import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

const config = (): SkyNetTableConfig => {
    return {
        name: 'UserTable',
        tabs: [{
            name: 'All Users',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'userName',
                    labelKey: 'Users.userName',
                    reduced: true,
                },
                {
                    name: 'email',
                    labelKey: 'Contact.email',
                },
                {
                    name: 'locationLocationName',
                    labelKey: 'Contact.location',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}

export default config

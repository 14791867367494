import {
    LoggerDataTypes,
    LoggerStatus,
} from '../Loggers.types'

export const availableLoggerStatuses = {
    [LoggerStatus.ACTIVE]: 'Active',
    [LoggerStatus.DECOMMISSIONED]: 'Decomissioned',
    [LoggerStatus.SERVICE]: 'Service',
    [LoggerStatus.OUT_OF_SERVICE]: 'Out of Service',
    [LoggerStatus.READY_TO_SHIP]: 'Ready to Ship',
}

export const allowedLoggerDataTypes = [
    LoggerDataTypes.TEMPERATURE,
    LoggerDataTypes.RELATIVE_HUMIDITY,
    LoggerDataTypes.RSSI,
    LoggerDataTypes.SNR,
    LoggerDataTypes.BATTERY_VOLTAGE,
    LoggerDataTypes.BATTERY_VOLTAGE_DROP,
    LoggerDataTypes.BATTERY_LEVEL,
    LoggerDataTypes.MESSAGE_ID,
    LoggerDataTypes.GATEWAY_NUMBER,
    LoggerDataTypes.MARKER,
    LoggerDataTypes.IS_HISTORIC_READOUT,
]

export default availableLoggerStatuses

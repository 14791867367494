import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    kamToBeCompleted,
} from '../KamDashboard.types'

const kamDashboardConfig = (): SkyNetTableConfig<keyof kamToBeCompleted> => {
    return {
        name: 'kamToBeCompleted',
        tabs: [{
            name: 'To Be Completed',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'quoteNumber',
                    labelKey: 'AccountQuotes.quoteNumber',
                },
                {
                    name: 'customerQuotationLocationName',
                    labelKey: 'AccountQuotes.customerQuotation',
                },
                {
                    name: 'accountName',
                    labelKey: 'AccountLane.accountCompanyName',
                    filterField: 'accountName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'leaseType',
                    labelKey: 'AccountLane.leaseType',
                    filterField: 'leaseType',
                    componentName: 'EnumValue',
                    filterType: FilterType.Array,
                    allowedValuesEnum: LeaseType,
                },
                {
                    name: 'pricingResponsible',
                    labelKey: 'AccountQuotes.pricingResponsible',
                    componentName: 'List',
                },
                {
                    name: 'createdBy',
                    labelKey: 'KamDashboard.requestor',
                    sorting: false,
                },
                {
                    name: 'upcomingLanes',
                    labelKey: 'KamDashboard.upcomingLanes',
                    sorting: false,
                },
                {
                    name: 'pricedLanes',
                    labelKey: 'KamDashboard.pricedLanes',
                    sorting: false,
                },
                {
                    name: 'completedLanes',
                    labelKey: 'KamDashboard.completedLanes',
                    sorting: false,
                },
                {
                    name: 'lastUpdated',
                    labelKey: 'KamDashboard.lastUpdated',
                    sorting: false,
                },
            ],
        }],
    }
}

export default kamDashboardConfig

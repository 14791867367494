/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    generatePath,
} from 'react-router-dom'
import {
    CommonRoutes, APPS, SkyNetNavigateFunction,
    SkyNetGeneratePathParams, RoutesConfig, SkyNetRoutesConfig,
    NavigateFunctionArgs, RoutesDescription,
    RouteDescription, NavigateFunction, RouteParamsConfig,
} from './SkyNetRoutes.types'

export const createSearchParam = (params: Record<string, string>) => {
    return `?${String(new URLSearchParams(Object.entries(params)))}`
}

export const convertSkyNetPathToLink = ({
    module,
    domain,
    domainPath,
    absolute,
}: Omit<SkyNetGeneratePathParams, 'params' | 'searchParams'>) => {
    const init = absolute ? CommonRoutes.SLASH.concat(APPS, CommonRoutes.SLASH) : ''

    return init.concat([
        module,
        domain,
        ...domainPath,
    ].filter(Boolean).join('/'))
}

export const generateSkyNetLink = ({
    module,
    domain,
    domainPath,
    params,
    searchParams,
    absolute = true,
}: SkyNetGeneratePathParams) => {
    let fullPath = convertSkyNetPathToLink({
        absolute,
        module,
        domain,
        domainPath,
    })

    if (searchParams) {
        fullPath = fullPath.concat(createSearchParam(searchParams))
    }

    return generatePath(fullPath, params)
}

const createRouteStringParam = ({
    name, required,
}) => {
    return `:${name}${required ? '' : '?'}`
}

export function createRoutesConfig(skyNetNavigate: SkyNetNavigateFunction, options: {
    absolute: boolean
} = {
    absolute: true,
}) {
    return function parseRouteConfig(
        config: SkyNetRoutesConfig | RoutesDescription,
    ): {[key: string]: RoutesConfig} {
        const {
            path,
            via,
            params,
            routeParams,
            requiredParams,
            stringParams,
            route,
            navigate,
            pattern,
            ...routes
        }: {
            path?: string[],
            via?: RoutesConfig,
            params?: RouteParamsConfig[],
            requiredParams?: string[],
            routeParams?: string[],
            stringParams?: string,
            navigate?: NavigateFunction,
            route?: string,
            pattern?: string,
            routes?: Record<string, RoutesConfig | RouteDescription>,
        } = config

        return (Object.entries(routes) || []).reduce((acc, [
            key,
            value,
        ]) => {
            const paramNames = ('params' in value) && (value.params || []).map(createRouteStringParam)
            const mergedRouteParams = ('params' in value) ? [
                ...(routeParams || []) as string[],
                ...paramNames,
            ] : routeParams

            const mergedPath = [
                ...(path || []),
                ...(value.via?.path || []),
                value.route,
                ...(paramNames || []),
            ] as string[]

            const mergedRequiredParams: string[] = [
                ...value?.requiredParams || [],
                ...value?.via?.requiredParams || [],
                ...(value?.params || []).map(({
                    name, required,
                }) => {
                    if (required) {
                        return name
                    }
                    return null
                }).filter(Boolean),
            ]

            return {
                ...acc,
                [key]: {
                    requiredParams: mergedRequiredParams as string[],
                    route: value.route,
                    params: value.params,
                    routeParams: mergedRouteParams as string[],
                    stringParams: ('params' in value) && paramNames?.join(CommonRoutes.SLASH),
                    // eslint-disable-next-line func-names
                    navigate: value.navigate || function (navigateParams?: NavigateFunctionArgs) {
                        try {
                            const errors = mergedRequiredParams.reduce((errorsAcc, param) => {
                                if (!Object.keys(navigateParams.params).includes(param)) {
                                    errorsAcc.push(`Param "${param}" is required to specify`)
                                }
                                return errorsAcc
                            }, [] as string[])

                            if (errors.length) {
                                throw Error(errors.join('\n'))
                            }

                            skyNetNavigate(mergedPath, navigateParams)
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.error('Unable to call navigate:', e)
                        }
                    },
                    path: mergedPath,
                    pattern: convertSkyNetPathToLink({
                        domainPath: mergedPath,
                        absolute: options.absolute,
                    }),
                    ...parseRouteConfig({
                        ...value,
                        path: mergedPath,
                    } as RoutesDescription),
                },
            } as RoutesConfig
        }, {})
    }
}

import {
    Column,
} from 'app/types/simpletable.types'
import getUniqueId from 'app/utils/getUniqueId'
import InputSingleline from 'app/shared-components/InputSingleline'
import Checkbox from 'app/shared-components/Checkbox'
import EnumSelector from 'app/shared-components/EnumSelector'
import EnumChipSelector from 'app/shared-components/EnumChipSelector'
import {
    OpportunityFrequency, ProductType,
} from 'app/types/enums'
import {
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'

import SkyNetLink, {
    SizeLink,
    TypeLink,
} from 'app/shared-components/SkyNetLink'
import {
    DeliveryServiceType, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import RemoveFromLG from './RemoveFromLG'

export type QuoteLane = {
    changedOn: string,
    laneStatus: LaneStatus,
    destAirportIataCode: string,
    id: number,
    laneComment: string,
    laneNumber: string,
    originAirportIataCode: string,
    selected: boolean,
    opportunityNumber: string,
    expPalletsYear: number,
    frequency: OpportunityFrequency,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    productTypes: ProductType[],
    baseLeaseDays: number[]
}

const getColumns = ({
    classes,
    handleChange,
    l,
    getAllowedValues,
    onRemoveFromLG,
    getLaneLink,
    getOpportunityLink,
}: {
    classes: Record<string, any>,
    l: (l:string) => string,
    handleChange: (...a: any[]) => void,
    getAllowedValues: (e: Record<string, string>) => Record<string, string>,
    onRemoveFromLG: ({
        laneId,
    }: {
        laneId: number,
    }) => void,
    getLaneLink: (id: string) => string,
    getOpportunityLink: (id: string) => string,
}): Column <keyof QuoteLane>[] => {
    return [
        {
            label: '',
            component: Checkbox,
            fieldName: 'selected',
            getProps: ({
                id,
                selected,
            }) => {
                return {
                    className: classes.checkbox,
                    value: selected,
                    onChange: (checked) => {
                        handleChange({
                            id,
                            selected: checked,
                        })
                    },
                }
            },
            width: 50,
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.lanes'),
            component: SkyNetLink,
            fieldName: 'laneNumber',
            getProps: ({
                id,
                laneNumber,
            }) => {
                return {
                    className: classes.link,
                    disabled: true,
                    link: getLaneLink(id),
                    type: TypeLink.EXTERNAL,
                    text: laneNumber,
                    size: SizeLink.S,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountLane.opportunity'),
            component: SkyNetLink,
            fieldName: 'opportunityNumber',
            getProps: ({
                opportunityId,
                opportunityNumber,
            }) => {
                return {
                    className: classes.link,
                    disabled: true,
                    link: getOpportunityLink(opportunityId),
                    type: TypeLink.EXTERNAL,
                    text: opportunityNumber,
                    size: SizeLink.S,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneStatus'),
            component: InputSingleline,
            getProps: ({
                laneStatus,
            }) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    value: getAllowedValues(LaneStatus)[laneStatus],
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('LaneBasicView.expPalletsYear'),
            component: InputSingleline,
            fieldName: 'expPalletsYear',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('Opportunity.frequency'),
            component: EnumSelector,
            fieldName: 'frequency',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(OpportunityFrequency),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneFrom'),
            component: InputSingleline,
            fieldName: 'originAirportIataCode',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneTo'),
            component: InputSingleline,
            fieldName: 'destAirportIataCode',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('LaneGroup.deliveryServiceType'),
            component: EnumSelector,
            fieldName: 'deliveryServiceType',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(DeliveryServiceType),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('LaneGroup.collectionServiceType'),
            component: EnumSelector,
            fieldName: 'collectionServiceType',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(CollServiceType),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.productTypes'),
            component: EnumChipSelector,
            fieldName: 'productTypes',
            getProps: () => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    allowedValues: getAllowedValues(ProductType),
                }
            },
            id: getUniqueId(),
        },
        {
            label: l('AccountQuotes.laneBaseLeaseDays'),
            component: InputSingleline,
            fieldName: 'baseLeaseDays',
            getProps: ({
                baseLeaseDays,
            }) => {
                return {
                    classNames: {
                        label: classes.inputLabel,
                    },
                    disabled: true,
                    value: (baseLeaseDays || []).join(';'),
                }
            },
            id: getUniqueId(),
        },
        {
            label: '',
            component: RemoveFromLG,
            getProps: ({
                laneStatus,
                id,
            }) => {
                return {
                    laneStatus,
                    onRemoveFromLG,
                    laneId: id,
                }
            },
            id: getUniqueId(),
        },
    ]
}

export default getColumns

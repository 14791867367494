import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    Deployment,
} from './Deployment.types'

export default (): SkyNetTableConfig<keyof Deployment> => {
    return {
        name: 'DeploymentsTable',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'deploymentName',
                    labelKey: 'Deployment.deploymentName',
                    reduced: true,
                    minWidth: '300px',
                },
                {
                    name: 'createdOn',
                    labelKey: 'Deployment.createdOn',
                    width: '300px',
                },
                {
                    name: 'createdBy',
                    labelKey: 'Deployment.createdBy',
                    width: '300px',
                },
            ],
        }],
    }
}

import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    ModificationType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import request from '../orderConfirmation.request'
import {
    PositioningSetupType,
} from '../orderConfirmation.types'

type RequestParams = {
    modificationType: ModificationType,
    orderNumber: string,
    data: PositioningSetupType,
}

const useUpdatePositioningSetup = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessUpdate,
        onError,
    } = useHandleStatusRequest({
        id: 'ModifyOrderStep',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            modificationType,
            orderNumber,
            data,
        }: RequestParams) => {
            return request.Update.mutationFn({
                modificationType,
                orderNumber,
                data,
                token,
            })
        },
        onSuccess: onSuccessUpdate,
        onError,
    })
}

export default useUpdatePositioningSetup

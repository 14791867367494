import {
    createContext, useContext,
} from 'react'

export type CollapsibleTableContextType = {
    id: string,
    action: string,
    updatePathWithId: ({
        id,
    }: {id: number}) => void,
    updatePathWithParams: ({
        id, action,
    }: {
        action?: string,
        id?: string | number
    }) => void,
    routeParams: string[]
}

export const CollapsibleTableContext = createContext<CollapsibleTableContextType>(null)

const useCollapsibleTableContext = () => {
    return useContext<CollapsibleTableContextType>(CollapsibleTableContext)
}

export default useCollapsibleTableContext

import {
    useMemo,
} from 'react'
import {
    App, CommonRoutes, SkyNetRoutesConfig, useSkyNetNavigate,
} from 'app/SkyNetRoutes'

import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import useCreateSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    ForecastDomains,
} from '../Forecast.types'
import {
    ShortTermForecastsTabs,
} from '../ShortTermForecasts/ShortTermForecasts.enums'

export default function useForecastRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useCreateSkyNetDomainRoutes()()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.FORECAST],
            ShortTermForecast: {
                route: ForecastDomains.ShortTermForecasts,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                CurrentMonthUpdate: {
                    route: ShortTermForecastsTabs.CurrentMonthUpdate,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}

import React, {
    useCallback, useMemo,
} from 'react'

import {
    RejectedReasons,
} from 'app/shared-components/LaneFees/LaneFeesForm/reasons.types'
import useEnumValues from 'app/hooks/useEnumValues'
import useLabel from 'app/hooks/useLabel'
import CommentedReasonConfirmationDialog from 'app/shared-components/LaneFees/LaneFeesUpdate/CommentedReasonConfirmationDialog'

const RejectLaneFeesConfirmationDialog = ({
    open,
    close,
    action,
}: {
    open: boolean,
    close: () => void,
    action: (a: {
        rejectedReason: string,
        rejectedReasonComment: string
    }) => void
}) => {
    const rejectedReasons = useEnumValues()(RejectedReasons)
    const l = useLabel()

    const handleClose = useCallback((positive, {
        reason,
        reasonComment,
    }) => {
        if (positive) {
            action({
                rejectedReason: reason,
                rejectedReasonComment: reasonComment,
            })
        }
        close()
    }, [
        close,
        action,
    ])

    const titles = useMemo(() => {
        return {
            headerTitle: 'Add Rejected Reason',
            reasonTitle: l('LaneFees.rejectedReason'),
            commentTitle: l('LaneFeesUpdateDto.rejectedReasonComment'),
        }
    }, [l])

    return (
        <CommentedReasonConfirmationDialog
            open={open}
            handleClose={handleClose}
            allReasons={rejectedReasons}
            title={titles}
        />
    )
}

export default RejectLaneFeesConfirmationDialog

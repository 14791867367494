import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    Company,
    IndustryType,
} from './Companies.types'

export default (): SkyNetTableConfig<keyof Company> => {
    return {
        name: 'Company',
        tabs: [{
            name: 'All Companies',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'companyName',
                    reduced: true,
                },
                {
                    name: 'hasAccount',
                    labelKey: 'Company.hasAccount',
                    componentName: 'ChipCheckMark',
                },
                {
                    name: 'website',
                    reduced: true,
                },
                {
                    name: 'industryType',
                    labelKey: 'ContactBase.type',
                    componentName: 'EnumValue',
                    filterField: 'industryType',
                    allowedValuesEnum: IndustryType,
                    filterType: FilterType.Array,
                },
                {
                    name: 'createdBy',
                    reduced: true,
                    labelKey: 'CompanyView.createdBy',
                },
            ],
        }],
    }
}

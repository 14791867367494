import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export default (): SkyNetTableConfig => {
    return {
        tabs: [{
            name: 'Countries',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'countryName',
                    reduced: true,
                },
                {
                    name: 'countryIsoAlpha2Code',
                    reduced: true,
                },
            ],
        }],
    }
}

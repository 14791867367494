import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    CommonRoutes,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import {
    useParams, Outlet, Navigate,
} from 'react-router-dom'
import LaneFees from './LaneFees'
import useGetLaneGroupPricingAssignmentById from '../hooks/useGetLaneGroupPricingAssignmentById'
import useAccountQuoteContext from '../../AccountQuoteContext/useAccountQuoteContext'
import useStyles from './AssignPricingLaneGroupUpdate.styles'
import AssignPricingLaneGroupUpdate from './AssignPricingLaneGroupUpdate'

const AssignPricingLaneGroupUpdateWrapper = ({
    id,
    refetch,
}: {
    id: number,
    refetch: (...args: any[]) => void
}) => {
    const {
        classes,
    } = useStyles()

    const {
        action,
    } = useParams()

    const {
        quoteId,
    } = useAccountQuoteContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLaneGroupPricingAssignmentById({
        id, quoteId,
    })

    const routes = useSkyNetRoutes({
        routes: [{
            path: '/',
            element: (
                <>
                    <AssignPricingLaneGroupUpdate
                        data={data}
                        invalidate={invalidate}
                        refetch={refetch}
                    />
                    <Outlet />
                    { !action && (
                        <Navigate
                            to="lane-fees"
                            replace
                        />
                    ) }
                </>
            ),
            children: [{
                path: `lane-fees/${CommonRoutes.ASTERISK}`,
                element: <LaneFees id={id} />,
            }],
        }],
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div className={classes.formContainer}>
                { routes }
            </div>
        </StatusHandler>
    )
}

export default AssignPricingLaneGroupUpdateWrapper

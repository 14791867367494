import compact from 'lodash/compact'

import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'

import {
    LeaseType, LocationType, OrderTemperatureRange,
} from 'app/types/enums'
import {
    CollectionServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import reduceEnumValues from 'app/utils/reduceEnumValues'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

import {
    LaneGroupForm,
} from 'app/Apps/Pricing/Pricing.types'

type Params = {
    useTransportModeRoad: boolean,
    usePositioningTemperature: {
        showFTL: boolean,
        showLTL: boolean,
    },
    tempRange: string,
    handoverPointId: number,
    collectionDropoffPointId: number,
    hasAwardedImplementedLanes: boolean,
    getAllowedValues: (param: Record<string, string>) => Record<string, string>,
    deliveryServiceType: DeliveryServiceType,
    contractBasisNumber: string,
    exists: boolean,
    contractBasisAdminPermission: boolean,
    contractBasisLink: string,
}

const getLaneGroupFields = ({
    useTransportModeRoad,
    usePositioningTemperature,
    handoverPointId,
    collectionDropoffPointId,
    hasAwardedImplementedLanes,
    getAllowedValues,
    deliveryServiceType,
    contractBasisNumber,
    exists,
    contractBasisAdminPermission,
    contractBasisLink,
}: Params) => {
    const disabled = hasAwardedImplementedLanes && exists
    const handOverFirstColumnDefault:
        NestedField<LaneGroupForm >[] = [{
            name: 'deliveryServiceType',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(DeliveryServiceType),
            showInColumns: true,
            disabled: exists,
            required: true,
        }]
    const handOverFirstColumn:
        NestedField<LaneGroupForm>[] = useTransportModeRoad ? [
            ...handOverFirstColumnDefault,
            {
                name: 'transportModeRoad',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(TransportModeRoad),
                showInColumns: true,
                disabled: disabled
                    || (deliveryServiceType !== DeliveryServiceType.DELIVERY_ROAD),
                required: deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD,
            },
        ]
            : handOverFirstColumnDefault

    const handOverFirstColumWithFTLORLTL:
        NestedField<LaneGroupForm>[] = (useTransportModeRoad
        && usePositioningTemperature) ? compact([
                ...handOverFirstColumn,
                ...(usePositioningTemperature.showFTL ? [{
                    name: 'isFtlPositioningTemperatureControlled',
                    componentName: 'Checkbox',
                    className: 'tlCheckbox',
                    disabled,
                }] as NestedField<LaneGroupForm>[] : [null]),
                ...(usePositioningTemperature.showLTL ? [{
                    name: 'isLtlPositioningTemperatureControlled',
                    componentName: 'Checkbox',
                    className: 'tlCheckbox',
                    disabled,
                }] as NestedField<LaneGroupForm>[] : [null]),
            ]) : handOverFirstColumn

    const fields: DTOCardFormField<LaneGroupForm>[] = [
        {
            id: 'generalinformation',
            title: 'General Information',
            className: 'generalInfo',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'tempRange',
                            componentName: 'EnumSelector',
                            allowedValues: reduceEnumValues(
                                getAllowedValues(OrderTemperatureRange), [
                                    OrderTemperatureRange.BT,
                                    OrderTemperatureRange.ANY_TEMP_RANGE,
                                ],
                            ),
                            disabled: exists,
                            required: true,
                        },
                        {
                            name: 'leaseType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LeaseType),
                            disabled: exists,
                            showInColumns: true,
                            required: true,
                        },
                        {
                            name: 'laneGroupTag',
                            labelKey: 'LaneGroup.laneGroupTag',
                            componentName: 'RestrictedPermissionInputSingleline',
                            hasPermission: contractBasisAdminPermission,
                            text: 'You do not have permission to view this field.',
                            disabled,
                        },
                        {
                            name: 'laneGroupNumber',
                            componentName: 'InputSingleline',
                            className: 'laneGroupNumber',
                            disabled: true,
                            labelKey: 'Lane.laneGroup',
                        },
                        {
                            text: contractBasisNumber,
                            labelKey: 'ContractBasis.contractBasisId',
                            name: 'linkToContractBasis',
                            componentName: 'SkyNetLink',
                            type: TypeLink.EXTERNAL,
                            size: SizeLink.S,
                            link: contractBasisLink,
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'comment',
                            componentName: 'LongText',
                            disabled,
                        },
                        {
                            componentName: 'InputSingleline',
                            title: 'Changed On',
                            name: 'changedOn',
                            disabled: true,
                        },
                        {
                            componentName: 'InputSingleline',
                            title: 'Changed By',
                            name: 'changedBy',
                            disabled: true,
                        },
                    ],
                },
            ],
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: [
                {
                    id: 'column1',
                    fields: handOverFirstColumWithFTLORLTL,
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'handoverPoint',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            includeFilters: {
                                isProspect: [
                                    true,
                                    false,
                                ],
                            },
                            required: !exists,
                            disabled,
                        },
                        {
                            name: 'handoverPointContacts',
                            componentName: 'MultipleContactByLocationObjectSelector',
                            selectedLocation: handoverPointId,
                            existedForm: true,
                            disabled,
                        },
                    ],
                },
            ],
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'collectionServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(CollectionServiceType),
                        disabled: exists,
                        showInColumns: true,
                        required: true,
                    }],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'collectionDropoffPoint',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.ADDRESS,
                            includeFilters: {
                                isProspect: [
                                    true,
                                    false,
                                ],
                            },
                            required: !exists,
                            disabled,
                        },
                        {
                            name: 'collectionDropoffPointContacts',
                            componentName: 'MultipleContactByLocationObjectSelector',
                            selectedLocation: collectionDropoffPointId,
                            existedForm: true,
                            disabled,
                        },
                    ],
                },
            ],
        },
    ]

    return fields
}

export default getLaneGroupFields

import React, {
    useMemo,
} from 'react'
import {
    Navigate,
} from 'react-router-dom'
import {
    CommonRoutes,
    SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import Companies from './Companies'
import Locations from './Locations'
import BillingCompanies from './BillingCompanies'
import Contacts from './Contacts'
import Users from './Users'
import AirportsAndSeaports from './AirportsAndSeaports'
import useContactManagementRoutes from './hooks/useContactManagementRoutes'
import {
    ContactManagementDomains,
} from './ContactManagement.types'

export default function ContactManagement() {
    const Routes = useContactManagementRoutes()

    const routesConfig: SkyNetRouteConfigObject<ContactManagementDomains> = useMemo(() => {
        return {
            [Routes.Companies.route]: <Companies />,
            [Routes.Locations.route]: <Locations />,
            [Routes.BillingCompanies.route]: <BillingCompanies />,
            [Routes.AirportsAndSeaports.route]: <AirportsAndSeaports />,
            [Routes.Contacts.route]: <Contacts />,
            [Routes.Users.route]: <Users />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.Locations.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}

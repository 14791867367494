import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    OperationalDays,
    ServiceCentersTableType,
    ServiceCenterStatus, ServiceCenterType,
} from './serviceCenters.types'

export default (): SkyNetTableConfig<keyof ServiceCentersTableType> => {
    return {
        name: 'ServiceCenter',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'serviceCenterNumber',
                    labelKey: 'ServiceCenters.serviceCenterNumber',
                    reduced: true,
                },
                {
                    name: 'locationLocationName',
                    filterField: 'locationLocationName',
                    filterType: FilterType.Array,
                    labelKey: 'ServiceCenters.location',
                    reduced: true,
                },
                {
                    name: 'serviceCenterStatus',
                    filterField: 'serviceCenterStatus',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: ServiceCenterStatus,
                    labelKey: 'ServiceCenters.serviceCenterStatus',
                },
                {
                    name: 'serviceCenterType',
                    filterField: 'serviceCenterType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: ServiceCenterType,
                    labelKey: 'ServiceCenters.serviceCenterType',
                },
                {
                    name: 'operationalDays',
                    filterField: 'operationalDays',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: OperationalDays,
                    labelKey: 'ServiceCenters.operationalDays',
                },
                {
                    name: 'orderSelectionDelay',
                    labelKey: 'ServiceCenters.orderSelectionDelay',
                },
            ],
        }],
    }
}

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../serviceCenters.request'
import {
    ServiceCenterFormType,
} from '../serviceCenters.types'

const useGetServiceCenterByLocation = (locationId: number) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<ServiceCenterFormType>({
        key: RequestKeys.getServiceCenterByLocation,
        params: {
            locationId,
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetServiceCenterByLocation),
        enabled: Boolean(locationId),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}

export default useGetServiceCenterByLocation

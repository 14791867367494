import React, {
    useCallback,
    useMemo,
} from 'react'
import Card from 'app/shared-components/Card'
import {
    AccountLane,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import SimpleTable, {
    RowComponent,
} from 'app/shared-components/SimpleTable'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    Column,
} from 'app/types/simpletable.types'
import useLabel from 'app/hooks/useLabel'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import getColumns, {
    QuoteLane,
} from './AssignPricingLanes.columns'
import useStyles from './AssignPricingLanes.styles'
import useRemoveLaneFromLaneGroup from '../../hooks/useRemoveLaneFromLaneGroup'

const AssignPricingLanes = ({
    lanes,
    onSelect,
    onSuccess,
}: {
    lanes: AccountLane[],
    onSelect: (...args: any[]) => void,
    onSuccess: () => void,
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const getAllowedValues = useEnumValues()
    const {
        Lanes: LanesRoutes,
        Opportunities: OpportunitiesRoutes,
    } = useAccountManagementRoutes()

    const {
        mutate,
    } = useRemoveLaneFromLaneGroup(onSuccess)

    const onRemoveFromLG = useCallback((params: {
        laneId: number,
    }): void => {
        mutate(params)
    }, [mutate])

    const columns = useMemo((): Column<keyof QuoteLane>[] => {
        return getColumns({
            classes,
            l,
            handleChange: onSelect,
            getAllowedValues,
            onRemoveFromLG,
            getLaneLink: (id) => {
                return generateSkyNetLink({
                    domainPath: LanesRoutes.Overview.path,
                    params: {
                        key: id,
                    },
                })
            },
            getOpportunityLink: (id) => {
                return generateSkyNetLink({
                    domainPath: OpportunitiesRoutes.Overview.path,
                    params: {
                        key: id,
                    },
                })
            },
        })
    }, [
        LanesRoutes,
        OpportunitiesRoutes,
        classes,
        l,
        onSelect,
        getAllowedValues,
        onRemoveFromLG,
    ])

    return (
        <Card
            title="Lanes"
            className={classes.container}
        >
            <SimpleTable
                name="QuoteOpportunitiesTable"
                columns={columns}
                data={lanes}
                component={RowComponent}
                disabled
                classNames={{
                    tableCellHead: classes.tableCellHead,
                    tableCell: classes.tableCell,
                }}
            />
        </Card>
    )
}

export default AssignPricingLanes

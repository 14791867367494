import {
    tss,
} from 'app/tss'

export default tss.withNestedSelectors().create(({
    theme,
}) => {
    return {
        wrapper: {
            '&&': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        root: {
            minHeight: 300,
            marginTop: theme.spacing(2),
            '&:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },
        },
        title: {
            '&&': {
                marginBottom: theme.spacing(2),
            },
        },
    }
})

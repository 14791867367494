import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetDomainForm from 'app/shared-components/SkyNetDomain/SkyNetDomainForm'

import InternalTransportsOverview from './InternalTransportsOverview'
import orderRequest from '../orders.request'
import request from './internalTransport.request'
import getTableConfig from './internalTransport.config'
import ContainerSelection from '../Components/ContainerSelection/ContainerSelectionContainer'
import OrderSteps from '../Components/OrderSteps'
import OrderNotificationsTab from '../Components/OrderNotificationsTab'
import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'
import CreateInternalTransportForm from './CreateInternalTransportForm'
import InternalOrdersChangeHistory from './InternalOrdersChangeHistory'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const InternalTransport = () => {
    const {
        InternalOrders: InternalOrdersRoutes,
    } = useOrderManagementRoutes()
    const tabs: SidebarTab[] = [
        {
            url: InternalOrdersRoutes.Overview.route,
            label: 'Overview',
            Component: InternalTransportsOverview,
        },
        {
            url: InternalOrdersRoutes.Notifications.route,
            label: 'Notifications',
            Component: OrderNotificationsTab,
        },
        {
            url: InternalOrdersRoutes.ContainerSelection.route,
            label: 'Containers / Freight',
            Component: ContainerSelection,
        },
        {
            url: InternalOrdersRoutes.OrderSteps.route,
            label: 'Order Steps',
            Component: OrderSteps,
        },
        {
            url: InternalOrdersRoutes.ChangeHistory.route,
            label: 'Change History',
            Component: InternalOrdersChangeHistory,
        },
    ]

    const customRoutes = useMemo(() => {
        return [{
            path: InternalOrdersRoutes.Create.route,
            element: (
                <SkyNetDomainForm onClose={() => {
                    InternalOrdersRoutes.navigate()
                }}
                >
                    <CreateInternalTransportForm />
                </SkyNetDomainForm>
            ),
        }]
    }, [InternalOrdersRoutes])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            name={orderRequest.dataModelName}
            sidebarTabs={tabs}
            uniqField="orderNumber"
            customRoutes={customRoutes}
        >
            <SkyNetTable
                moduleName={orderRequest.moduleName}
                customUrl={request.all.url}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default InternalTransport

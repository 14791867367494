import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    ContainerCode, ContainerVolume, TemperatureRange,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export default (): SkyNetTableConfig => {
    return {
        name: 'ContainerTypes',
        tabs: [{
            name: 'Container Type',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'containerModel',
                    reduced: true,
                },
                {
                    name: 'containerCode',
                    reduced: true,
                    componentName: 'EnumValue',
                    allowedValuesEnum: ContainerCode,
                    filterType: FilterType.Array,
                    filterField: 'containerCode',
                },
                {
                    name: 'containerVolume',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: ContainerVolume,
                    filterField: 'containerVolume',
                },
                {
                    name: 'temperatureRange',
                    reduced: true,
                    componentName: 'EnumValue',
                    allowedValuesEnum: TemperatureRange,
                },
                {
                    name: 'isPrototype',
                },
            ],
        }],
    }
}

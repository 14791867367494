import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

type BillingAddresses = {
    billingLocationName: string,
    street: string,
    city: string,
    zip: string,
    countryCountryName: string,
}

const config: SkyNetTableConfig<keyof BillingAddresses> = {
    labelEntityName: 'BillingCompanyDto',
    fields: [
        {
            name: 'billingLocationName',
            labelKey: 'BillingCompanyDto.billingLocationName',
        },
        {
            name: 'street',
            labelKey: 'BillingCompanyDto.street',
        },
        {
            name: 'city',
            labelKey: 'Location.city',
        },
        {
            name: 'zip',
            labelKey: 'Location.zip',
        },
        {
            name: 'countryCountryName',
            labelKey: 'Location.country',
        },
    ],
}

export default config

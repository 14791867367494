import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    AccountLanesTable, LaneStatus,
} from './AccountLanes.types'

const config = (): SkyNetTableConfig<keyof AccountLanesTable> => {
    return {
        name: 'Account Lanes',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'laneNumber',
                    labelKey: 'AccountLane.laneNumber',
                    reduced: true,
                },
                {
                    name: 'laneStatus',
                    filterField: 'laneStatus',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: LaneStatus,
                    labelKey: 'LaneQuoteRequest.selectedLaneStatus',
                    reduced: true,
                },
                {
                    name: 'accountCompanyName',
                    filterField: 'accountCompanyName',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.accountCompanyName',
                },
                {
                    name: 'originAirport',
                    filterField: 'originAirport',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.originAirport',
                    reduced: true,
                },
                {
                    name: 'destinationAirport',
                    filterField: 'destinationAirport',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.destinationAirport',
                    reduced: true,
                },
                {
                    name: 'opportunity',
                    filterField: 'opportunity',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.opportunity',
                },
                {
                    name: 'leaseType',
                    labelKey: 'AccountLane.leaseType',
                    filterField: 'leaseType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: LeaseType,
                },
                {
                    name: 'customerExecution',
                    filterField: 'customerExecution',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.customerExecution',
                },
                {
                    name: 'changedOn',
                    filterField: 'changedOn',
                    filterType: FilterType.DateRange,
                    labelKey: 'AccountLane.changedOn',
                },
                {
                    name: 'changedBy',
                    filterField: 'changedBy',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.changedBy',
                },
                {
                    name: 'opportunityCollaborators',
                    labelKey: 'Opportunity.opportunityCollaborators',
                    componentName: 'List',
                    filterField: 'opportunityCollaborators',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}

export default config

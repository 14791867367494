import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    ProductType,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    PreBooking,
    PreBookingStatus,
    PreBookingContainerStatus,
} from './PreBooking.types'

export default (): SkyNetTableConfig<keyof PreBooking> => {
    return {
        name: 'PreBookings',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'preBookingNumber',
                    labelKey: 'Prebooking.number',
                    reduced: true,
                },
                {
                    name: 'laneNumber',
                    labelKey: 'Prebooking.lane',
                    reduced: true,
                },
                {
                    name: 'pricingAccountCompanyName',
                    labelKey: 'Prebooking.pricingAccount',
                },
                {
                    name: 'originAirportIataCode',
                    labelKey: 'Prebooking.originAirport',
                },
                {
                    name: 'destinationAirportIataCode',
                    labelKey: 'Prebooking.destinationAirport',
                },
                {
                    name: 'customerExecution',
                    labelKey: 'Prebooking.customerExecution',
                },
                {
                    name: 'hasBooking',
                    labelKey: 'Prebooking.hasBooking',
                    filterField: 'hasBooking',
                    filterType: FilterType.Array,
                    componentName: 'ChipCheckMark',
                },
                {
                    name: 'requestedHandover',
                    labelKey: 'Prebooking.requestedHandover',
                    filterField: 'requestedHandover',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'containersStatus',
                    labelKey: 'Prebooking.containerStatus',
                    componentName: 'EnumValue',
                    allowedValuesEnum: PreBookingContainerStatus,
                    filterField: 'containersStatus',
                    filterType: FilterType.Array,
                },
                {
                    name: 'preBookingStatus',
                    labelKey: 'Prebooking.status',
                    componentName: 'EnumValue',
                    allowedValuesEnum: PreBookingStatus,
                    filterField: 'preBookingStatus',
                    filterType: FilterType.Array,
                },
                {
                    name: 'requiredContainers',
                    labelKey: 'Prebooking.requiredContainers',
                    componentName: 'RequiredContainersList',
                    allowedValuesEnum: ProductType,
                    sorting: false,
                },
            ],
        }],
    }
}

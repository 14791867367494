import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    OperationalDays,
    ServiceCenterFormType,
} from 'app/Apps/ContainerManagement/ServiceCenters/serviceCenters.types'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

import {
    PositioningSelection, LocationSelectionDataType,
} from '../orderConfirmation.types'

const getFields = ({
    originServiceCenterId,
    originServiceCenterSelection,
    isDeliveryRoad,
    serviceCenter,
    getAllowedValues,
    isOtherByDefault,
    serviceCenterNumberLink,
}: {
    isDeliveryRoad: boolean,
    originServiceCenterId: number,
    originServiceCenterSelection: PositioningSelection,
    serviceCenter: ServiceCenterFormType,
    isOtherByDefault: boolean,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
    serviceCenterNumberLink: string
}): DTOCardFormField<keyof (LocationSelectionDataType & ServiceCenterFormType)>[] => {
    return [{
        id: 'generalInformation',
        className: 'generalInformation',
        title: 'General Information',
        fields: [
            {
                id: 'confirm_order_column_1',
                fields: [
                    {
                        name: 'originServiceCenter',
                        componentName: 'LocationObjectSelector',
                        filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
                        disabled: originServiceCenterSelection !== PositioningSelection.OTHER,
                        required: true,
                        labelKey: 'ServiceCenter.location',
                    },
                    {
                        name: 'serviceCenterNumber',
                        componentName: 'SkyNetLink',
                        type: TypeLink.EXTERNAL,
                        size: SizeLink.S,
                        text: serviceCenter?.serviceCenterNumber,
                        link: serviceCenterNumberLink,
                        disabled: true,
                        labelKey: 'ServiceCenter.serviceCenterNumber',
                    },
                    {
                        name: 'productMetrics',
                        componentName: 'AllowedContainerTypes',
                        value: serviceCenter?.productMetrics?.map(({
                            productType,
                        }) => {
                            return productType
                        }),
                        labelKey: 'ServiceCenter.modeledProductTypes',
                    },
                ],
            },
            {
                id: 'confirm_order_2',
                fields: [
                    ...isDeliveryRoad
                        ? [...originServiceCenterSelection === PositioningSelection.OTHER ? [{
                            name: 'originServiceCenterContacts',
                            componentName: 'MultipleContactByLocationObjectSelector',
                            existedForm: isOtherByDefault,
                            required: true,
                            selectedLocation: originServiceCenterId,
                            labelKey: 'ServiceCenter.locationContacts',
                        }] : [{
                            name: 'originServiceCenterContacts',
                            componentName: 'MultipleContactObjectSelector',
                            disabled: true,
                            labelKey: 'ServiceCenter.locationContacts',
                        }]] as NestedField<keyof LocationSelectionDataType>[] : [],
                    {
                        name: 'operationalDays',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(OperationalDays),
                        value: serviceCenter?.operationalDays,
                        disabled: true,
                        labelKey: 'ServiceCenter.operationalDays',
                    },
                    {
                        name: 'orderSelectionDelay',
                        componentName: 'IntegerInput',
                        value: serviceCenter?.orderSelectionDelay,
                        disabled: true,
                        labelKey: 'ServiceCenter.orderSelectionDelay',
                    },
                ],
            },
        ],
    }]
}

export default getFields

import React from 'react'

import EnumValue from 'app/shared-components/EnumValue'
import {
    AvailabilityStatus as AvailabilityStatusEnum,
} from 'app/Apps/OrderManagement/Orders/CustomerTransport/OrderConfirmation/orderConfirmation.types'

import useStyles from './AvailabilityStatus.style'

const MAP_COLOR = {
    [AvailabilityStatusEnum.READY_TO_CONFIRM]: 'green',
    [AvailabilityStatusEnum.CAN_BE_CONFIRMED]: 'orange',
    [AvailabilityStatusEnum.NOT_ENOUGH]: 'red',
    [AvailabilityStatusEnum.NO_MODEL_AVAILABLE]: 'gray',
}

const AvailabilityStatus = ({
    configName,
    mapCellProps,
    value,
}: {
    configName: string,
    mapCellProps: string,
    value: AvailabilityStatusEnum,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <EnumValue
            value={value}
            configName={configName}
            mapCellProps={mapCellProps}
            className={classes[MAP_COLOR[value]]}
        />
    )
}

export default AvailabilityStatus

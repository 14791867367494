type ContainerSelectionRequests = {
    name: string,
    preconditioningFeasibility: string,
    customerTransports: string,
    internalTransports: string,
}

const containerSelectionRequests: ContainerSelectionRequests = {
    name: 'ContainerSelectionView',
    preconditioningFeasibility: 'order/filter-request/preconditioning-feasibility',
    customerTransports: 'order/filter-request/container-selection-customer-transports',
    internalTransports: 'order/filter-request/container-selection-internal-transports',
}

export default containerSelectionRequests

import React, {
    useState, useMemo, useCallback,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import useHasPermission from 'app/hooks/useHasPermission'
import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    useCheckAwardedLanes,
} from 'app/Apps/AccountManagement/AccountQuotes'
import omit from 'lodash/omit'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    LaneGroup,
} from 'app/Apps/Pricing/Pricing.types'
import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'

import getLaneGroupFields from './LaneGroupForm.fields'
import useStyles from './LaneGroupForm.styles'

const defaultProps = {
    data: undefined,
    customButtons: undefined,
}

const defaultTemperatureControlledSetter = (
) => {
    return {
        [TransportModeRoad.FTL_OR_LTL]: {
            isFtlPositioningTemperatureControlled: true,
            isLtlPositioningTemperatureControlled: true,
        },
        [TransportModeRoad.FTL]: {
            isFtlPositioningTemperatureControlled: true,
        },
        [TransportModeRoad.LTL]: {
            isLtlPositioningTemperatureControlled: true,
        },
    }
}

const LaneGroupForm = ({
    data,
    onSuccess,
    onCancel,
    requestParam,
    customButtons,
}: {
    data?: LaneGroup & { originId?: number },
    onSuccess: (...args: any[]) => void,
    onCancel: (...args: any[]) => void
    requestParam: RequestConfig,
    customButtons?: JSX.Element[]
}) => {
    const {
        classes,
    } = useStyles()
    const id = data?.id
    const exists = Boolean(id)
    const contractBasisAdminPermission = useHasPermission(['contract_basis_admin'])
    const hasAwardedImplementedLanes = useCheckAwardedLanes(data?.id)
    const {
        ContractBasis: ContractBasisRoutes,
    } = usePricingRoutes()

    const getAllowedValues = useEnumValues()

    const [
        updatedData,
        setUpdatedData,
    ] = useState(null)

    const value = useMemo(() => {
        return {
            ...defaultTemperatureControlledSetter(
            )[updatedData?.transportModeRoad],
            ...data,
            ...updatedData,
        }
    }, [
        data,
        updatedData,
    ])

    const useTransportModeRoad = value?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD

    const fields = useFieldsWithClassName(
        getLaneGroupFields({
            useTransportModeRoad,
            usePositioningTemperature: {
                showFTL: [
                    TransportModeRoad.FTL,
                    TransportModeRoad.FTL_OR_LTL,
                ].includes(value?.transportModeRoad),
                showLTL: [
                    TransportModeRoad.LTL,
                    TransportModeRoad.FTL_OR_LTL,
                ].includes(value?.transportModeRoad),
            },
            tempRange: value?.tempRange,
            handoverPointId: value?.handoverPoint?.id,
            collectionDropoffPointId: value?.collectionDropoffPoint?.id,
            hasAwardedImplementedLanes,
            getAllowedValues,
            deliveryServiceType: value?.deliveryServiceType,
            contractBasisNumber: value?.contractBasisNumber,
            exists,
            contractBasisAdminPermission,
            contractBasisLink: value?.contractBasisId ? generateSkyNetLink({
                domainPath: ContractBasisRoutes.ContractBasis.path,
                params: {
                    key: value?.contractBasisId,
                },
            }) : null,
        }),
        classes,
    )

    const modifyDataBeforeSend = useCallback(
        (modifiedData) => {
            if (!useTransportModeRoad) {
                return {
                    ...omit(modifiedData, 'transportModeRoad'),
                }
            }
            return modifiedData
        },
        [useTransportModeRoad],
    )

    return (
        <div className={classes.root}>
            <FormWithControls
                name="LaneGroupCreateOrDuplicateDto"
                fields={fields}
                value={value}
                modifyDataBeforeSend={modifyDataBeforeSend}
                setExternalValue={setUpdatedData}
                classNames={{
                    gridWrapper: classes.gridWrapper,
                }}
                onCancel={onCancel}
                onSuccess={onSuccess}
                exists={exists}
                requestParams={requestParam}
                customButtons={customButtons}
            />
        </div>
    )
}

LaneGroupForm.defaultProps = defaultProps

export default LaneGroupForm

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export default (): SkyNetTableConfig => {
    return {
        name: 'ContainerSelection.InternalTransports',
        tabs: [{
            name: 'Open Transports',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'orderNumber',
                    filterField: 'orderNumber',
                    filterType: FilterType.Array,
                    width: '100px',
                },
                {
                    name: 'originAirportIataCode',
                    filterField: 'originAirportIataCode',
                    filterType: FilterType.Array,
                    width: '135px',
                },
                {
                    name: 'destinationAirportIataCode',
                    filterField: 'destinationAirportIataCode',
                    filterType: FilterType.Array,
                    width: '170px',
                },
                {
                    name: 'orderSubType',
                    filterField: 'orderSubType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: OrderSubType,
                },
                {
                    name: 'originLocationLocationName',
                    filterField: 'originLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'destinationLocationLocationName',
                    filterField: 'destinationLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'plannedPickup',
                    filterField: 'plannedPickup',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'requiredContainers',
                    componentName: 'List',
                },
                {
                    name: 'containerSelectionUntilUtc',
                    componentName: 'ContainerSelectionUntil',
                    headerProps: {
                        children: 'Remaining / Overdue',
                    },
                },
            ],
        }],
    }
}

import React, {
    useState,
} from 'react'
import omit from 'lodash/omit'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import getUniqueId from 'app/utils/getUniqueId'

import {
    RequestConfig,
} from 'app/types/request.types'
import {
    ServiceCenterFormDefaultType,
    ServiceCenterFormType, ServiceCenterStatus, ServiceCenterType,
} from '../serviceCenters.types'
import getFields from './serviceCenterForm.fields'
import useStyles from './serviceCenterForm.style'
import request from '../serviceCenters.request'

const defaultProps = {
    data: undefined,
    disabled: false,
    onSuccess: null,
    exists: false,
    customButtons: [],
    requestParam: undefined,
}

const ServiceCenterForm = ({
    data,
    onSuccess,
    disabled,
    exists,
    customButtons,
    requestParam,
}: {
    data?: ServiceCenterFormType | ServiceCenterFormDefaultType,
    onSuccess?: (serviceCenter?: ServiceCenterFormType) => void,
    disabled?: boolean,
    exists?: boolean,
    customButtons?: JSX.Element[],
    requestParam?: RequestConfig
}) => {
    const getAllowedValues = useEnumValues()

    const [
        value,
        setValue,
    ] = useState<Partial<ServiceCenterFormType>>(data ? {
        ...data,
        storages: data.storages?.map((el) => {
            return {
                ...el,
                id: getUniqueId(),
            }
        }),
    } : {
        serviceCenterStatus: ServiceCenterStatus.PLANNED,
        enhancedSkyCenterScanning: true,
        orderSelectionDelay: 2,
    })

    const {
        classes,
    } = useStyles({
        isProductionSite: value?.serviceCenterType === ServiceCenterType.PRODUCTION_SITE,
    })

    const isPlannedStatus = value?.serviceCenterStatus === ServiceCenterStatus.PLANNED
    const isProductionSite = value?.serviceCenterType === ServiceCenterType.PRODUCTION_SITE

    const fields = getFields({
        getAllowedValues,
        isProductionSite,
        isPlannedStatus,
        exists,
    })
    const fieldsWithClasses = useFieldsWithClassName(fields, classes)

    const modifyDataBeforeSend = (
        dataForm: ServiceCenterFormType,
    ): Partial<ServiceCenterFormType> => {
        let requestData = dataForm

        if (!isPlannedStatus) {
            requestData = omit(dataForm, 'serviceCenterPlannedStartDate')
        }
        if (!isProductionSite) {
            requestData = omit(dataForm, 'containerProductionRate')
        }

        return requestData
    }

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={value}
            setExternalValue={setValue}
            name={request.domainName}
            fields={fieldsWithClasses}
            disabled={disabled}
            requestParams={requestParam}
            modifyDataBeforeSend={modifyDataBeforeSend}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            customButtons={customButtons}
            exists={exists}
        />
    )
}

ServiceCenterForm.defaultProps = defaultProps

export default ServiceCenterForm

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    UnassignedToOpportunity,
} from '../KamDashboard.types'
import {
    DeliveryServiceType,
} from '../../../OrderManagement/Lanes/lanes.types'
import {
    CollectionServiceType,
} from '../../../OrderManagement/Orders/orders.types'
import {
    LaneStatus,
} from '../../AccountLanes/AccountLanes.types'

const toBeAssignedLanesConfig = (): SkyNetTableConfig<keyof UnassignedToOpportunity> => {
    return {
        name: 'toBeAssigned',
        tabs: [{
            name: 'To Be Assigned',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'laneNumber',
                    labelKey: 'AccountLane.laneNumber',
                },
                {
                    name: 'laneGroupNumber',
                    labelKey: 'Lane.laneGroup',
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.pricingAccount',
                },
                {
                    name: 'temperatureRange',
                    componentName: 'EnumValue',
                    allowedValuesEnum: LaneGroupTemperatureRange,
                    filterField: 'temperatureRange',
                    filterType: FilterType.Array,
                    labelKey: 'AccountLane.temperatureRange',
                },
                {
                    name: 'customerQuotation',
                    filterField: 'customerQuotation',
                    filterType: FilterType.Array,
                    labelKey: 'AccountQuotes.customerQuotation',
                },
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumValue',
                    allowedValuesEnum: DeliveryServiceType,
                    labelKey: 'LaneGroupView.deliveryService',
                },
                {
                    name: 'collectionServiceType',
                    labelKey: 'LaneGroupView.collService',
                    componentName: 'EnumValue',
                    allowedValuesEnum: CollectionServiceType,
                },
                {
                    name: 'originAirport',
                    labelKey: 'OrderView.from',
                },
                {
                    name: 'destinationAirport',
                    labelKey: 'OrderView.to',
                },
                {
                    name: 'laneStatus',
                    componentName: 'EnumValue',
                    allowedValuesEnum: LaneStatus,
                    labelKey: 'LaneQuoteRequest.selectedLaneStatus',
                },
                {
                    name: 'changedOn',
                    labelKey: 'KamDashboard.lastUpdated',
                },
            ],
        }],
    }
}

export default toBeAssignedLanesConfig

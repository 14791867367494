import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    ContactMainType,
} from 'app/types/enums'
import {
    Contact,
} from 'app/Apps/ContactManagement/Contacts/Contacts.types'

type ContactTable = Contact & {
    locationLocationName: string,
    locationCompanyCompanyName: string
}

const config: SkyNetTableConfig<keyof ContactTable> = {
    name: 'ContactsTable',
    fields: [
        {
            name: 'contactName',
            filterField: 'contactName',
            reduced: true,
        },
        {
            name: 'email',
            filterField: 'email',
        },
        {
            name: 'phone',
            filterField: 'phone',
        },
        {
            name: 'isMainContact',
            componentName: 'ChipCheckMark',
            isHidden: true,
        },
        {
            name: 'locationLocationName',
            filterField: 'locationLocationName',
            labelKey: 'location',
            isHidden: true,
        },
        {
            name: 'locationCompanyCompanyName',
            filterField: 'locationCompanyCompanyName',
            reduced: true,
            labelKey: 'Location.company',
        },
        {
            name: 'mainType',
            componentName: 'EnumList',
            allowedValuesEnum: ContactMainType,
            isHidden: true,
        },
        {
            name: 'createdBy',
            filterField: 'createdBy',
            labelKey: 'AccountQuotes.createdBy',
            isHidden: true,
        },
    ],
}

export default config

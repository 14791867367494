import React, {
    useMemo, useCallback, useState,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    AdditionalFeesStatus,
} from 'app/types/enums'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import Button from 'app/shared-components/Button'
import {
    useChangeValidity,
} from 'app/shared-components/ChangeValidity'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'

import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'
import useContractBasisContext from 'app/Apps/Pricing/ContractBasis/hooks/useContractBasisContext'

import useRequestWithConfirmation from 'app/hooks/useRequestWithConfirmation'

import {
    useCollapsibleTableContext,
} from 'app/shared-components/CollapsibleTable'
import AdditionalFeesForm from '../AdditionalFeesForm'
import AdditionalFeesRequests from '../AdditionalFees.requests'
import AdditionalFeeChangeValidity from '../AdditionalFeeChangeValidity'
import AdditionalFeeCancellation from '../AdditionalFeeCancellation'
import useGetAdditionalFeesById from '../hooks/useGetAdditionalFeesById'

type Props = {
    id: number,
    refetch: (...args: any[]) => void
}

export default function AdditionalFeesUpdate({
    id, refetch,
}: Readonly<Props>) {
    const {
        ContractBasis: ContractBasisRoutes,
    } = usePricingRoutes()

    const {
        updatePathWithParams,
    } = useCollapsibleTableContext()

    const {
        contractId,
    } = useContractBasisContext()

    const [
        reason,
        setReason,
    ] = useState('')

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAdditionalFeesById(id)

    const onSuccess = useCallback(({
        id: newId,
    }) => {
        updatePathWithParams({
            id: newId,
        })
        invalidate()
        refetch()
    }, [
        invalidate,
        updatePathWithParams,
        refetch,
    ])

    const onCancel = useCallback(() => {
        updatePathWithParams({
            id: data?.id,
        })
        invalidate()
    }, [
        invalidate,
        data?.id,
        updatePathWithParams,
    ])

    const requestParams = useMemo(() => {
        if (data?.additionalFeesStatus !== AdditionalFeesStatus.IN_PROGRESS) {
            return AdditionalFeesRequests.editableData({
                id,
            })
        }

        return AdditionalFeesRequests.update({
            id,
        })
    }, [
        data?.additionalFeesStatus,
        id,
    ])

    const onChangeValiditySuccess = useCallback(() => {
        refetch()
        ContractBasisRoutes.AdditionalFees.navigate({
            params: {
                key: contractId,
                id: data?.id,
            },
        })
    }, [
        refetch,
        ContractBasisRoutes.AdditionalFees,
        contractId,
        data?.id,
    ])

    const changeValidity = useChangeValidity({
        requestParamFn: AdditionalFeesRequests.validity,
        notificationId: 'AdditionalFeesUpdateDto',
        title: 'Additional Fee',
        data: {
            id: data?.id,
            validFrom: data?.validFrom,
            validTo: data?.validTo,
        },
        onSuccess: onChangeValiditySuccess,
    })

    const onCancelFeeSuccess = useCallback(() => {
        onSuccess({
            id: data?.id,
        })
        return ContractBasisRoutes.AdditionalFees.navigate({
            params: {
                key: contractId,
                id: data?.id,
            },
        })
    }, [
        contractId,
        onSuccess,
        data,
        ContractBasisRoutes,
    ])

    const cancelFee = useRequestWithConfirmation({
        requestParamFn: AdditionalFeesRequests.cancellation,
        notificationId: 'AdditionalFeesUpdateDto',
        title: 'Additional Fee',
        data: {
            ...data,
            commentForBilling: reason,
        },
        actionDone: 'Cancelled',
        onSuccess: onCancelFeeSuccess,
    })

    const validFee = useRequestWithConfirmation({
        requestParamFn: AdditionalFeesRequests.validation,
        notificationId: 'AdditionalFeesUpdateDto2',
        title: 'Additional Fee',
        data: {
            ...data,
            commentForBilling: reason,
        },
        actionDone: 'Validated',
        onSuccess: onCancelFeeSuccess,
    })

    const archive = useArchive({
        requestParamFn: data?.archived ? AdditionalFeesRequests.restore
            : AdditionalFeesRequests.archive,
        notificationId: 'AdditionalFeeUpdateDto',
        title: 'Additional Fee',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: ContractBasisRoutes.AdditionalFees.path,
            params: {
                key: contractId,
            },
        }),
        onSuccess,
    })

    const customButtonsDisabled = useMemo(() => {
        return data?.additionalFeesStatus === AdditionalFeesStatus.CANCELLED
            || data?.additionalFeesStatus === AdditionalFeesStatus.VALID
    }, [data])

    const customButtons = useMemo(() => {
        return (
            [
                <Button
                    key="Copy"
                    label="Copy"
                    onClick={() => {
                        ContractBasisRoutes.AdditionalFeesCopy.navigate({
                            params: {
                                key: contractId,
                                id: data?.id,
                                action: 'copy',
                            },
                        })
                    }}
                />,
                <Button
                    key="Set to Valid"
                    label="Set to Valid"
                    onClick={validFee.askConfirmation}
                    disabled={customButtonsDisabled}
                />,
                <Button
                    key="Set to Canceled"
                    label="Set to Canceled"
                    onClick={cancelFee.askConfirmation}
                    disabled={data?.additionalFeesStatus === AdditionalFeesStatus.CANCELLED}
                />,
                <Button
                    key="Change Validity"
                    label="Change Validity"
                    onClick={changeValidity.handleChangeValidity}
                    disabled={data?.additionalFeesStatus !== AdditionalFeesStatus.VALID}
                />,
                <ArchiveButton
                    key="archive-button"
                    onClick={archive.handleArchiveRestore}
                    archived={data?.archived}
                />,
            ]
        )
    }, [
        contractId,
        data,
        ContractBasisRoutes,
        changeValidity.handleChangeValidity,
        cancelFee.askConfirmation,
        archive.handleArchiveRestore,
        validFee.askConfirmation,
        customButtonsDisabled,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <AdditionalFeesForm
                    value={data}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParams={requestParams}
                    customButtons={customButtons}
                />
                <AdditionalFeeChangeValidity
                    changeValidity={changeValidity}
                    id={data?.id}
                />
                <AdditionalFeeCancellation
                    id={data?.id}
                    cancelFee={cancelFee}
                    setReason={setReason}
                    reason={reason}
                />
                <ArchiveConfirmationDialog
                    title="Additional Fee"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
                <ConfirmationDialog
                    open={validFee.openConfirmDialog}
                    handleClose={validFee.doAction}
                    dialogTitle="VALIDATE ADDITIONAL FEE CONFIRMATION"
                    dialogContent="Do you want to validate this Additional Fee?"
                />
            </>
        </StatusHandler>
    )
}

import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import AccountManagementRequests from '../../AccountManagement.request'
import AccountForm from '../AccountForm'

const CreateAccount = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <AccountForm
            onSuccess={onSuccess}
            requestParam={AccountManagementRequests.create.request()}
        />
    )
}

export default CreateAccount

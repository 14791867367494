import React, {
    useMemo,
} from 'react'

import Card from 'app/shared-components/Card'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import useLabel from 'app/hooks/useLabel'
import useGetLaneGroupsPricingAssignment from './hooks/useGetLaneGroupsPricingAssignment'
import requests from '../AccountQuotes.requests'
import useStyles from '../LanesToBeGrouped/LanesToBeGrouped.styles'
import getColumns from './AssignPricing.columns'
import {
    AccountQuoteContext, AccountQuoteContextType,
} from '../AccountQuoteContext/useAccountQuoteContext'

import AssignPricingLaneGroupUpdateWrapper from './AssignPricingLaneGroupUpdate'
import useGetAccountQuote from '../hooks/useGetAccountQuote'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const AssignPricing = ({
    id,
}: {
    id: number
}) => {
    const {
        Quotes: QuotesRoutes,
    } = useAccountManagementRoutes()
    const l = useLabel()
    const {
        classes,
    } = useStyles()
    const {
        data: quote,
    } = useGetAccountQuote(id)

    const requestHandlers = useGetLaneGroupsPricingAssignment(id)

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const actionComponents = useMemo(() => {
        return {
            Update: AssignPricingLaneGroupUpdateWrapper,
        }
    }, [])

    const accountQuoteContext = useMemo(() => {
        return {
            quoteId: id,
            ...(quote || {}),
        } as AccountQuoteContextType
    }, [
        id,
        quote,
    ])

    return (
        <AccountQuoteContext.Provider value={accountQuoteContext}>
            <div
                data-testid="LaneGroupsAssignPricing"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="LanesGroups-card"
                >
                    <CollapsibleTable
                        title="Lane Groups"
                        configName="AssignPricingLaneGroups"
                        domainName={requests.domainName}
                        columns={columns}
                        customUrl={null}
                        requestHandlers={requestHandlers}
                        actionComponents={actionComponents}
                        createEnabled={false}
                        tableControls={[]}
                        switchableFormTable
                        backToTableText="Back To Lane Groups"
                        routeParams={QuotesRoutes.AssignPricing.params.map(({
                            name,
                        }) => {
                            return name
                        })}
                    />
                </Card>
            </div>
        </AccountQuoteContext.Provider>
    )
}

export default AssignPricing

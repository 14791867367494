import {
    ProductType,
} from 'app/types/enums'

export enum StatusLongTermLease {
    NOT_STARTED = 'NOT_STARTED',
    CONFIRMED = 'CONFIRMED',
    BOOKED = 'BOOKED',
    RENTAL = 'RENTAL',
    CLOSED = 'CLOSED',
}

export type RequiredContainersLongTermLease = {
    containerType: ProductType,
    quantity: number,
    tareWeight: number,
    extDimHeight: number,
    extDimWidth: number,
    extDimLength: number,
}

export type LongTermLeaseTableType = {
    contractNumber: string,
    customerExecutionLocationName: string,
    customerContactsContactName: string[],
    pricingAccountCompanyName: string,
    requiredContainers: RequiredContainersLongTermLease[],
    leaseStartDate: string,
    status: StatusLongTermLease,
}

import EnumList from 'app/shared-components/EnumList'
import EnumValue from 'app/shared-components/EnumValue'

import RequiredContainersList from './RequiredContainersList'
import AttitudeToSkyCellTable from './AttitudeToSkyCellTable'
import AvailabilityStatus from './AvailabilityStatus'

/* This object should contain all component that required
to define allowedValuesEnum: Record<string, string> in config  that we will use as componentName in:
 SkyNetTable and RichTableReduxWrapper */
export const EnumComponents = {
    EnumList,
    EnumValue,
    RequiredContainersList,
    AttitudeToSkyCellTable,
    AvailabilityStatus,
}

export type EnumComponentConfig = {
    componentName: keyof typeof EnumComponents,
    allowedValuesEnum: Record<string, any>,
}

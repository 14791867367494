import React, {
    useCallback,
    useMemo,
} from 'react'

import useEnumValues from 'app/hooks/useEnumValues'
import {
    ReturnedReasons,
} from 'app/shared-components/LaneFees/LaneFeesForm/reasons.types'
import useLabel from 'app/hooks/useLabel'

import CommentedReasonConfirmationDialog from '../CommentedReasonConfirmationDialog'

type ReturnResult = {
    returnReason: string,
    returnReasonComment: string,
}

type Props = {
    open: boolean,
    close: () => void,
    action: (a: ReturnResult) => void
}

const ReturnLaneFeesConfirmationDialog = ({
    open,
    close,
    action,
}: Readonly<Props>) => {
    const allReasons = useEnumValues()(ReturnedReasons)
    const l = useLabel()

    const titles = useMemo(() => {
        return {
            headerTitle: 'Add Return Reason',
            reasonTitle: l('LaneFees.returnReason'),
            commentTitle: l('LaneFeesUpdateDto.returnReasonComment'),
        }
    }, [l])

    const handleClose = useCallback((positive, {
        reason,
        reasonComment,
    }) => {
        if (positive) {
            action({
                returnReason: reason,
                returnReasonComment: reasonComment,
            })
        }
        close()
    }, [
        close,
        action,
    ])

    return (
        <CommentedReasonConfirmationDialog
            open={open}
            handleClose={handleClose}
            allReasons={allReasons}
            title={titles}
            initialReason={ReturnedReasons.PRICING_OTHER_REASON}
        />
    )
}

export default ReturnLaneFeesConfirmationDialog

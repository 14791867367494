import React, {
    useEffect, useMemo, useState,
} from 'react'
import omit from 'lodash/omit'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'

import {
    ModificationType, Order, TransportModeRoad,
} from '../../../orders.types'
import {
    PositioningSelection, PositioningSetupType,
} from '../orderConfirmation.types'
import getServiceCenterMap from '../getServiceCenterMap'
import useUpdatePositioningSetup from '../hooks/useUpdatePositioningSetup'
import getFields from './positioningSetup.fields'
import useStyles from './PositioningSetup.style'

const PositioningSetup = ({
    data,
    order,
    isDeliveryRoad,
    onSuccess,
}: {
    data: PositioningSetupType,
    order: Order,
    isDeliveryRoad: boolean,
    onSuccess: () => void
}) => {
    const getAllowedValues = useEnumValues()
    const serviceCenterMap = useMemo(() => {
        return getServiceCenterMap(data)
    }, [data])
    const {
        classes,
    } = useStyles()
    const [
        value,
        setValue,
    ] = useState(data)

    const {
        forwarderPositioningSelection,
        transportModeForwarder,
        forwarderPos,
        leaseType,
    } = value

    const [defaultValue] = useState(data)

    const [
        modification,
        setModification,
    ] = useState<ModificationType>()

    useEffect(() => {
        setValue((prevState) => {
            return {
                ...prevState,
                ...serviceCenterMap.forwarder[forwarderPositioningSelection].data,
            }
        })
    }, [
        forwarderPositioningSelection,
        serviceCenterMap.forwarder,
    ])

    const {
        mutate,
        isPending: isLoading,
    } = useUpdatePositioningSetup(onSuccess)

    const resetData = (): void => {
        setValue(defaultValue)
    }

    const fields = useMemo(() => {
        return getFields({
            order,
            transportModeForwarder,
            getAllowedValues,
            isDeliveryRoad,
            forwarderPositioningSelection,
            leaseType,
            forwarderPositioningId: forwarderPos?.id,
            isOtherByDefault: data.forwarderPositioningSelection
                === PositioningSelection.OTHER,
        })
    }, [
        order,
        transportModeForwarder,
        getAllowedValues,
        isDeliveryRoad,
        forwarderPositioningSelection,
        leaseType,
        forwarderPos?.id,
        data.forwarderPositioningSelection,
    ])

    const fieldsWithClassName = useFieldsWithClassName(fields, classes)

    const isSaveDisabled = defaultValue === value

    const update = (modificationType: ModificationType) => {
        mutate({
            modificationType,
            orderNumber: order?.orderNumber,
            data: transportModeForwarder === TransportModeRoad.FTL ? value : omit(value, [
                'numberOfTrucks',
                'numberOfVans',
            ]),
        })
        setModification(modificationType)
    }

    return (
        <>
            <DomainObjectForm
                value={value}
                onChange={setValue}
                fields={fieldsWithClassName}
                name="OrderByNumberView"
                className={classes.gridWrapper}
            />
            <ButtonContainer>
                <Button
                    label="Save (SkyCell)"
                    onClick={() => {
                        update(ModificationType.INTERNAL)
                    }}
                    data-testid="save-btn-internal"
                    disabled={
                        isSaveDisabled || isLoading
                    }
                    saving={isLoading && modification === ModificationType.INTERNAL}
                />
                <Button
                    label="Save (Customer)"
                    onClick={() => { update(ModificationType.CUSTOMER) }}
                    disabled={isSaveDisabled || isLoading}
                    data-testid="save-btn-customer"
                    saving={isLoading && modification === ModificationType.CUSTOMER}
                />
                <Button
                    secondary
                    label="Cancel"
                    onClick={resetData}
                    disabled={isLoading}
                    data-testid="cancel-btn"
                />
            </ButtonContainer>
        </>
    )
}

export default PositioningSetup

import React, {
    useMemo,
    useCallback,
} from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'
import {
    Select as MaterialSelect,
    MenuItem,
    FormControl,
    FormHelperText,
} from '@mui/material'

import Label from 'app/shared-components/Label'
import sortAscending from 'app/utils/sortAscending'

import useStyles from './Select.style'

const defaultProps = {
    title: '',
    name: '',
    data: [],
    allowedValues: {},
    onChange: noop,
    value: undefined,
    hasError: '',
    required: false,
    timeFormat: false,
    disableUnderline: false,
    disableSorting: false,
    disabled: false,
    disableHelperText: false,
    className: '',
}

export default function Select({
    title,
    name,
    onChange,
    value,
    data,
    allowedValues,
    hasError,
    required,
    timeFormat,
    disableUnderline,
    disableHelperText,
    disableSorting,
    disabled,
    className,
}: Readonly<{
    title?: string,
    name?: string,
    onChange?: (...a: any[]) => void,
    value?: string | number,
    data?: any[],
    allowedValues?: Record<string, string>,
    hasError?: string,
    required?: boolean,
    timeFormat?: boolean,
    disableUnderline?: boolean,
    disableHelperText?: boolean,
    disableSorting?: boolean,
    disabled?: boolean,
    className?: string,
}>) {
    const {
        classes,
    } = useStyles({
        required,
    })

    const options = useMemo(() => {
        const result = data.length === 0
            ? Object.entries(allowedValues)
            : data

        return disableSorting ? result : sortAscending(result)
    }, [
        data,
        allowedValues,
        disableSorting,
    ])

    const handleChange = useCallback(({
        target,
    }) => {
        onChange(
            target.value,
            {
                target,
            },
        )
    }, [onChange])

    return (
        <>
            <FormControl
                error={Boolean(hasError)}
                className={clsx(
                    classes.formControl,
                    className,
                )}
                variant="standard"
            >
                {title && (
                    <Label
                        title={title}
                        hasError={hasError}
                    />
                )}
                <div className={clsx(
                    {
                        [classes.selectContainer]: true,
                        [classes.isRequired]: required,
                        [classes.timeFormat]: timeFormat,
                    },
                )}
                >
                    <MaterialSelect
                        data-testid={`select-${name}`}
                        className={clsx(
                            {
                                [classes.background]: true,
                            },
                            className,
                        )}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        required={required}
                        color="primary"
                        fullWidth
                        disableUnderline={disableUnderline}
                        classes={{
                            root: classes.input,
                            select: classes.select,
                        }}
                        disabled={disabled}
                        inputProps={{
                            required,
                        }}
                    >
                        {!required ? (
                            <MenuItem value={undefined}>
                                <em>None</em>
                            </MenuItem>
                        ) : null}
                        {options.map(([
                            key,
                            val,
                        ]) => {
                            return (
                                <MenuItem
                                    key={`menuitem-${key}`}
                                    value={key}
                                >
                                    {val}
                                </MenuItem>
                            )
                        })}
                    </MaterialSelect>
                    {Boolean(hasError) && (
                        <FormHelperText
                            error
                            className={clsx(
                                {
                                    [classes.errorMessage]: true,
                                    [classes.hideHelperText]: disableHelperText,
                                },
                            )}
                        >
                            {hasError}
                        </FormHelperText>
                    )}
                </div>
            </FormControl>
        </>
    )
}

Select.defaultProps = defaultProps

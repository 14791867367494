import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import AccountLaneForm from '../AccountLaneForm'
import request from '../AccountLanes.requests'

const AccountLaneCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        state,
    } = useLocation()

    return (
        <AccountLaneForm
            data={{
                ...state?.autofill,
            }}
            requestParam={request.create()}
            onSuccess={onSuccess}
        />
    )
}

export default AccountLaneCreate

import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            alignItems: 'center',
            position: 'relative',
        },
        wrapper: {
            fontSize: theme.typography.body1.fontSize,
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        text: {
            color: theme.palette.primary[500],
            textDecoration: 'unset',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }
})

export default useStyles

import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        chart: {
            width: '100%',
        },
        fullscreen: {
            position: 'absolute',
            height: '85%',
            width: '100%',
        },
        title: {
            padding: theme.spacing(2),
        },
    }
})

export default useStyles

/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import {
    InputBase as MaterialInputBase,
} from '@mui/material'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import filterFieldForInput from 'app/utils/filterFieldForInput'

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
    inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

const defaultProps = {
    name: '',
    value: '',
    onChange: noop,
    type: 'text',
    disabled: false,
    inputProps: undefined,
    classes: undefined,
    autoComplete: undefined,
    required: false,
}

const getValue = ((value) => {
    return value !== '' ? Number(value) : null
})

const InputBase = (props) => {
    const {
        name,
        value,
        onChange,
        type,
        disabled,
        inputProps,
        required,
    } = props

    const handleChange = useCallback(({
        target,
    }) => {
        const newValue = type === 'number' ? getValue(target.value) : target.value

        onChange(
            newValue,
            {
                target: {
                    name,
                    value: newValue,
                },
            },
        )
    }, [
        name,
        onChange,
        type,
    ])

    return (
        <MaterialInputBase
            {...filterFieldForInput(props)}
            name={name}
            value={value}
            disabled={disabled}
            required={required}
            onChange={handleChange}
            inputProps={inputProps}
            onWheel={(event) => { event.currentTarget.querySelector('input')?.blur() }}
        />
    )
}

InputBase.propTypes = propTypes
InputBase.defaultProps = defaultProps

export default InputBase

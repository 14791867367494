import React, {
    useCallback, useMemo, useState,
} from 'react'
import {
    LaneStatus,
} from 'app/types/enums'
import Button from 'app/shared-components/Button'
import ButtonContainer from 'app/shared-components/ButtonContainer'

import AccountQuoteLaneGroupOverview from './LaneGroupOverview'
import AssignPricingLanes from './AssignPricingLanes'
import {
    AssignPricingLaneGroupResponse,
} from '../../AccountQuotes.types'
import useSetLaneStatus from '../hooks/useSetLaneStatus'

export default function AssignPricingLaneGroupUpdate({
    data,
    invalidate,
    refetch,
}: Readonly<{
    data: AssignPricingLaneGroupResponse,
    invalidate: () => void,
    refetch: () => void,
}>) {
    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        refetch,
        invalidate,
    ])

    const [
        selectedLanes,
        setSelectedLanes,
    ] = useState([])

    const updateLaneStatus = useSetLaneStatus({
        onSuccess,
    })

    const onSelect = useCallback(({
        id: laneId, selected,
    }) => {
        if (selected) {
            setSelectedLanes([
                ...selectedLanes,
                {
                    id: laneId,
                },
            ])

            return
        }

        setSelectedLanes(selectedLanes.filter((lane) => { return lane.id !== laneId }))
    }, [
        setSelectedLanes,
        selectedLanes,
    ])

    const lanesData = useMemo(() => {
        return (data?.lanes || []).map((lane) => {
            return {
                ...lane,
                selected: Boolean(selectedLanes.find((el) => { return el.id === lane.id })),
            }
        })
    }, [
        data?.lanes,
        selectedLanes,
    ])

    return (
        <>
            <AccountQuoteLaneGroupOverview data={data} />
            <AssignPricingLanes
                lanes={lanesData}
                onSelect={onSelect}
                onSuccess={onSuccess}
            />
            <ButtonContainer>
                <Button
                    key="set-as-priced"
                    label="Set as Priced"
                    onClick={() => {
                        updateLaneStatus({
                            laneStatus: LaneStatus.PRICED,
                            lanes: selectedLanes,
                        })
                    }}
                />
                <Button
                    key="set-as-rejected"
                    label="Set as Rejected"
                    onClick={() => {
                        updateLaneStatus({
                            laneStatus: LaneStatus.REJECTED_PRICING,
                            lanes: selectedLanes,
                        })
                    }}
                />
                <Button
                    key="set-as-cancelled"
                    label="Set as Cancelled"
                    onClick={() => {
                        updateLaneStatus({
                            laneStatus: LaneStatus.CANCELLED,
                            lanes: selectedLanes,
                        })
                    }}
                />
            </ButtonContainer>
        </>
    )
}

/* eslint-disable react/jsx-props-no-spreading */
import React, {
    Dispatch, SetStateAction,
} from 'react'

import Tabs from 'app/shared-components/Tabs'
import AddEntry from 'app/shared-components/AddEntry'
import PrintLabel from 'app/shared-components/PrintLabel'
import {
    useSkyNetDomainContext, SidebarTab,
} from 'app/shared-components/SkyNetDomain'

import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import ControlPanel from 'app/Apps/DomainObject/ControlPanel'

import useStyles from './SkyNetDomainHeader.styles'
import useCreateSkyNetDomainRoutes from '../useCreateSkyNetDomainRoutes'

const defaultProps = {
    reducedMode: false,
    simple: false,
    createForm: undefined,
    counter: undefined,
    printForm: undefined,
    controlPanelProps: undefined,
    tabs: undefined,
    selectedTab: undefined,
}

const SkyNetDomainHeader = ({
    reducedMode,
    simple,
    tableControls,
    tabs,
    counter,
    applySearch,
    controlPanelProps,
}: Readonly<{
    reducedMode: boolean,
    simple: boolean,
    tableControls?: TableControlTypes[],
    tabs: SkyNetTableTab[],
    counter: number,
    applySearch: Dispatch<SetStateAction<string>>,
    controlPanelProps: any,
}>) => {
    const {
        classes,
    } = useStyles()

    const Routes = useCreateSkyNetDomainRoutes()()

    const {
        printable, creatable,
    } = useSkyNetDomainContext()

    if (reducedMode || (simple && !tableControls)) {
        return null
    }

    return (
        <>
            <div className={simple ? classes.tableConfigSimple : classes.tableConfig}>
                {!reducedMode && (
                    <>
                        <Tabs
                            className={classes.tabs}
                            items={tabs as unknown as SidebarTab[]}
                            counter={counter}
                        />
                        {creatable && !simple && (
                            <AddEntry
                                to={Routes.All.Create.route}
                                data-testid="add-entry-button"
                            />
                        )}
                        {printable && !simple && (
                            <PrintLabel
                                to={Routes.All.Print.route}
                                data-testid="print-label-button"
                            />
                        )}
                    </>
                )}
            </div>
            <ControlPanel
                {...controlPanelProps} // eslint-disable-line react/jsx-props-no-spreading
                applySearch={applySearch}
                tableControls={tableControls}
                simple={simple}
            />
        </>
    )
}

SkyNetDomainHeader.defaultProps = defaultProps

export default SkyNetDomainHeader

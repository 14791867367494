/* eslint-disable import/prefer-default-export */
import {
    SkyNetTableConfig,
} from '../SkyNetTable'
import {
    RoutesConfig,
} from '../../SkyNetRoutes'

export type SubDomainUrlsConfig = {
    defaultSubDomainUrl?: string,
    urls: string[]
}

export function getUrlsFromTableConfig(tableConfig: SkyNetTableConfig) {
    const config = ('tabs' in tableConfig && tableConfig?.tabs) || []

    return config.reduce((acc, {
        url, defaultTab,
    }) => {
        if (defaultTab) {
            acc.defaultSubDomainUrl = url
        }
        acc.urls.push(url)
        return acc
    }, {
        defaultSubDomainUrl: undefined,
        urls: [],
    } as SubDomainUrlsConfig)
}

export function createChildEditRoutes(Routes: { [p: string]: RoutesConfig }) {
    return (children: Record<string, string>) => {
        return Object.entries(Routes).reduce((acc, [
            key,
            {
                route, params, via, navigate, ...rest
            },
        ]) => {
            return {
                ...acc,
                [key]: {
                    ...Object.entries(children).reduce((childAcc, [
                        child,
                        value,
                    ]) => {
                        return {
                            ...childAcc,
                            route,
                            params,
                            via,
                            navigate,
                            [child]: {
                                route: value,
                                via: {
                                    ...rest.Edit,
                                    path: rest.Edit?.path.slice(1),
                                },
                            },
                        }
                    }, {}),
                },
            }
        }, {})
    }
}

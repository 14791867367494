/* eslint-disable import/prefer-default-export */
export enum RequestStatuses
{
    PRISTIN = 'PRISTIN',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum RequestMethods {
    CREATE = 'CREATE',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
}

export enum FilterType {
    Array = 'array',
    DateRange = 'date-range',
    DayRange = 'dayRange',
    Range = 'range',
}

export enum KeyboardKeys {
    ENTER = 'Enter',
    ESCAPE = 'Escape',
    BACKSPACE = 'Backspace',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_UP = 'ArrowUp',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_DOWN = 'ArrowDown',
    COMMA = ',',
    PERIOD = '.',
    A = 'a',
}

export enum YesNo {
    YES = 'Yes',
    NO = 'No'
}

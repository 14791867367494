import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import requestConfig from '../orderConfirmation.request'
import {
    AvailabilityRequestData, OrderConfirmationAvailability,
} from '../orderConfirmation.types'

const useGetContainerAvailability = (
    params: AvailabilityRequestData,
) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useRequest<OrderConfirmationAvailability>({
        key: RequestKeys.getOrderConfirmationAvailability,
        params: {
            params,
        },
        requestFunc: RequestFn.getEntity(requestConfig.GetContainerAvailability.requestFn),
        enabled: Boolean(params?.serviceCenter?.id),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetContainerAvailability

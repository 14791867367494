import {
    useCallback,
} from 'react'
import {
    useSkyNetNavigate,
    createRoutesConfig, CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

export enum SkyNetDomainRoutes {
    ALL = 'all',
    BULK_EDIT = 'bulk-edit',
    BULK_CREATE = 'bulk-create',
}

export enum SkyNetDomainSubRoutes {
    EDIT = 'edit',
    PRINT = 'print',
    CREATE = 'create',
}

export default function useCreateSkyNetDomainRoutes() {
    const skyNetNavigate = useSkyNetNavigate()

    return useCallback((domains: Record<string, any> = {
        All: CommonRoutes.ALL,
    }) => {
        const config = {
            path: [],
            ...Object.entries(domains).reduce((acc, [
                key,
                value,
            ]) => {
                return {
                    ...acc,
                    [key]: {
                        route: value,
                        Edit: {
                            route: SkyNetDomainSubRoutes.EDIT,
                            params: [{
                                name: 'key',
                                required: true,
                            }],
                        },
                        Print: {
                            route: SkyNetDomainSubRoutes.PRINT,
                        },
                        Create: {
                            route: SkyNetDomainSubRoutes.CREATE,
                        },
                    },
                }
            }, {}),
            BulkEdit: {
                route: SkyNetDomainRoutes.BULK_EDIT,
            },
            BulkCreate: {
                route: SkyNetDomainRoutes.BULK_CREATE,
            },
        } as unknown as SkyNetRoutesConfig

        return createRoutesConfig(skyNetNavigate, {
            absolute: false,
        })(config)
    }, [skyNetNavigate])
}

import React, {
    useCallback, useMemo,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import HeaderWarning from 'app/shared-components/HeaderWarning'

import omit from 'lodash/omit'
import {
    LaneFeesStatus,
} from 'app/types/enums'
import useGetLaneFee from 'app/Apps/Pricing/LaneGroups/LaneFees/hooks/useGetLaneFee'
import LaneFeesRequests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import {
    useCollapsibleTableContext,
} from 'app/shared-components/CollapsibleTable'
import LaneFeesForm from '../LaneFeesForm'

const defaultProps = {
    id: undefined,
    refetch: () => {},
}

const LaneFeesCopy = ({
    id,
    refetch,
}: { id?: number, refetch?: (...args: any[]) => void }) => {
    const {
        updatePathWithParams,
    } = useCollapsibleTableContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLaneFee(id)

    const onSuccess = useCallback(({
        id: newId,
    }) => {
        updatePathWithParams({
            id: newId,
        })
        refetch()
    }, [
        updatePathWithParams,
        refetch,
    ])

    const onCancel = useCallback(() => {
        updatePathWithParams({
            id: data?.id,
        })
    }, [
        data?.id,
        updatePathWithParams,
    ])

    const copyData = useMemo(() => {
        return {
            ...omit(data, [
                'id',
                'changedBy',
                'changedOn',
                'laneFeesNumber',
                'truckingCapacity',
                'fees',
            ]),
            originId: data?.id,
            laneFeesStatus: LaneFeesStatus.IN_PROGRESS,
        }
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <HeaderWarning>
                    You are copying a Lane Fee.
                </HeaderWarning>
                <LaneFeesForm
                    data={copyData}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={LaneFeesRequests.duplicate({
                        laneFeesId: copyData?.originId,
                    })}
                />
            </>
        </StatusHandler>
    )
}

LaneFeesCopy.defaultProps = defaultProps

export default LaneFeesCopy

import React, {
    useCallback, useMemo,
} from 'react'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useLabel from 'app/hooks/useLabel'

import {
    RequiredContainersAvailabilityType,
} from '../orderConfirmation.types'
import getColumns from './requiredContainersAvailability.columns'
import useStyles from './RequiredContainersAvailability.style'

const defaultProps = {
    value: [],
}

const RequiredContainersAvailability = ({
    value,
}: {value?: RequiredContainersAvailabilityType[]}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const columns = useMemo(() => {
        return getColumns(l)
    }, [l])

    const load = useCallback(() => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    return (
        <RichTable
            configName="RequiredContainersAvailabilityTable"
            name="RequiredContainersAvailability"
            columns={columns}
            load={load}
            className={classes.tableContent}
            classNames={{
                contentWrapper: classes.tableContentWrapper,
            }}
        />
    )
}

RequiredContainersAvailability.defaultProps = defaultProps

export default RequiredContainersAvailability

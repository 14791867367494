import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import request from '../orderConfirmation.request'
import {
    LocationSelectionDataType,
} from '../orderConfirmation.types'

const useUpdateOriginServiceCenter = (onSuccess: () => void) => {
    const token = useJWTToken()
    const {
        onSuccess: onSuccessUpdate,
        onError,
    } = useHandleStatusRequest({
        id: 'UpdateOriginServiceCenter',
        onSuccess,
    })

    return useMutation({
        mutationFn: ({
            version,
            orderNumber,
            data,
        }: {
            version: number,
            orderNumber: string,
            data: LocationSelectionDataType,
        }) => {
            return request.UpdateOriginServiceCenter.mutationFn({
                orderNumber,
                version,
                data,
                token,
            })
        },
        onSuccess: onSuccessUpdate,
        onError,
    })
}

export default useUpdateOriginServiceCenter

import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useTableControlsByPermissions from 'app/hooks/useTableControlsByPermissions'
import useRouteStateFilter from 'app/hooks/useRouteStateFilter'
import getTableConfig from './opportunities.config'
import Overview from './Overview'
import DataUpdate from './DataUpdate'
import Lanes from './Lanes'
import CreateOpportunity from './CreateOpportunity'
import OpportunitiesChangeHistory from './OpportunitiesChangeHistory'
import OpportunitiesContainerInformation from './OpportunitiesContainerInformation'
import OpportunitiesBulkCreate from './OpportunititesBulkCreate'
import OpportunitiesBulkEdit from './OpportunitiesBulkEdit'
import useAccountManagementRoutes from '../hooks/useAccountManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.BulkCreate,
    TableControlTypes.BulkEdit,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Opportunities = () => {
    const {
        Opportunities: OpportunitiesRoutes,
    } = useAccountManagementRoutes()
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const filter = useRouteStateFilter()
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: OpportunitiesRoutes.Overview.route,
                Component: Overview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: OpportunitiesRoutes.Update.route,
                Component: DataUpdate,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Container Information',
                url: OpportunitiesRoutes.ContainerInformation.route,
                Component: OpportunitiesContainerInformation,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Lanes',
                url: OpportunitiesRoutes.Lanes.route,
                Component: Lanes,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Change History',
                url: OpportunitiesRoutes.ChangeHistory.route,
                Component: OpportunitiesChangeHistory,
            },
            permission: true,
        },
    ])

    const restrictedTableControls = useTableControlsByPermissions(tableControls, {
        [TableControlTypes.BulkEdit]: accountUpdate,
        [TableControlTypes.BulkCreate]: accountCreate,
    })

    const actionComponents = useMemo(() => {
        return {
            Create: accountCreate ? CreateOpportunity : undefined,
            BulkCreate: accountCreate ? OpportunitiesBulkCreate : undefined,
            BulkEdit: accountUpdate ? OpportunitiesBulkEdit : undefined,
        }
    }, [
        accountCreate,
        accountUpdate,
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={actionComponents}
            name={tableConfig.name}
            defaultTab="overview"
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={restrictedTableControls}
                tableConfig={tableConfig}
                showSearch
                filter={filter}
            />
        </SkyNetDomain>
    )
}

export default Opportunities

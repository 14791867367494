import {
    RequestConfig,
} from 'app/types/request.types'
import {
    Navigate,
    useParams, Outlet, useResolvedPath, useNavigate,
} from 'react-router-dom'
import React, {
    useCallback,
    useMemo,
} from 'react'
import useSkyNetRoutes from 'app/SkyNetRoutes/hooks/useSkyNetRoutes'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import SideBar from 'app/shared-components/SideBar'
import Tabs from 'app/shared-components/Tabs'

import useGetDomainObjectById from 'app/Apps/DomainObject/hooks/useGetDomainObjectById'
import {
    SidebarTab,
} from '../SkyNetDomain.types'
import useSkyNetDomainContext from '../useSkyNetDomainContext'
import useStyles from './SkyNetDomainTabs.styles'

const SkyNetDomainTabs = ({
    getDomainObject,
    tabs,
}: Readonly<{
    getDomainObject?: (params: Record<string, any>) => RequestConfig,
    tabs: SidebarTab[],
}>) => {
    const {
        classes,
    } = useStyles()
    const {
        setSelectedTab,
        selectedTab,
        requestKey,
    } = useSkyNetDomainContext()

    const {
        key, ...rest
    } = useParams()

    const navigate = useNavigate()
    const {
        pathname,
    } = useResolvedPath('..')

    const {
        data,
    } = useGetDomainObjectById({
        id: key,
        requestKey,
        requestFn: getDomainObject,
    })

    const sidebarTabs = useMemo(() => {
        if (!getDomainObject) {
            return tabs
        }

        if (!data) {
            return undefined
        }

        return tabs.filter(({
            condition,
        }) => {
            if (condition) {
                return condition(data)
            }

            return true
        })
    }, [
        data,
        getDomainObject,
        tabs,
    ])

    const isSideBarTabAvailable = useCallback((tab) => {
        return (sidebarTabs || []).find(({
            url,
        }) => {
            return tab?.split('/').includes(url)
        })
    }, [sidebarTabs])

    const RedirectElement = useMemo(() => {
        const route = rest[CommonRoutes.ASTERISK] || selectedTab

        if (isSideBarTabAvailable(route)) {
            return (
                <Navigate
                    to={route}
                    replace
                />
            )
        }
        return (
            <Navigate
                to={sidebarTabs?.[0].url || '..'}
                replace
            />
        )
    }, [
        rest,
        isSideBarTabAvailable,
        selectedTab,
        sidebarTabs,
    ])

    return useSkyNetRoutes({
        routes: [{
            path: `${CommonRoutes.SLASH}`,
            element: (
                <SideBar
                    onClose={() => {
                        navigate(pathname)
                    }}
                >
                    <div className={classes.tabsWrapper}>
                        <div className={classes.tabs}>
                            <Tabs
                                items={sidebarTabs}
                                setSelectedTab={setSelectedTab}
                            />
                        </div>
                    </div>
                    <Outlet />
                    {RedirectElement}
                </SideBar>
            ),
            children: (sidebarTabs || []).map(({
                url,
                Component,
                params,
            }) => {
                return {
                    path: `${url}/${params?.length ? params?.concat(CommonRoutes.SLASH) : ''}${CommonRoutes.ASTERISK}`,
                    element: (
                        <>
                            <div className={classes.tabContentWrapper}>
                                <div className={classes.tabContent}>
                                    <Component
                                        id={key}
                                        data={data}
                                    />
                                </div>
                            </div>
                        </>
                    ),
                }
            }),
        }],
    })
}

export default SkyNetDomainTabs

import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    CollServiceType, DeliveryServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import SkyNetLink, {
    TypeLink,
    SizeLink,
} from 'app/shared-components/SkyNetLink'
import {
    OpportunityFrequency,
} from 'app/types/enums'
import {
    AccountLanesTable,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    RequestedLane,
    RequestedOpportunity,
} from '../../AccountQuotes.types'

const columns = ({
    l,
    laneStyle,
    getLaneLink,
}: {
    l: (l: string) => string,
    laneStyle: string,
    getLaneLink: (id: string) => string
}): RichTableColumns<keyof (RequestedLane & AccountLanesTable
    & Pick<RequestedOpportunity, 'productTypes'>)>[] => {
    return [
        {
            id: 'laneNumber',
            headerProps: {
                children: l('AccountQuotes.lanes'),
            },
            mapCellProps: ({
                laneNumber,
                id,
            }) => {
                return {
                    value: laneNumber,
                    link: getLaneLink(id),
                    type: TypeLink.EXTERNAL,
                    className: laneStyle,
                    size: SizeLink.S,
                }
            },
            Cell: SkyNetLink,
        },
        {
            id: 'expPalletsYear',
            headerProps: {
                children: 'Expected Pallets / Year',
            },
            mapCellProps: 'expPalletsYear',
        },
        {
            id: 'frequency',
            headerProps: {
                children: 'Frequency',
            },
            mapCellProps: 'frequency',
            componentName: 'EnumValue',
            allowedValuesEnum: OpportunityFrequency,
        },
        {
            id: 'originAirport',
            headerProps: {
                children: l('AccountQuotes.laneFrom'),
            },
            mapCellProps: 'originAirport',
        },
        {
            id: 'destinationAirport',
            headerProps: {
                children: l('AccountQuotes.laneTo'),
            },
            mapCellProps: 'destinationAirport',
        },
        {
            id: 'deliveryServiceType',
            headerProps: {
                children: l('AccountQuotes.laneDeliveryType'),
            },
            mapCellProps: 'deliveryServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: DeliveryServiceType,
        },
        {
            id: 'collectionServiceType',
            headerProps: {
                children: l('AccountQuotes.laneCollectionType'),
            },
            mapCellProps: 'collectionServiceType',
            componentName: 'EnumValue',
            allowedValuesEnum: CollServiceType,
        },
        {
            id: 'productTypes',
            componentName: 'List',
            headerProps: {
                children: l('AccountQuotes.productTypes'),
            },
            mapCellProps: 'productTypes',
        },
        {
            id: 'baseLeaseDays',
            headerProps: {
                children: l('AccountQuotes.laneBaseLeaseDays'),
            },
            mapCellProps: ({
                baseLeaseDays,
            }) => {
                return {
                    children: baseLeaseDays.join(', '),
                }
            },
        },
        {
            id: 'otherComments',
            headerProps: {
                children: l('AccountQuotes.quoteLaneComment'),
            },
            mapCellProps: 'otherComments',
        },
    ]
}

export default columns

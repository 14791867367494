import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission'

import DeploymentCreate from './DeploymentCreate'
import DeploymentRequests from './Deployments.request'
import DeploymentOverview from './DeploymentOverview'
import getTableConfig from './Deployments.config'
import DeploymentUpdate from './DeploymentUpdate'
import useDeploymentRoutes from './hooks/useDeploymentRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearSorting,
]

const Deployments = () => {
    const {
        Deployments: DeploymentsRoutes,
    } = useDeploymentRoutes()
    const gatewayCreate = useHasPermission(['gateway_create'])
    const gatewayUpdate = useHasPermission(['gateway_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: DeploymentsRoutes.Overview.route,
                label: 'Overview',
                Component: DeploymentOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: DeploymentsRoutes.Update.route,
                label: 'Data Update',
                Component: DeploymentUpdate,
            },
            permission: gatewayUpdate,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: gatewayCreate ? DeploymentCreate : undefined,
            }}
            sidebarTabs={tabs}
            name="Deployment"
            defaultTab={DeploymentsRoutes.Overview.route}
        >
            <SkyNetTable
                customUrl={DeploymentRequests.all}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default Deployments

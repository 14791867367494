import React from 'react'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

import useStyles from './RadioButtonTile.style'

const defaultProps = {
    imgSrc: undefined,
    disabled: false,
}

const RadioButtonTile = ({
    imgSrc,
    title,
    onClick,
    selected,
    disabled,
}: {
    imgSrc?: string,
    title: string,
    onClick: () => void
    selected: boolean,
    disabled?: boolean,
}) => {
    const {
        classes,
        cx,
    } = useStyles()

    return (
        <div
            onClick={onClick}
            onKeyDown={onClick}
            className={cx(
                classes.root,
                selected ? classes.selected : classes.unselected,
                disabled && classes.disabled,
            )}
        >
            <div className={classes.wrapper}>
                {imgSrc && (
                    <img
                        src={imgSrc}
                        alt={title}
                        className={classes.img}
                    />
                )}
                <div>{title}</div>
            </div>
            {selected && (
                <RadioButtonCheckedIcon className={classes.icon} />
            )}
            {!selected && (
                <RadioButtonUncheckedIcon className={classes.icon} />
            )}
        </div>
    )
}

RadioButtonTile.defaultProps = defaultProps

export default RadioButtonTile

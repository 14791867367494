import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    CollServiceType,
    DeliveryServiceType,
    LaneOpsOverview,
} from './lanes.types'

export default (): SkyNetTableConfig<keyof LaneOpsOverview> => {
    return {
        name: 'OrderManagement.LanesTable',
        tabs: [{
            name: 'All Lanes',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'laneNumber',
                    reduced: true,
                },
                {
                    name: 'leaseType',
                    filterField: 'leaseType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: LeaseType,
                },
                {
                    name: 'customerQuotationLocationName',
                    filterField: 'customerQuotationLocationName',
                    filterType: FilterType.Array,
                    labelKey: 'customerQuotation',
                },
                {
                    name: 'customerExecutionLocationName',
                    filterField: 'customerExecutionLocationName',
                    filterType: FilterType.Array,
                    reduced: true,
                    labelKey: 'customerExecution',
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    filterType: FilterType.Array,
                    reduced: true,
                    labelKey: 'Prebooking.pricingAccount',
                },
                {
                    name: 'originAirportIataCode',
                    filterField: 'originAirportIataCode',
                    filterType: FilterType.Array,
                    labelKey: 'originAirport',
                },
                {
                    name: 'destAirportIataCode',
                    filterField: 'destAirportIataCode',
                    filterType: FilterType.Array,
                    labelKey: 'destAirport',
                },
                {
                    name: 'tempRange',
                    filterField: 'tempRange',
                    filterType: FilterType.Array,
                },
                {
                    name: 'handoverPointLocationName',
                    filterType: FilterType.Array,
                    filterField: 'handoverPointLocationName',
                    labelKey: 'handoverPoint',
                },
                {
                    name: 'consigneeLocationName',
                    filterType: FilterType.Array,
                    filterField: 'consigneeLocationName',
                    labelKey: 'consignee',
                },
                {
                    name: 'deliveryServiceType',
                    filterType: FilterType.Array,
                    filterField: 'deliveryServiceType',
                    componentName: 'EnumValue',
                    allowedValuesEnum: DeliveryServiceType,
                },
                {
                    name: 'collectionServiceType',
                    filterType: FilterType.Array,
                    filterField: 'collectionServiceType',
                    componentName: 'EnumValue',
                    allowedValuesEnum: CollServiceType,
                },
                {
                    name: 'validPricingTo',
                    filterField: 'validPricingTo',
                    filterType: FilterType.DateRange,
                    headerProps: {
                        children: 'Valid To',
                    },
                    componentName: 'LaneDateEnriched',
                },
                {
                    name: 'laneStatus',
                    filterType: FilterType.Array,
                    filterField: 'laneStatus',
                    componentName: 'LaneStatus',
                },
            ],
        }],
    }
}

import {
    IncoTerm, Lane,
} from '../../lanes.types'

const getTextFromOriginAirport = (lane: Lane) => {
    return `${lane?.incoTerm} ${lane?.originAirport?.locationName}`
}

const getTextFromDestinationAirport = (lane: Lane) => {
    return `${lane?.incoTerm} ${lane?.destinationAirport?.locationName}`
}

const getText = (lane: Lane) => {
    if (lane?.consignee) {
        return `${lane?.incoTerm} ${lane?.consignee?.city}`
    }

    return `${lane?.incoTerm} ${lane?.collectionDropoffPoint?.city}`
}

const incoTermAdditionalTextMap = {
    [IncoTerm.EXW]: (lane: Lane) => { return `${lane.handoverPoint.city}` },
    [IncoTerm.FCA]: getTextFromOriginAirport,
    [IncoTerm.FOB]: getTextFromOriginAirport,
    [IncoTerm.FAS]: getTextFromOriginAirport,
    [IncoTerm.CIP]: getTextFromDestinationAirport,
    [IncoTerm.CIF]: getTextFromDestinationAirport,
    [IncoTerm.CFR]: getTextFromDestinationAirport,
    [IncoTerm.CPT]: getTextFromDestinationAirport,
    [IncoTerm.DAP]: getText,
    [IncoTerm.DPU]: getText,
    [IncoTerm.DDP]: getText,
}

export default incoTermAdditionalTextMap

import React, {
    useMemo,
} from 'react'
import groupBy from 'lodash/groupBy'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import contractBasisRequests from './ContractBasis.requests'

import ContractBasisCreate from './ContractBasisCreate'
import ContractBasisCreateQuote from './ContractBasisCreateQuote'
import getTableConfig from './ContractBasis.config'
import ContractBasisDetails from './ContractBasisDetails'
import AdditionalFees from './AdditionalFees'
import LaneGroups from './LaneGroups'
import usePricingRoutes from '../hooks/usePricingRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ContractBasisTable = () => {
    const {
        ContractBasis: ContractBasisRoutes,
    } = usePricingRoutes()
    const tabs: SidebarTab[] = [
        {
            url: ContractBasisRoutes.ContractBasis.route,
            label: 'Contract Basis',
            Component: ContractBasisDetails,
            activeComparer: (pathname, tabUrl) => {
                const pathnamesOccurrences = groupBy(pathname.split('/'))

                if (pathnamesOccurrences[tabUrl]?.length >= 2) {
                    return true
                }
                return false
            },
        },
        {
            url: ContractBasisRoutes.AdditionalFees.route,
            label: 'Additional Fees',
            Component: AdditionalFees,
            activeComparer: (pathname) => {
                return pathname.split('/').includes('additional-fees')
            },
        },
        {
            url: ContractBasisRoutes.LaneGroups.route,
            label: 'Lane Groups',
            Component: LaneGroups,
            activeComparer: (pathname) => {
                return pathname.split('/').includes('lane-groups')
            },
        },
        {
            url: ContractBasisRoutes.CreateQuote.route,
            label: 'Create Quote',
            Component: ContractBasisCreateQuote,
        },
    ]

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: ContractBasisCreate,
            }}
            name={contractBasisRequests.domainName}
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default ContractBasisTable

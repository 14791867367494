import React, {
    useCallback,
    useState,
    useEffect,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    ContractBasis,
} from 'app/Apps/Pricing/Pricing.types'
import StatusHandler from 'app/shared-components/StatusHandler'

import {
    useArchive,
    ArchiveButton,
    ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import {
    RequestConfig,
} from 'app/types/request.types'
import contractBasisFields from './contractBasisDetails.fields'
import useStyles from './ContractBasisDetails.styles'
import requests from '../ContractBasis.requests'
import useGetContractBasis from '../hooks/useGetContractBasis'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id?: number,
    onSuccess: (newContractBasis: any) => void,
    requestParams: RequestConfig
}

const defaultProps: Partial<Props> = {
    id: null,
}

const ContractBasisDetails = ({
    id,
    onSuccess,
    requestParams,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        ContractBasis: ContractBasisRoutes,
    } = usePricingRoutes()
    const [
        hasNDA,
        setHasNDA,
    ] = useState(null)

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetContractBasis(id)

    const exists = Boolean(data?.id)

    const fieldsWithClasses = useFieldsWithClassName(
        contractBasisFields(hasNDA, getAllowedValues, exists),
        classes,
    )

    useEffect(() => {
        if (isDataReady) {
            setHasNDA(data?.hasNDA)
        }
    }, [
        data,
        isDataReady,
    ])

    const archive = useArchive({
        requestParamFn: data?.archived ? requests.restore
            : requests.archive,
        notificationId: 'ContractBasisUpdateDto',
        title: 'Contract Basis',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: ContractBasisRoutes.path,
        }),
    })

    const setExternalValue = useCallback((update: ContractBasis) => {
        setHasNDA(update.hasNDA)
    }, [])

    const modifyDataBeforeSend = useCallback((newData) => {
        if (newData.hasNDA) {
            return {
                ...newData,
                account: undefined,
            }
        }
        return newData
    }, [])

    const handleSuccess = useCallback((contractBasis) => {
        invalidate()

        if (onSuccess) {
            onSuccess(contractBasis)
        }
    }, [
        onSuccess,
        invalidate,
    ])

    return (
        <StatusHandler
            isSuccess={exists ? isDataReady : true}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <FormWithControls
                    name={requests.domainName}
                    setExternalValue={setExternalValue}
                    value={data}
                    fields={fieldsWithClasses}
                    classNames={{
                        gridWrapper: classes.gridWrapper,
                    }}
                    onSuccess={handleSuccess}
                    modifyDataBeforeSend={modifyDataBeforeSend}
                    requestParams={requestParams ?? requests.update({
                        id: data?.id,
                    })}
                    customButtons={exists ? [<ArchiveButton
                        onClick={archive.handleArchiveRestore}
                        archived={data?.archived}
                    />] : undefined}
                    exists={exists}
                />
                <ArchiveConfirmationDialog
                    title="Contract Basis"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

ContractBasisDetails.defaultProps = defaultProps

export default ContractBasisDetails

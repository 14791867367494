import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    ProductType,
} from 'app/types/enums'

import {
    AvailabilityStatus,
    RequiredContainersAvailabilityType,
} from '../orderConfirmation.types'

const getColumns = (
    l: (l:string) => string,
): RichTableColumns<keyof RequiredContainersAvailabilityType>[] => {
    return [
        {
            id: 'productType',
            headerProps: {
                children: l('Forecasts.productType'),
            },
            mapCellProps: 'productType',
            componentName: 'EnumValue',
            allowedValuesEnum: ProductType,
        },
        {
            id: 'toBeProcessed',
            headerProps: {
                children: l('Monitoring.toBeProcessed'),
            },
            mapCellProps: 'toBeProcessed',
        },
        {
            id: 'inProcess',
            headerProps: {
                children: l('Monitoring.inProcess'),
            },
            mapCellProps: 'inProcess',
        },
        {
            id: 'preconditioned',
            headerProps: {
                children: l('Monitoring.preconditioned'),
            },
            mapCellProps: 'preconditioned',
        },
        {
            id: 'minPreconditioned',
            headerProps: {
                children: l('Monitoring.minPreconditioned'),
            },
            mapCellProps: 'minPreconditioned',
        },
        {
            id: 'availabilityStatus',
            headerProps: {
                children: l('Monitoring.availabilityStatus'),
            },
            mapCellProps: 'availabilityStatus',
            componentName: 'AvailabilityStatus',
            allowedValuesEnum: AvailabilityStatus,
        },
    ]
}

export default getColumns

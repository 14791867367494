import React, {
    useMemo,
} from 'react'

import Card from 'app/shared-components/Card'
import {
    useArchivedTableControl,
} from 'app/shared-components/TableControls/ShowArchived'
import CollapsibleTable from 'app/shared-components/CollapsibleTable'
import {
    useLocation,
} from 'react-router-dom'
import useStyles from './AdditionalFees.style'
import AdditionalFeesRequests from './AdditionalFees.requests'
import ContractBasisContextWrapper from '../ContractBasisContextWrapper/ContractBasisContextWrapper'
import columns from './additionalFees.columns'
import useGetAdditionalFeesByContractBasisId from './hooks/useGetAdditionalFees'
import AdditionalFeesCopy from './AdditionalFeesCopy'
import AdditionalFeesCreate from './AdditionalFeesCreate'
import AdditionalFeesUpdate from './AdditionalFeesUpdate'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id: number,
}

const AdditionalFees = ({
    id,
}: Props) => {
    const {
        ContractBasis: ContractBasisRoutes,
    } = usePricingRoutes()
    const {
        classes,
    } = useStyles()

    const {
        pathname,
    } = useLocation()

    const showArchivedFromUrl = pathname.includes('additionalfee-archived')
    const showArchived = useArchivedTableControl(AdditionalFeesRequests.domainName)

    const requestsHandlers = useGetAdditionalFeesByContractBasisId({
        id,
        showArchived: showArchived || showArchivedFromUrl,
    })

    const actionComponents = useMemo(() => {
        return {
            Copy: AdditionalFeesCopy,
            Update: AdditionalFeesUpdate,
            Create: AdditionalFeesCreate,
        }
    }, [])

    return (
        <ContractBasisContextWrapper id={id}>
            <div
                data-testid="additionalFees"
                className={classes.root}
            >
                <Card
                    fullHeight
                    contentClass={classes.cardContent}
                    data-testid="additionalFees-card"
                >
                    <CollapsibleTable
                        title="Additional Fees"
                        domainName={AdditionalFeesRequests.domainName}
                        requestHandlers={requestsHandlers}
                        actionComponents={actionComponents}
                        columns={columns}
                        radioSelect
                        uniqField="additionalFeesNumber"
                        routeParams={ContractBasisRoutes.AdditionalFees.params.map(({
                            name,
                        }) => { return name })}
                    />
                </Card>
            </div>
        </ContractBasisContextWrapper>
    )
}

export default AdditionalFees

import React, {
    useCallback, useRef,
} from 'react'
import {
    FormControl,
} from '@mui/material'

import useEnumValues from 'app/hooks/useEnumValues'
import Checkbox from 'app/shared-components/Checkbox'
import Label from 'app/shared-components/Label'
import EnumSelector from 'app/shared-components/EnumSelector'
import useLabel from 'app/hooks/useLabel'

import useStyles from './OptionPanel.style'
import {
    AllowedProductTypes, ForecastAvailabilityOptions,
} from '../../../serviceCenters.types'

const OptionPanel = ({
    value,
    onChange,
    allowedValues,
}: {
    value: ForecastAvailabilityOptions,
    onChange: (value: ForecastAvailabilityOptions) => void,
    allowedValues: Record<string, string>,
}) => {
    const {
        classes,
    } = useStyles()

    const l = useLabel()

    const listStatuses = useRef(Object.keys(value.containerStatus))

    const onChangeContainerStatus = useCallback((newVal: boolean, {
        target: {
            name,
        },
    }) => {
        onChange({
            ...value,
            containerStatus: {
                ...value.containerStatus,
                [name]: newVal,
            },
        })
    }, [
        onChange,
        value,
    ])

    const onChangeProductType = useCallback((newVal: AllowedProductTypes) => {
        onChange({
            ...value,
            productType: newVal,
        })
    }, [
        onChange,
        value,
    ])

    const allowedProductTypes = useEnumValues()(allowedValues)

    return (
        <div className={classes.panel}>
            <FormControl className={classes.containerStatus}>
                <Label title={l('Container.containerStatus')} />
                <div className={classes.wrapperCheckbox}>
                    {
                        listStatuses.current.map((item) => {
                            return (
                                <Checkbox
                                    key={item}
                                    name={item}
                                    title={l(`Monitoring.${item}`)}
                                    value={value.containerStatus[item]}
                                    onChange={onChangeContainerStatus}
                                    className={classes.checkbox}
                                />
                            )
                        })
                    }
                </div>
            </FormControl>
            <EnumSelector
                title={l('Forecasts.productType')}
                className={classes.productType}
                value={value.productType}
                allowedValues={allowedProductTypes}
                onChange={onChangeProductType}
                name="productType"
                useDropdown
                noSort
            />
        </div>
    )
}

export default OptionPanel

import React, {
    useMemo,
} from 'react'

import {
    Navigate,
} from 'react-router-dom'

import {
    CommonRoutes,
    SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'

import ShortTermForecasts from './ShortTermForecasts'
import useForecastRoutes from './hooks/useForecastRoutes'
import {
    ForecastDomains,
} from './Forecast.types'

const Forecast = () => {
    const Routes = useForecastRoutes()

    const routesConfig: SkyNetRouteConfigObject<ForecastDomains> = useMemo(() => {
        return {
            [Routes.ShortTermForecast.route]: <ShortTermForecasts />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.ShortTermForecast.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}

export default Forecast

import queryString from 'query-string'

import simpleApi from 'app/services/simpleApi'
import {
    RequestConfig,
} from 'app/types/request.types'

import {
    ModificationType,
} from '../../orders.types'
import {
    LocationSelectionDataType,
    PositioningSetupType,
} from './orderConfirmation.types'

type OrderConfirmationRequest = {
    Get: {
        requestFn: ({
            orderNumber,
        }: {orderNumber: string}) => RequestConfig,
    },
    Update: {
        mutationFn: ({
            modificationType,
            orderNumber,
            data,
            token,
        }: {
            modificationType: ModificationType,
            orderNumber: string,
            data: PositioningSetupType,
            token: string,
        }) => Promise<any>,
    },
    UpdateOriginServiceCenter: {
        mutationFn: ({
            version,
            orderNumber,
            data,
            token,
        }: {
            version: number,
            orderNumber: string,
            data: LocationSelectionDataType,
            token: string,
        }) => Promise<any>,
    },
    GetContainerAvailability: {
        requestFn: (params) => RequestConfig,
    }
}

const request: OrderConfirmationRequest = {
    Get: {
        requestFn: ({
            orderNumber,
        }) => {
            return {
                url: `order/${orderNumber}/positioning-setup`,
                method: 'GET',
            }
        },
    },
    Update: {
        mutationFn: ({
            modificationType,
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: queryString.stringifyUrl({
                    url: `order/${orderNumber}/positioning-setup`,
                    query: {
                        modificationType,
                    },
                }),
                method: 'PATCH',
                data,
                token,
            })
        },
    },
    UpdateOriginServiceCenter: {
        mutationFn: ({
            version,
            orderNumber,
            token,
            data,
        }) => {
            return simpleApi({
                url: `order/${orderNumber}/originservicecenter`,
                method: 'PATCH',
                data: {
                    ...data,
                    version,
                },
                token,
            })
        },
    },
    GetContainerAvailability: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'servicecenter/monitoring/container-availability/required-containers',
                method: 'POST',
                data: params,
            }
        },
    },
}

export default request

import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import CompanyForm from '../CompanyForm/CompanyForm'
import useCreateCompany from '../hooks/useCreateCompany'

const CompanyCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const createCompany = useCreateCompany({
        onSuccess,
    })

    return (
        <CompanyForm
            data-testid="create-company"
            action={createCompany}
        />
    )
}

export default CompanyCreate

import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    ContractBasis,
} from 'app/Apps/Pricing/Pricing.types'
import {
    AddressCategory,
    Currency,
    LocationType,
} from 'app/types/enums'

export type ContractBasisFields = keyof ContractBasis

const contractBasisFields = (
    hasNDA: boolean, getAllowedValues, exists,
): DTOCardFormField<ContractBasisFields>[] => {
    const account: NestedField<ContractBasisFields> = {
        name: 'account',
        componentName: 'AccountSelector',
        labelKey: 'Prebooking.pricingAccount',
        required: true,
    }

    const generalInformationFields: NestedField<ContractBasisFields>[] = [
        {
            name: 'contractBasisNumber',
            componentName: 'InputSingleline',
            disabled: true,
            labelKey: 'ContractBasis.contractBasisId',
        },
        {
            name: 'customerQuotation',
            componentName: 'LocationObjectSelector',
            filterLocationType: LocationType.ADDRESS,
            required: true,
            labelKey: 'ContractBasisUpdateDto.customerQuotation',
            filterAddressCategory: [
                AddressCategory.CUSTOMER,
                AddressCategory.CUSTOMER_QUOTATION,
            ],
        },
        {
            name: 'currency',
            componentName: 'EnumSelector',
            allowedValues: getAllowedValues(Currency),
            useDropdown: true,
            labelKey: 'ContractWithCurrencyCreateDto.currency',
            disabled: exists,
            required: true,
        },
        {
            name: 'customerQuotationContacts',
            componentName: 'MultipleContactObjectSelector',
            labelKey: 'ContractBasisUpdateDto.customerQuotationContacts',
        },
        {
            name: 'hasNDA',
            componentName: 'Checkbox',
            labelKey: 'ContractBasisUpdateDto.hasNDA',
        },
    ]

    return [
        {
            title: 'General Information',
            className: 'generalInformation',
            fields: [
                {
                    id: 'column1',
                    fields: !hasNDA
                        ? [
                            ...generalInformationFields,
                            account,
                        ]
                        : generalInformationFields,
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'keyAccountManager',
                            componentName: 'ContactObjectSelector',
                            labelKey: 'ContractBasisUpdateDto.keyAccountManager',
                        },
                        {
                            name: 'businessDevelopers',
                            componentName: 'MultipleBusinessDevelopersSelect',
                            labelKey: 'ContractBasisUpdateDto.businessDevelopers',
                        },
                        {
                            name: 'plannedPaymentTerms',
                            componentName: 'InputSingleline',
                            labelKey: 'ContractBasisUpdateDto.plannedPaymentTerms',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Information',
            className: 'information',
            fields: [
                {
                    id: 'column1',
                    fields: [{
                        name: 'supportingDocuments',
                        componentName: 'AttachmentsObjectSelector',
                        labelKey: 'ContractBasisUpdateDto.supportingDocuments',
                    }],
                },
                {
                    id: 'column2',
                    fields: [{
                        name: 'comment',
                        componentName: 'LongText',
                        labelKey: 'AdditionalFees.comment',
                    }],
                },
            ],
        },
    ]
}

export default contractBasisFields

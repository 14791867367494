import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderSubType,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    PoolingFields,
} from '../pooling.types'

export default (): SkyNetTableConfig<PoolingFields> => {
    return {
        name: 'Pooling.PickupAddPickup',
        tabs: [{
            name: 'Pickup: Add Pickup',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'orderNumber',
                    width: '100px',
                },
                {
                    name: 'from',
                    filterField: 'from',
                    filterType: FilterType.Array,
                    width: '80px',
                },
                {
                    name: 'to',
                    filterField: 'to',
                    filterType: FilterType.Array,
                    width: '80px',
                },
                {
                    name: 'orderSubType',
                    filterField: 'orderSubType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    width: '150px',
                    allowedValuesEnum: OrderSubType,
                },
                {
                    name: 'forwarderLocationName',
                    filterField: 'forwarderLocationName',
                    filterType: FilterType.Array,
                    width: '200px',
                },
                {
                    name: 'originLocationLocationName',
                    filterField: 'originLocationLocationName',
                    filterType: FilterType.Array,
                    width: '200px',
                },
                {
                    name: 'deliveryLocationLocationName',
                    labelKey: 'OrderStep.deliveryLocation',
                    width: '200px',
                    sorting: false,
                },
                {
                    name: 'destinationLocationLocationName',
                    filterField: 'destinationLocationLocationName',
                    filterType: FilterType.Array,
                    width: '250px',
                },
                {
                    name: 'mainMeansOfTransport',
                    componentName: 'TransportMode',
                    headerProps: {
                        children: 'Mode',
                    },
                    filterField: 'mainMeansOfTransport',
                    filterType: FilterType.Array,
                    width: '150px',
                },
                {
                    name: 'requiredContainers',
                    componentName: 'List',
                    width: '200px',
                    sorting: false,
                },
                {
                    name: 'estimatedPickupFirstStep',
                    componentName: 'PoolingEstimatedPickup',
                    labelKey: 'DailyOpsView.estimatedPickupRoad',
                    sorting: false,
                },
            ],
        }],
    }
}

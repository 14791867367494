import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    OrdersToBeConfirmedType,
} from './openBookings.types'
import {
    OrderStatusTransitionTo,
} from '../Orders/orders.types'

export default (): SkyNetTableConfig<keyof OrdersToBeConfirmedType> => {
    return {
        name: 'OrdersToBeConfirmed',
        tabs: [{
            name: 'Orders To Be Confirmed',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'orderNumber',
                    labelKey: 'OrderBaseTabView.orderNumber',
                    sorting: false,
                },
                {
                    name: 'originLocationLocationName',
                    labelKey: 'OrderBaseTabView.originLocationLocationName',
                },
                {
                    name: 'from',
                    labelKey: 'OrderBaseTabView.from',
                    filterField: 'from',
                    filterType: FilterType.Array,
                },
                {
                    name: 'to',
                    labelKey: 'OrderBaseTabView.to',
                    filterField: 'to',
                    filterType: FilterType.Array,
                },
                {
                    name: 'orderStatus',
                    labelKey: 'OrderBaseTabView.orderStatus',
                    componentName: 'EnumValue',
                    allowedValuesEnum: OrderStatusTransitionTo,
                    sorting: false,
                },
                {
                    name: 'customerLocationName',
                    labelKey: 'OrderInvoicesView.customerLocationName',
                    filterField: 'customerLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'pricingAccountCompanyName',
                    labelKey: 'Prebooking.pricingAccount',
                    filterField: 'pricingAccountCompanyName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'forwarderPositioningLocationName',
                    labelKey: 'OrderBaseTabView.forwarderPositioningLocationName',
                    filterField: 'forwarderPositioningLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'handoverPointLocationName',
                    labelKey: 'OrderBaseTabView.handoverPointLocationName',
                    filterField: 'handoverPointLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'requestedHandover',
                    componentName: 'WorkingDaysLeftAlert',
                    alertDays: 3,
                    labelKey: 'OrderBaseTabView.requestedHandover',
                    filterField: 'requestedHandover',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'requiredContainers',
                    labelKey: 'OrderByNumberView.requiredContainers',
                    componentName: 'List',
                    filterField: 'requiredContainersProductType',
                    filterType: FilterType.Array,
                    sorting: false,
                },
                {
                    name: 'mawbNumber',
                    labelKey: 'OrderView.mawbNumber',
                    filterField: 'mawbNumber',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}

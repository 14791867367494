import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import requestConfig from '../engagement.request'
import EngagementForm from '../EngagementForm'

const EngagementCreateForm = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <EngagementForm
            onSuccess={onSuccess}
            requestParam={requestConfig.create.request()}
        />
    )
}

export default EngagementCreateForm

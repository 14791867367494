import React from 'react'

import useHasPermission from 'app/hooks/useHasPermission'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import {
    useLocation, useSearchParams,
} from 'react-router-dom'
import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'
import LocationForm from '../LocationForm'
import {
    defaultProps,
} from '../LocationForm/LocationForm'

import {
    useCreateLocation,
} from '../hooks'
import {
    ROLES_NOPROSPECT_AND_PROSPECT_ALLOWED,
    ROLES_PROSPECT_ALLOWED,
} from '../Locations.types'

const LocationCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        search,
        state,
    } = useLocation()

    const createLocation = useCreateLocation({
        onSuccess: onSuccess || state?.onSuccess,
    })

    const [searchParams] = useSearchParams(search)

    const canCreateProspect = useHasPermission(ROLES_PROSPECT_ALLOWED)
    const canCreateNonProspectAndProspect = useHasPermission(ROLES_NOPROSPECT_AND_PROSPECT_ALLOWED)
    const canCreateOnlyProspectPermissions = canCreateProspect && !canCreateNonProspectAndProspect
    const companyId = searchParams?.get('companyId')
    const defaultData = {
        company: companyId ? {
            id: companyId,
        } as unknown as Company : undefined,
        ...defaultProps.data,
        isProspect: canCreateOnlyProspectPermissions,
    }

    return (
        <LocationForm
            action={createLocation}
            data={defaultData}
        />
    )
}

export default LocationCreate

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import deliveryViaRoadFields from './deliveryViaRoad.fields'

import {
    DailyOpsFields,
} from '../dailyOps.types'

export default (): SkyNetTableConfig<DailyOpsFields> => {
    return {
        name: 'DeliveryViaRoadPickUp',
        tabs: [{
            name: 'Delivery: Add Pick-up',
            url: CommonRoutes.ALL,
            fields: deliveryViaRoadFields,
        }],

    }
}

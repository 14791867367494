import {
    LeaseType, ProductType,
} from 'app/types/enums'
import {
    RequiredContainersCustomer,
} from 'app/types/common.types'
import {
    AllowedProductTypes,
} from 'app/Apps/ContainerManagement/ServiceCenters/serviceCenters.types'

import {
    DeliveryServiceType, TransportModeRoad,
} from '../../orders.types'

export enum PositioningSelection {
    DEFAULT = 'DEFAULT',
    BACKUP = 'BACKUP',
    OTHER = 'OTHER'
}

export enum AvailabilityStatus {
    READY_TO_CONFIRM = 'READY_TO_CONFIRM',
    CAN_BE_CONFIRMED = 'CAN_BE_CONFIRMED',
    NOT_ENOUGH = 'NOT_ENOUGH',
    NO_MODEL_AVAILABLE = 'NO_MODEL_AVAILABLE',
    NOT_SUPPORTED_IN_SC = 'NOT_SUPPORTED_IN_SC',
}

export type PositioningSetupType = {
    version: number,
    mawbNumber: string,
    hawbNumber: string,
    transportModeForwarder: TransportModeRoad,
    transportModeRoad: TransportModeRoad,
    freightForwarder: {
        id: number,
    },
    numberOfTrucks: number,
    numberOfVans: number,
    leaseType: LeaseType,
    deliveryServiceType: DeliveryServiceType,
    orderReceived: string,
    originSc: {id: number},
    originScContacts: {id: number}[],
    originScBackup: {id: number},
    originScBackupContacts: {id: number}[],
    originScOther: {id: number},
    originScOtherContacts: {id: number}[],
    forwarderPositioning: {id: number},
    forwarderPositioningContacts: {id: number}[],
    forwarderPos: {id: number},
    forwarderPosContacts: {id: number}[],
    forwarderPosBackup: {id: number},
    forwarderPosBackupContacts: {id: number}[],
    forwarderPosOther: {id: number},
    forwarderPosOtherContacts: {id: number}[],
    customerReference: string,
    personInCharge: string,
    originServiceCenterSelection: PositioningSelection,
    forwarderPositioningSelection: PositioningSelection,
    estimatedPickup: string,
    laneId: number,
    additionalCommentFreightForwarder?: string,
}

export type LocationSelectionDataType = {
    originServiceCenter: {id: number},
    originServiceCenterContacts: {id: number}[],
    originServiceCenterSelection: PositioningSelection,
}

export type AvailabilityRequestData = {
    serviceCenter: { id: number },
    containersRequestedHandoverDate: string,
    requiredContainers: RequiredContainersCustomer[],
    excludeOrderNumber: string,
}

export type RequiredContainersAvailabilityType = {
    productType: ProductType,
    toBeProcessed: number,
    inProcess: number,
    preconditioned: number,
    minPreconditioned: number,
    availabilityStatus: AvailabilityStatus,
}

export type OrderConfirmationAvailability = {
    status: AvailabilityStatus,
    requiredContainersAvailability: RequiredContainersAvailabilityType[],
    forecasts: {
        productType: AllowedProductTypes,
        containerAvailabilityForecastForDates: {
            date: string,
            toBeProcessed: number,
            inProcess: number,
            preconditioned: number,
        }[],
        minPreconditioned: number,
    }[],
}

import React, {
    useCallback, useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import SkyNetDomain from 'app/shared-components/SkyNetDomain'
import useStyles from './KamDashboard.style'
import getKamUpcomingConfig from './configs/kamUpcoming.config'
import getKamToBeCompletedConfig from './configs/kamToBeCompleted.config'
import getKamDashboardConfig from './configs/kamCompleted.config'
import getPreBookingsConfig from './configs/preBookings.config'
import dashboardRequest from './KamDashboard.requests'
import ToBeAssignedLanes from './ToBeAssignedLanes'
import useAccountManagementRoutes from '../hooks/useAccountManagementRoutes'
import useOrderManagementRoutes from '../../OrderManagement/hooks/useOrderManagementRoutes'

const DOMAIN_NAME = 'KamDashboard'
const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const KamDashboard = () => {
    const {
        classes,
    } = useStyles()
    const {
        Quotes: QuotesRoutes,
    } = useAccountManagementRoutes()

    const {
        PreBookings: PreBookingsRoutes,
    } = useOrderManagementRoutes()

    const onRowClick = useCallback(({
        id,
    }: {id: number}) => {
        QuotesRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }, [QuotesRoutes.Overview])

    const onRowClickPreBookings = ({
        id,
    }: {id: number}): void => {
        PreBookingsRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }

    const tableConfigs = useMemo(() => {
        return {
            kamUpcomingConfig: getKamUpcomingConfig(),
            kamToBeCompletedConfig: getKamToBeCompletedConfig(),
            kamDashboardConfig: getKamDashboardConfig(),
            preBookingsConfig: getPreBookingsConfig(),
        }
    }, [])

    return (
        <SkyNetDomain
            name={DOMAIN_NAME}
            editable={false}
            uniqField={dashboardRequest.uniqField}
        >
            <div className={classes.root}>
                <div className={classes.headerTitle}>
                    Dashboard
                    <div className={classes.actionWrapper} />
                </div>
                <div className={classes.tableWrapper}>
                    <SkyNetTable
                        onRowClick={onRowClick}
                        customUrl={dashboardRequest.url.upcoming}
                        tableConfig={tableConfigs.kamUpcomingConfig}
                        tableControls={tableControls}
                        isShowCounterTab
                        showSearch
                        paginationModeMatches
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <SkyNetTable
                        onRowClick={onRowClick}
                        customUrl={dashboardRequest.url.toBeCompleted}
                        tableConfig={tableConfigs.kamToBeCompletedConfig}
                        tableControls={tableControls}
                        isShowCounterTab
                        showSearch
                        paginationModeMatches
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <SkyNetTable
                        onRowClick={onRowClick}
                        customUrl={dashboardRequest.url.completed}
                        tableConfig={tableConfigs.kamDashboardConfig}
                        tableControls={tableControls}
                        isShowCounterTab
                        showSearch
                        paginationModeMatches
                    />
                </div>
                <div className={classes.headerTitle}>Unassigned Lanes</div>
                <div className={classes.tableWrapper}>
                    <ToBeAssignedLanes />
                </div>
                <div className={classes.headerTitle}>
                    Pre Bookings
                    <div className={classes.actionWrapper} />
                </div>
                <div className={classes.tableWrapper}>
                    <SkyNetTable
                        onRowClick={onRowClickPreBookings}
                        customUrl={dashboardRequest.url.preBookings}
                        tableConfig={tableConfigs.preBookingsConfig}
                        tableControls={tableControls}
                        isShowCounterTab
                        showSearch
                        paginationModeMatches
                        data-testid="preBookingsTransports"
                    />
                </div>
            </div>
        </SkyNetDomain>
    )
}

export default KamDashboard

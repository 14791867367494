import React from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import noop from 'lodash/noop'

import useHasPermission from 'app/hooks/useHasPermission'
import {
    ArchiveButton, useArchive, ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import {
    QuoteStatus,
} from '../AccountQuotes.types'

import useGetAccountQuote from '../hooks/useGetAccountQuote'
import QuoteInformationForm from '../QuoteInformationForm'
import RequestedOpportunities from './RequestedOpportunities'

import request from './accountQuote.requests'

import useStyles from './AccountQuoteOverview.styles'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const AccountQuoteOverview = ({
    id,
}: {
    id: number
}) => {
    const {
        Quotes: QuotesRoutes,
    } = useAccountManagementRoutes()
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetAccountQuote(id)

    const {
        classes,
    } = useStyles()
    const updateAllPermission = useHasPermission(['account_update_all'])

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'QuoteUpdateDto',
        title: 'Quote',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: QuotesRoutes.path,
        }),
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <QuoteInformationForm
                    value={data}
                    setValue={noop}
                    showContractBasisLink
                    disabled
                    omitField={[
                        QuoteStatus.CANCELLED,
                        QuoteStatus.CREATED,
                    ].includes(data?.quoteStatus) ? [
                            'customerQuotation',
                            'pricingResponsible',
                        ] : undefined}
                    render={({
                        fields, formData,
                    }) => {
                        return (
                            <DomainObjectForm
                                name="AccountQuoteInformation"
                                value={formData}
                                fields={fields}
                                disabled
                            />
                        )
                    }}
                />
                { Boolean(data?.account?.id) && (
                    <RequestedOpportunities
                        data={data}
                    />
                ) }
                {updateAllPermission && data?.quoteStatus === QuoteStatus.CANCELLED && (
                    <>
                        <div className={classes.archiveButton}>
                            <ArchiveButton
                                onClick={archive.handleArchiveRestore}
                                archived={data?.archived}
                            />
                        </div>
                        <ArchiveConfirmationDialog
                            title="Quote"
                            openConfirmDialog={archive.openConfirmDialog}
                            handleClose={archive.handleArchiveRestoreConfirm}
                            archived={data?.archived}
                        />
                    </>
                )}
            </>
        </StatusHandler>
    )
}

export default AccountQuoteOverview

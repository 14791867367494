import React, {
    useCallback, useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import Button from 'app/shared-components/Button'
import useHasPermission from 'app/hooks/useHasPermission'

import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'
import {
    useCollapsibleTableContext,
} from 'app/shared-components/CollapsibleTable'
import LaneForm from '../LaneForm'
import LaneRequests from '../Lane.requests'

import useGetLane from '../hooks/useGetLane'
import useLaneGroupContext from '../../hooks/useLaneGroupContext'

const LaneUpdate = ({
    id,
    refetch,
}: { id: number, refetch: (...args: any[]) => void }) => {
    const {
        laneGroupId,
    } = useLaneGroupContext()

    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLane(id)

    const {
        updatePathWithParams,
        updatePathWithId,
    } = useCollapsibleTableContext()

    const createPermission = useHasPermission(['lane_create'])

    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        refetch,
        invalidate,
    ])

    const archive = useArchive({
        requestParamFn: data?.archived ? LaneRequests.restore
            : LaneRequests.archive,
        notificationId: 'LaneUpdateDto',
        title: 'Lane',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: LaneGroupsRoutes.Lanes.path,
            params: {
                key: laneGroupId,
            },
        }),
        onSuccess,
    })

    const customButtons = useMemo(() => {
        return createPermission ? [
            <Button
                label="Copy"
                key="copy"
                onClick={() => {
                    updatePathWithParams({
                        action: 'copy',
                        id: data?.id,
                    })
                }}
            />,
            <ArchiveButton
                onClick={archive.handleArchiveRestore}
                archived={data?.archived}
            />,
        ] : [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        updatePathWithParams,
        data,
        archive.handleArchiveRestore,
        createPermission,
    ])

    const onCancel = useCallback(() => {
        invalidate()
        updatePathWithId({
            id: undefined,
        })
    }, [
        invalidate,
        updatePathWithId,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <LaneForm
                    data={data}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={LaneRequests.update.request({
                        laneId: id,
                    })}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Lane"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>

        </StatusHandler>
    )
}

export default LaneUpdate

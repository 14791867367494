import React, {
    useCallback, useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import Label from 'app/shared-components/Label'
import EnumSelector from 'app/shared-components/EnumSelector'
import InputSingleline from 'app/shared-components/InputSingleline'
import useStyles from './CommentedReasonConfirmationDialog.styles'

type Props = {
    open: boolean,
    handleClose: (positive: boolean, data: {
        reason: string,
        reasonComment: string,
    }) => void,
    allReasons: Record<string, string>,

    initialReason?: string,
    title: {
        headerTitle: string,
        reasonTitle: string,
        commentTitle: string,
    }
}

const CommentedReasonConfirmationDialog = ({
    open,
    handleClose,
    allReasons,
    title: {
        headerTitle,
        reasonTitle,
        commentTitle,
    },
    initialReason,
}: Readonly<Props>) => {
    const {
        classes,
    } = useStyles()
    const [
        reason,
        setReason,
    ] = useState(initialReason || null)

    const [
        reasonComment,
        setReasonComment,
    ] = useState(null)

    const handleCloseLocal = useCallback((positive) => {
        handleClose(!!positive, {
            reason,
            reasonComment,
        })
    }, [
        handleClose,
        reason,
        reasonComment,
    ])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleCloseLocal}
            dialogTitle={headerTitle}
            positiveLabel="Save"
            positiveLabelDisabled={!reason || !reasonComment}
            negativeLabel="Cancel"
        >
            <div className={classes.container}>
                <div className={classes.child}>
                    <Label
                        title={reasonTitle}
                    />
                    <EnumSelector
                        required
                        value={reason}
                        allowedValues={allReasons}
                        onChange={setReason}
                        name="reason"
                        useDropdown
                    />
                </div>
                <div className={classes.child}>
                    <InputSingleline
                        multiline
                        required
                        rows={4}
                        title={commentTitle}
                        value={reasonComment}
                        onChange={setReasonComment}
                    />
                </div>
            </div>
        </ConfirmationDialog>
    )
}

export default CommentedReasonConfirmationDialog

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    TemperatureRange,
    OpportunityStatus,
    RegionFirstLevel,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    OpportunityTableType,
} from './opportunities.types'

const config = (): SkyNetTableConfig<keyof OpportunityTableType> => {
    return {
        name: 'Opportunity',
        tabs: [{
            name: 'All Opportunities',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'opportunityNumber',
                    labelKey: 'Opportunities.opportunityNumber',
                    reduced: true,
                },
                {
                    name: 'companyName',
                    labelKey: 'AccountQuotes.accountName',
                    filterField: 'companyName',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'temperatureRange',
                    labelKey: 'Opportunities.temperatureRange',
                    filterField: 'temperatureRange',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: TemperatureRange,
                },
                {
                    name: 'originRegion',
                    labelKey: 'Opportunities.originRegion',
                    componentName: 'EnumValue',
                    allowedValuesEnum: RegionFirstLevel,
                    filterField: 'originRegion',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'destinationRegion',
                    labelKey: 'Opportunities.destinationRegion',
                    componentName: 'EnumValue',
                    allowedValuesEnum: RegionFirstLevel,
                    filterField: 'destinationRegion',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'reprOriginAirport',
                    labelKey: 'Opportunity.reprOriginAirport',
                    filterField: 'reprOriginAirport',
                    filterType: FilterType.Array,
                },
                {
                    name: 'reprDestinationAirport',
                    labelKey: 'Opportunity.reprDestinationAirport',
                    filterField: 'reprDestinationAirport',
                    filterType: FilterType.Array,
                },
                {
                    id: 'opportunityStatus',
                    headerProps: {
                        children: 'Status',
                    },
                    mapCellProps: 'opportunityStatus',
                    componentName: 'EnumValue',
                    allowedValuesEnum: OpportunityStatus,
                    filterField: 'opportunityStatus',
                    filterType: FilterType.Array,
                },
                {
                    name: 'collaborators',
                    labelKey: 'Opportunity.opportunityCollaborators',
                    componentName: 'List',
                    filterField: 'collaborators',
                    filterType: FilterType.Array,
                },
            ],
        }],
    }
}

export default config

import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import DeploymentRequests from '../Deployments.request'
import DeploymentForm from '../DeploymentForm'

const DeploymentCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <DeploymentForm
            onSuccess={onSuccess}
            requestParam={DeploymentRequests.create.request()}
        />
    )
}

export default DeploymentCreate

import {
    Fee,
} from 'app/Apps/Pricing/Pricing.types'
import {
    PricingStatus,
} from 'app/types/enums'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

export enum PickupFeeType {
    CHARGE = 'CHARGE',
    FREE = 'FREE',
    NOT_AVAILABLE = 'NOT_AVAILABLE'
}

export type DeliveryCollectionFees = {
    id?: number,
    location: Location,
    temperatureRange: string,
    deliveryFeeLTL: string,
    deliveryFeeFTL: string,
    pickupFeeType: PickupFeeType,
    pickupFee: number,
    dropoffCollectionFee: number,
    expressDays: number,
    postponementDays: number,
}

export type AlLogisticPricing = {
    id: number,
    airline: Location,
    airlineName: string,
    currency: string,
    billingCompany?: Location,
    status: PricingStatus,
    validFrom: string,
    validTo: string,
    changedBy: string,
    changedOn: string,
    comment: string,
    deliveryCollectionFees: DeliveryCollectionFees[],
    otherFees?: Fee[],
}

export type AlLogisticPricingFields = keyof AlLogisticPricing

import React from 'react'
import useEnumValues from 'app/hooks/useEnumValues'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import requests from '../Damages.requests'
import getDamageCreateFields from './DamageCreate.fields'

const DamageCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const getAllowedValue = useEnumValues()

    return (
        <FormWithControls
            onSuccess={onSuccess}
            name={requests.domainName}
            fields={getDamageCreateFields(getAllowedValue)}
            requestParams={requests.create()}
        />
    )
}

export default DamageCreate

import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex',
            height: '100%',
            width: '100%',
            '@media print': {
                display: 'none',
            },
        },
        rootResponsive: {
            width: 'auto',
            maxWidth: '30%',
            minWidth: '20%',
            '@media print': {
                display: 'none',
            },
        },
        tableContentWrapper: {
            padding: 0,
            borderRadius: 0,
            width: '100%',
            height: 'min-content',
            minHeight: 300,
            boxSizing: 'border-box',
            border: 'none',
            position: 'absolute',
        },
        contentWrapper: {
            position: 'relative',
        },
        tableHeaderWrapper: {
            position: 'relative',
            '&&': {
                position: 'relative',
            },
        },
        tableContent: {
            boxShadow: 'none !important',
            '& .MuiCheckbox-root': {
                marginLeft: theme.spacing(2),
            },
        },
        wrapper: {
            whiteSpace: 'nowrap',
        },
    }
})

export default useStyles

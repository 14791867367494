import React, {
    useMemo,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import getTableConfig from './AirportsAndSeaports.config'

import requests from '../Locations/Locations.request'
import LocationOverview from '../Locations/LocationOverview'
import LocationUpdate from '../Locations/LocationUpdate'
import LocationAreas from '../Locations/LocationAreas'
import LocationChangeSummary from '../Locations/LocationChangeSummary/LocationChangeSummary'
import {
    LocationsTabs,
} from '../Locations/Locations.types'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

export default function AirportsAndSeaports() {
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: CommonRoutes.OVERVIEW,
                Component: LocationOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: CommonRoutes.DATA_UPDATE,
                Component: LocationUpdate,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Areas',
                url: LocationsTabs.Areas,
                Component: LocationAreas,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Change History',
                url: CommonRoutes.CHANGE_HISTORY,
                Component: LocationChangeSummary,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            defaultTab="overview"
            sidebarTabs={tabs}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName={requests.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

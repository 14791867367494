import React from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'
import AlertDialog from 'app/shared-components/AlertDialog'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RejectedReasons,
} from 'app/shared-components/LaneFees/LaneFeesForm/reasons.types'

import useStyles from '../ReviewLaneFeesReturnReason/ReviewLaneFeesReturnReason.style'

type Props = {
    openConfirmDialog?: boolean,
    handleClose: (confirm: boolean) => void
    data?: {
        rejectCreatedBy?: string,
        rejectCreatedOn?: string,
        rejectReason?: string,
        rejectReasonComment?: string,
    },
}
const defaultProps: Partial<Props> = {
    openConfirmDialog: true,
    data: {},
}
const ReviewLaneFeesRejectReason = ({
    openConfirmDialog,
    handleClose,
    data,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getEnumValues = useEnumValues()(RejectedReasons)

    return (
        <AlertDialog
            open={openConfirmDialog}
            handleClose={handleClose}
            dialogTitle="Lane Fee Rejected Reason"
        >
            <>
                <div className={classes.container}>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Return Reason"
                            value={getEnumValues[data?.rejectReason]}
                            disabled
                        />
                    </div>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Date"
                            value={data.rejectCreatedOn}
                            disabled
                        />
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Reason Comment"
                            value={data.rejectReasonComment}
                            rows={2}
                            multiline
                            disabled
                        />
                    </div>
                    <div className={classes.child}>
                        <InputSingleline
                            title="Created By"
                            value={data.rejectCreatedBy}
                            disabled
                        />
                    </div>
                </div>
            </>
        </AlertDialog>
    )
}

ReviewLaneFeesRejectReason.defaultProps = defaultProps

export default ReviewLaneFeesRejectReason

import React, {
    useState,
    useCallback, useMemo,
} from 'react'
import {
    Grid, Typography,
} from '@mui/material'

import Button from 'app/shared-components/Button'
import IntegerInput from 'app/shared-components/IntegerInput'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import Card from 'app/shared-components/Card'
import Select from 'app/shared-components/Select'
import useEnumValues from 'app/hooks/useEnumValues'
import StatusHandler from 'app/shared-components/StatusHandler'
import useGetContainerTypes from 'app/Apps/ContainerManagement/ContainerTypes/hooks/useGetContainerTypes'
import usePrintContainerLabels from 'app/Apps/ContainerManagement/Containers/hooks/usePrintContainerLabels'
import {
    ContainerCode,
} from 'app/types/enums'

import {
    ActionComponentPrintProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import useStyles from './PrintLabel.styles'

type PrintLabelFormValues = {
    containerCode: string,
    containerSerialNumberFrom: string,
    containerSerialNumberTo: string,
    includeJypNumber: string,
}

const PrintLabel = ({
    onClose,
}: ActionComponentPrintProps) => {
    const {
        classes,
    } = useStyles()
    const containerCodes = useEnumValues()(ContainerCode)

    const [
        state,
        setState,
    ] = useState<{ values: PrintLabelFormValues, edited: boolean }>({
        values: {} as PrintLabelFormValues,
        edited: false,
    })

    const onChange = useCallback((newValue, {
        target,
    }) => {
        const {
            name,
        } = target
        const updateObject = {
            ...state.values,
            [name]: newValue,
        }

        setState({
            ...state,
            values: updateObject,
            edited: true,
        })
    }, [
        setState,
        state,
    ])

    const {
        containerTypes,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContainerTypes()

    const {
        mutate: printLabelRequest,
    } = usePrintContainerLabels({
        loadMessage: 'Please stand by while we’re preparing the labels.',
    })

    const printLabel = useCallback(() => {
        const newLabel = `labels_${state.values.containerCode}_${state.values.containerSerialNumberFrom}-${state.values.containerSerialNumberTo}`

        printLabelRequest({
            ...state.values,
            barcodeType: 'CONTAINER',
            label: newLabel,
        })
    }, [
        printLabelRequest,
        state.values,
    ])

    const options = useMemo(() => {
        return (containerTypes || []).reduce((acc, {
            containerCode: code,
        }) => {
            return {
                ...acc,
                [code]: containerCodes[code],
            }
        }, {})
    }, [
        containerTypes,
        containerCodes,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <div>
                <Card title="Print Label">
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={4}
                        >
                            <Select
                                title="Container Type"
                                allowedValues={options}
                                value={state.values?.containerCode}
                                name="containerCode"
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                        >
                            <IntegerInput
                                title="Serial Number Counter From"
                                textType="editable"
                                name="containerSerialNumberFrom"
                                onChange={onChange}
                                value={state.values?.containerSerialNumberFrom}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                        >
                            <IntegerInput
                                title="Serial Number Counter To"
                                textType="editable"
                                name="containerSerialNumberTo"
                                onChange={onChange}
                                value={state.values?.containerSerialNumberTo}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.messageContainer}>
                        <Typography className={classes.messageFont}>
                            The counter is the second part of the serial number: 012-
                            <strong>10202</strong>
                            .
                            Please note that the containers need to exist in the system,
                            otherwise they will not be printed.
                        </Typography>
                    </div>
                </Card>
                <ButtonContainer>
                    <Button
                        label="Print"
                        onClick={printLabel}
                        disabled={!state.edited}
                    />
                    <Button
                        label="Cancel"
                        secondary
                        onClick={onClose}
                        disabled={!state.edited}
                    />
                </ButtonContainer>
            </div>
        </StatusHandler>
    )
}

export default PrintLabel

import React from 'react'

import Loading from 'app/shared-components/Loading'
import FailureMessageDefault from 'app/shared-components/withStatusHandler/FailureMessageDefault'

type Props = {
    children: JSX.Element,
    isSuccess?: boolean,
    isFetching?: boolean,
    isError?: boolean,
    error?: any,
    fallback?: JSX.Element,
}

const defaultProps = {
    error: '',
    isError: false,
    isSuccess: false,
    isFetching: false,
    fallback: undefined,
}

const StatusHandler = ({
    children,
    isSuccess,
    isFetching,
    isError,
    error,
    fallback,
}: Props) => {
    if (isError) {
        return (
            <>
                {fallback ?? null}
                <FailureMessageDefault
                    err={error}
                />
            </>
        )
    }

    return (
        <>
            {
                isFetching && (
                    <Loading />
                )
            }
            {
                isSuccess && (
                    <>{children}</>
                )
            }
        </>
    )
}

StatusHandler.defaultProps = defaultProps

export default StatusHandler

import {
    injectFieldsToTabs, SkyNetTableField,
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    AddressCategory,
    LocationType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export const getTabUrl = (name: LocationType) => {
    const tabUrls = {
        [LocationType.ADDRESS]: 'address',
        [LocationType.AIRPORT]: 'airport',
        [LocationType.SEAPORT]: 'seaport',
    }

    return tabUrls[name] || CommonRoutes.ALL
}

const config = (): SkyNetTableConfig => {
    const fields: SkyNetTableField[] = [
        {
            name: 'locationName',
            reduced: true,
        },
        {
            name: 'addressLine1',
            filterType: FilterType.Array,
        },
        {
            name: 'zip',
            filterType: FilterType.Array,
        },
        {
            name: 'city',
            reduced: true,
        },
        {
            name: 'countryName',
            filterField: 'countryName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'country',
        },
        {
            name: 'isMainAddress',
            componentName: 'ChipCheckMark',
        },
        {
            name: 'companyName',
            filterField: 'companyName',
            filterType: FilterType.Array,
            labelKey: 'company',
        },
        {
            name: 'addressCategory',
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
        },
    ]

    return {
        name: 'LocationTable',
        tabs: injectFieldsToTabs([
            {
                name: 'All Locations',
                url: CommonRoutes.ALL,
            },
            {
                name: 'Addresses',
                url: getTabUrl(LocationType.ADDRESS),
                includeFilters: {
                    locationType: [LocationType.ADDRESS],
                },
            },
        ], fields),
    }
}

export default config

import React, {
    useCallback, useMemo, useState,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    useNavigate,
} from 'react-router-dom'

import {
    LaneFeesStatus,
} from 'app/types/enums'
import {
    useChangeValidity,
} from 'app/shared-components/ChangeValidity'
import useRequestWithConfirmation from 'app/hooks/useRequestWithConfirmation'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import Button from 'app/shared-components/Button'
import {
    useCollapsibleTableContext,
} from 'app/shared-components/CollapsibleTable'
import useGetLaneFee from 'app/Apps/Pricing/LaneGroups/LaneFees/hooks/useGetLaneFee'
import LaneFeesRequests from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import useLaneGroupContext from 'app/Apps/Pricing/LaneGroups/hooks/useLaneGroupContext'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'
import RejectLaneFeesConfirmationDialog
    from 'app/Apps/AccountManagement/AccountQuotes/LanesToAwardReject/AwardRejectLaneFees/RejectLaneFeesConfirmationDialog'

import LaneFeesForm from '../LaneFeesForm'
import LaneFeeChangeValidity from './LaneFeeChangeValidity/LaneFeeChangeValidity'
import LaneFeeCancellation from './LaneFeeCancellation'
import ReviewLaneFeesReturnReason from './ReviewLaneFeesReturnReason'
import useChangeLaneFeesStatus from './hooks/useChangeLaneFeesStatus'
import ReviewLaneFeesRejectReason from './ReviewLaneFeesRejectReason'
import ReturnLaneFeesConfirmationDialog from './ReturnLaneFeesConfirmationDialog'

const defaultProps = {
    id: undefined,
    refetch: () => {},
}

const LaneFeesUpdate = ({
    id,
    refetch,
}: { id?: number, refetch?: (...args: any[]) => void }) => {
    const {
        laneGroupId,
    } = useLaneGroupContext()

    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()

    const {
        updatePathWithParams,
        updatePathWithId,
    } = useCollapsibleTableContext()

    const navigate = useNavigate()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLaneFee(id)

    const [
        cancelationReason,
        setCancelationReason,
    ] = useState('')
    const [
        openReviewLaneFeesReturnConfirm,
        setOpenReviewLaneFeesReturnConfirm,
    ] = useState(false)

    const requestParam = useMemo(() => {
        if (data?.laneFeesStatus !== LaneFeesStatus.IN_PROGRESS) {
            return LaneFeesRequests.editableData.request({
                laneFeesId: data?.id,
            })
        }
        return LaneFeesRequests.update.request({
            laneFeesId: data?.id,
        })
    }, [data])

    const archive = useArchive({
        requestParamFn: data?.archived ? LaneFeesRequests.restore
            : LaneFeesRequests.archive,
        notificationId: 'LaneFeeUpdateDto',
        title: 'Lane Fee',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: LaneGroupsRoutes.LaneFees.path,
            params: {
                key: laneGroupId,
            },
        }),
    })

    const onSuccess = useCallback(() => {
        invalidate()
        refetch()
    }, [
        refetch,
        invalidate,
    ])

    const {
        changeStatus,
    } = useChangeLaneFeesStatus({
        notificationId: 'LaneFeeUpdateDto',
        onSuccess,
    })

    const cancelFee = useRequestWithConfirmation({
        requestParamFn: LaneFeesRequests.cancellation,
        notificationId: 'LaneFeesUpdateDto',
        title: 'Lane Fee',
        data: {
            ...data,
            commentForBilling: cancelationReason,
        },
        actionDone: 'Cancelled',
        onSuccess,
    })

    const onChangeValiditySuccess = useCallback(() => {
        onSuccess()
        navigate(`/apps/pricing/lane-groups/all/edit/${laneGroupId}/lane-fees/${data?.id}`)
    }, [
        onSuccess,
        navigate,
        data?.id,
        laneGroupId,
    ])

    const changeValidity = useChangeValidity({
        requestParamFn: LaneFeesRequests.validity,
        notificationId: 'LaneFeesUpdateDto',
        title: 'Lane Fee',
        data: {
            id: data?.id,
            validFrom: data?.validFrom,
            validTo: data?.validTo,
        },
        onSuccess: onChangeValiditySuccess,
    })

    const completedLaneFees = useMemo(() => {
        return [
            LaneFeesStatus.AWARDED,
            LaneFeesStatus.SUBMITTED,
            LaneFeesStatus.CANCELLED,
        ].includes(data?.laneFeesStatus)
    }, [data?.laneFeesStatus])

    const handleToSubmitted = useCallback(() => {
        changeStatus({
            id,
            status: LaneFeesStatus.SUBMITTED,
            title: 'Lane Fee Status changes to Submitted',
        })
    }, [
        changeStatus,
        id,
    ])
    const handleToAwarded = useCallback(() => {
        changeStatus({
            id,
            status: LaneFeesStatus.AWARDED,
            title: 'Lane Fee Status changes to Awarded',
        })
    }, [
        changeStatus,
        id,
    ])

    const [
        toRejectedConfirmation,
        setToRejectedConfirmation,
    ] = useState(false)

    const [
        returnToInProgressConfirmation,
        setReturnToInProgressConfirmation,
    ] = useState(false)

    const buttonsForSubmitted = useMemo(() => {
        return (data?.laneFeesStatus === LaneFeesStatus.SUBMITTED ? [
            <Button
                key="set-in-progress"
                label="Set to in Progress"
                onClick={() => {
                    setReturnToInProgressConfirmation(true)
                }}
            />,
            <Button
                key="set-to-rejected"
                label="Set to Rejected"
                onClick={() => {
                    setToRejectedConfirmation(true)
                }}
            />,
            <Button
                key="set-to-awarded"
                label="Set to Awarded"
                onClick={handleToAwarded}
            />,
        ] : [])
    }, [
        data?.laneFeesStatus,
        handleToAwarded,
    ])

    const buttonForInProgress = useMemo(() => {
        return (data?.laneFeesStatus === LaneFeesStatus.IN_PROGRESS ? [<Button
            key="set-in-submitted"
            label="Set to Submitted"
            onClick={handleToSubmitted}
        />] : [])
    }, [
        data?.laneFeesStatus,
        handleToSubmitted,
    ])

    const buttonForInRejected = useMemo(() => {
        return (data?.laneFeesStatus === LaneFeesStatus.REJECTED ? [<Button
            key="set-to-awarded"
            label="Set to Awarded"
            onClick={handleToAwarded}
        />] : [])
    }, [
        data?.laneFeesStatus,
        handleToAwarded,
    ])

    const customButtons = useMemo(() => {
        return (
            [
                <Button
                    label="Copy"
                    key="copy-button"
                    onClick={() => {
                        updatePathWithParams({
                            action: 'copy',
                            id: data?.id,
                        })
                    }}
                />,
                ...buttonsForSubmitted,
                ...buttonForInProgress,
                ...buttonForInRejected,
                <Button
                    key="set-to-cancel-button"
                    label="Set to Canceled"
                    onClick={cancelFee.askConfirmation}
                    disabled={data?.laneFeesStatus === LaneFeesStatus.CANCELLED}
                />,
                <Button
                    key="change-validity-button"
                    label="Change Validity"
                    onClick={changeValidity.handleChangeValidity}
                    disabled={!completedLaneFees}
                />,
                <ArchiveButton
                    key="archive-button"
                    onClick={archive.handleArchiveRestore}
                    archived={data?.archived}
                />,
            ]
        )
    }, [
        updatePathWithParams,
        archive.handleArchiveRestore,
        cancelFee.askConfirmation,
        changeValidity.handleChangeValidity,
        data,
        completedLaneFees,
        buttonsForSubmitted,
        buttonForInProgress,
        buttonForInRejected,
    ])

    const onCancel = useCallback(() => {
        invalidate()
        updatePathWithId({
            id: undefined,
        })
    }, [
        invalidate,
        updatePathWithId,
    ])

    const toRejectHandler = useCallback((rejectReasonData) => {
        changeStatus({
            id,
            status: LaneFeesStatus.REJECTED,
            data: rejectReasonData,
            title: 'Lane Fee Status changes to Rejected',
        })
    }, [
        changeStatus,
        id,
    ])

    const returnToInProgressHandler = useCallback((reasonData) => {
        changeStatus({
            id,
            status: LaneFeesStatus.IN_PROGRESS,
            data: reasonData,
            title: 'Lane Fee Status changes to In Progress',
        })
    }, [
        changeStatus,
        id,
    ])

    const [
        openReviewLaneFeesRejecteDialog,
        setOpenReviewLaneFeesRejectedDialog,
    ] = useState(false)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <LaneFeesForm
                    data={data}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={requestParam}
                    customButtons={customButtons}
                    setOpenReviewLaneFeesReturnConfirm={setOpenReviewLaneFeesReturnConfirm}
                    setOpenReviewLaneFeesRejectedDialog={setOpenReviewLaneFeesRejectedDialog}
                />
                <LaneFeeChangeValidity
                    id={data?.id}
                    changeValidity={changeValidity}
                />
                <LaneFeeCancellation
                    id={data?.id}
                    cancelFee={cancelFee}
                    setReason={setCancelationReason}
                    reason={cancelationReason}
                />
                <ArchiveConfirmationDialog
                    title="Lane Fees"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
                <ReviewLaneFeesReturnReason
                    openConfirmDialog={openReviewLaneFeesReturnConfirm}
                    handleClose={() => { return setOpenReviewLaneFeesReturnConfirm(false) }}
                    data={{
                        returnCreatedBy: data?.returnCreatedBy,
                        returnCreatedOn: data?.returnCreatedOn,
                        returnReason: data?.returnReason,
                        returnReasonComment: data?.returnReasonComment,
                    }}
                />
                <RejectLaneFeesConfirmationDialog
                    open={toRejectedConfirmation}
                    action={toRejectHandler}
                    close={() => {
                        setToRejectedConfirmation(false)
                    }}
                />
                <ReviewLaneFeesRejectReason
                    openConfirmDialog={openReviewLaneFeesRejecteDialog}
                    handleClose={() => { return setOpenReviewLaneFeesRejectedDialog(false) }}
                    data={{
                        rejectReason: data?.rejectedReason,
                        rejectReasonComment: data?.rejectedReasonComment,
                        rejectCreatedBy: data?.rejectedBy,
                        rejectCreatedOn: data?.rejectedOn,
                    }}
                />
                <ReturnLaneFeesConfirmationDialog
                    open={returnToInProgressConfirmation}
                    close={() => { setReturnToInProgressConfirmation(false) }}
                    action={returnToInProgressHandler}
                />
            </>
        </StatusHandler>
    )
}

LaneFeesUpdate.defaultProps = defaultProps

export default LaneFeesUpdate

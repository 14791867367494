import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    LeaseType, RegionFirstLevel,
    ProductType, TemperatureRange,
} from 'app/types/enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    ForecastOngoingOpportunitiesTable,
} from '../Forecast.types'

export default ({
    tmpFields,
}: {
    tmpFields: Record<string, any>[]
}): SkyNetTableConfig<keyof ForecastOngoingOpportunitiesTable> => {
    return {
        name: 'ForecastOngoingOpportunities',
        tabs: [{
            name: 'Ongoing Opportunities',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'accountName',
                    labelKey: 'Forecasts.accountName',
                    filterField: 'accountName',
                    filterType: FilterType.Array,
                    reduced: true,
                },
                {
                    name: 'opportunityNumber',
                    labelKey: 'Forecasts.opportunityNumber',
                    reduced: true,
                },
                {
                    name: 'temperatureRange',
                    labelKey: 'AccountQuotes.opportunityTempRange',
                    componentName: 'EnumValue',
                    allowedValuesEnum: TemperatureRange,
                    reduced: true,
                },
                {
                    name: 'productType',
                    labelKey: 'Forecasts.productType',
                    componentName: 'EnumValue',
                    allowedValuesEnum: ProductType,
                    filterField: 'productType',
                    filterType: FilterType.Array,
                },
                {
                    name: 'leaseType',
                    labelKey: 'Forecasts.leaseType',
                    componentName: 'EnumValue',
                    allowedValuesEnum: LeaseType,
                    filterField: 'leaseType',
                    filterType: FilterType.Array,
                },
                {
                    name: 'originAirportRegion',
                    labelKey: 'RegionalGroups.originRegion',
                    componentName: 'EnumValue',
                    allowedValuesEnum: RegionFirstLevel,
                },
                {
                    name: 'originAirportIataCode',
                    labelKey: 'Forecasts.originAirport',
                    filterField: 'originAirportIataCode',
                    filterType: FilterType.Array,
                },
                {
                    name: 'destinationAirportRegion',
                    labelKey: 'RegionalGroups.destinationRegion',
                    componentName: 'EnumValue',
                    allowedValuesEnum: RegionFirstLevel,
                },
                {
                    name: 'destinationAirportIataCode',
                    labelKey: 'Forecasts.destinationAirport',
                    filterField: 'destinationAirportIataCode',
                    filterType: FilterType.Array,
                },
                {
                    name: 'opportunityCollaboratorsUserName',
                    labelKey: 'Opportunity.opportunityCollaborators',
                    filterField: 'opportunityCollaboratorsUserName',
                    componentName: 'List',
                    filterType: FilterType.Array,
                },
                {
                    name: 'changedOn',
                    labelKey: 'Forecasts.changedOn',
                    filterField: 'changedOn',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'changedBy',
                    labelKey: 'Forecasts.changedBy',
                    filterField: 'changedBy',
                    filterType: FilterType.Array,
                },
                ...tmpFields.map((fieldProps) => {
                    return fieldProps
                }),
            ],
        }],

    }
}

import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        cardContent: {
            height: 755,
        },
    }
})

export default useStyles

import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import AlLogisticPricingForm from '../AlLogisticPricingForm'
import AlLogisticPricingRequests from '../AlLogisticPricing.requests'

const AlLogisticPricingCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <AlLogisticPricingForm
            onSuccess={onSuccess}
            requestParam={AlLogisticPricingRequests.create()}
        />
    )
}

export default AlLogisticPricingCreate

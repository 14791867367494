import React, {
    useCallback, useMemo,
} from 'react'
import omit from 'lodash/omit'

import StatusHandler from 'app/shared-components/StatusHandler'
import HeaderWarning from 'app/shared-components/HeaderWarning'

import {
    useCollapsibleTableContext,
} from 'app/shared-components/CollapsibleTable'

import useGetLane from '../hooks/useGetLane'
import LaneForm from '../LaneForm'
import LaneRequests from '../Lane.requests'

const defaultProps = {
    id: undefined,
    refetch: () => {},
}

const LaneCopy = ({
    id,
    refetch,
}: { id?: number, refetch?: (...args: any[]) => void }) => {
    const {
        updatePathWithParams,
    } = useCollapsibleTableContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetLane(id)

    const onSuccess = useCallback(({
        id: newId,
    }) => {
        updatePathWithParams({
            id: newId,
        })
        refetch()
    }, [
        updatePathWithParams,
        refetch,
    ])

    const onCancel = useCallback(() => {
        updatePathWithParams({
            id: data?.id,
        })
    }, [
        data?.id,
        updatePathWithParams,
    ])

    const copyData = useMemo(() => {
        return {
            ...omit(data, [
                'id',
                'changedBy',
                'changedOn',
            ]),
            originId: data?.id,
        }
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <HeaderWarning>
                    You are copying a Lane.
                </HeaderWarning>
                <LaneForm
                    data={copyData}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={LaneRequests.duplicate(copyData?.originId)}
                />
            </>
        </StatusHandler>
    )
}

LaneCopy.defaultProps = defaultProps

export default LaneCopy

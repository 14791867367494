import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import ContractBasisDetails from '../ContractBasisDetails'

import requests from '../ContractBasis.requests'

const ContractBasisCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <ContractBasisDetails
            onSuccess={onSuccess}
            requestParams={requests.create()}
        />
    )
}

export default ContractBasisCreate

import {
    useCallback,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import {
    useJWTToken,
} from 'app/Auth'
import {
    useDispatch,
} from 'react-redux'

import LaneFeesRequests, {
    ChangeStatusToRejected,
} from 'app/Apps/Pricing/LaneGroups/LaneFees/LaneFees.requests'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import simpleApi from 'app/services/simpleApi'
import {
    LaneFeesStatus,
} from 'app/types/enums'
import {
    successedToSaveForm,
} from 'app/shared-components/FormStatuses/store/actions'

type Params = {
    notificationId: string,
    onSuccess?: (...args: any[]) => void
}

type MutationParams = {
    id: number,
    status: LaneFeesStatus.AWARDED
        | LaneFeesStatus.SUBMITTED
        | LaneFeesStatus.REJECTED
        | LaneFeesStatus.IN_PROGRESS,
    data? : ChangeStatusToRejected,
    title?: string,
}

const REQUEST_STATUS_MAP = {
    [LaneFeesStatus.AWARDED]: 'awarded',
    [LaneFeesStatus.SUBMITTED]: 'submitted',
    [LaneFeesStatus.REJECTED]: 'rejected',
    [LaneFeesStatus.IN_PROGRESS]: 'in-progress',
}

const useChangeLaneFeesStatus = ({
    notificationId,
    onSuccess,
}: Params) => {
    const token = useJWTToken()
    const dispatch = useDispatch()

    const {
        onError,
    } = useHandleStatusRequest({
        id: notificationId,
    })

    const onSuccessStatusChanged = useCallback((data, title) => {
        dispatch(
            successedToSaveForm({
                id: notificationId,
                title,
            }),
        )
        onSuccess(data)
    }, [
        dispatch,
        notificationId,
        onSuccess,
    ])

    const mutation = useMutation({
        mutationFn: ({
            id,
            status,
            data,
            title,
        }: MutationParams) => {
            return simpleApi({
                ...LaneFeesRequests.changeStatus({
                    id,
                    status: REQUEST_STATUS_MAP[status],
                    data,
                }),
                token,
            }).then((response) => {
                onSuccessStatusChanged(response, title)
            })
        },
        onError,
    })

    const changeStatus = useCallback(({
        id, status, data, title,
    }: MutationParams) => {
        mutation.mutate({
            id,
            status,
            data,
            title,
        })
    }, [mutation])

    return {
        changeStatus,
    }
}

export default useChangeLaneFeesStatus

import React from 'react'

import LocationAreasTable from './LocationAreasTable'
import LocationAreasContextWrapper from './LocationAreasContextWrapper'

export default function LocationAreas({
    id,
}: { id: number }) {
    return (
        <LocationAreasContextWrapper id={id}>
            <LocationAreasTable />
        </LocationAreasContextWrapper>
    )
}

import {
    SkyNetTableField, SkyNetTableTab,
} from '../../types/skyNetTable.types'

export default <T=any>(
    tabs: (Omit<SkyNetTableTab<T>, 'fields'>[] | SkyNetTableTab<T>[]),
    fields: SkyNetTableField<T>[],
): SkyNetTableTab<T>[] => {
    return tabs.map((tab) => {
        if (tab.fields) return tab
        return {
            ...tab, fields,
        }
    })
}

import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        requiredContainers: {
            marginTop: theme.spacing(2),
        },
        cardContent: {
            marginTop: theme.spacing(2),
            height: 755,
        },
    }
})

export default useStyles

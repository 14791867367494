import {
    PositioningSetupType,
} from './orderConfirmation.types'

const getServiceCenterMap = (positioningSetup: PositioningSetupType) => {
    return {
        origin: {
            DEFAULT: {
                data: {
                    originServiceCenter: positioningSetup?.originSc,
                    originServiceCenterContacts: positioningSetup?.originScContacts,
                },
            },
            BACKUP: {
                data: {
                    originServiceCenter: positioningSetup?.originScBackup,
                    originServiceCenterContacts: positioningSetup?.originScBackupContacts,
                },
            },
            OTHER: {
                data: {
                    originServiceCenter: positioningSetup?.originScOther,
                    originServiceCenterContacts: positioningSetup?.originScOtherContacts,
                },
            },
        },
        forwarder: {
            DEFAULT: {
                data: {
                    forwarderPositioning: positioningSetup?.forwarderPos,
                    forwarderPositioningContacts: positioningSetup?.forwarderPosContacts,
                },
            },
            BACKUP: {
                data: {
                    forwarderPositioning: positioningSetup?.forwarderPosBackup,
                    forwarderPositioningContacts: positioningSetup?.forwarderPosBackupContacts,
                },
            },
            OTHER: {
                data: {
                    forwarderPositioning: positioningSetup?.forwarderPosOther,
                    forwarderPositioningContacts: positioningSetup?.forwarderPosOtherContacts,
                },
            },
        },
    }
}

export default getServiceCenterMap

import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import {
    OpportunityStatus,
} from 'app/types/enums'
import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import {
    OpportunityType,
} from '../opportunities.types'

import OpportunitiesForm from '../OpportunitiesForm'

const CreateOpportunity = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        state,
    } = useLocation()

    return (
        <OpportunitiesForm
            data={{
                ...state?.autofill,
                opportunityStatus: OpportunityStatus.OPEN,
            }}
            onSuccess={onSuccess as (o: OpportunityType) => void}
        />
    )
}

export default CreateOpportunity

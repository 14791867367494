import React, {
    useMemo,
} from 'react'

import useFieldsWithClassNames from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'

import {
    showLTL, showFTL, isDeliveryRoad,
} from 'app/Apps/Pricing/utils/utils'
import useAccountQuoteContext from 'app/Apps/AccountManagement/AccountQuotes/AccountQuoteContext/useAccountQuoteContext'
import usePricingRoutes from 'app/Apps/Pricing/hooks/usePricingRoutes'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import getFields from './AccountQuoteLaneGroupOverview.fields'
import useStyles from './AccountQuoteLaneGroupOverview.styles'
import {
    AssignPricingLaneGroupResponse,
} from '../../../AccountQuotes.types'

const AccountQuoteLaneGroupOverview = ({
    data,
}: { data: AssignPricingLaneGroupResponse }) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const {
        isPerformanceQualification,
    } = useAccountQuoteContext()

    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()

    const value = useMemo(() => {
        return {
            ...data?.laneGroup,
            ...data?.quote,
            ...data?.requestedOpportunity,
            ...data?.requestedOpportunity?.opportunity,
            baseLeaseDays: (data?.quote?.baseLeaseDays || []).join(';'),
            changedOn: data?.laneGroup?.changedOn,
            id: data?.laneGroup?.id,
            isPerformanceQualification,
        }
    }, [
        data,
        isPerformanceQualification,
    ])

    const fields = useFieldsWithClassNames(
        getFields({
            getAllowedValues,
            isDeliveryRoad: isDeliveryRoad(value?.deliveryServiceType),
            showFTL: showFTL(value?.transportModeRoad),
            showLTL: showLTL(value?.transportModeRoad),
            laneGroupLink: generateSkyNetLink({
                domainPath: LaneGroupsRoutes.Overview.path,
                params: {
                    key: data?.laneGroup?.id,
                },
            }),
        }), classes,
    )

    return (
        <DomainObjectForm
            name="AccountQuoteLaneGroup"
            value={value}
            className={classes.gridWrapper}
            fields={fields}
            disabled
            exists
        />
    )
}

export default AccountQuoteLaneGroupOverview

import React, {
    useCallback,
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import clsx from 'clsx'
import {
    useLocation,
} from 'react-router-dom'

import {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'

import Tab from './Tab'
import useStyles from './Tabs.style'

const MAX_AM_TABS_EDIT_MODE = 3

type Props = {
    items?: SidebarTab[],
    editing?: boolean,
    className?: string,
    counter?: number,
    setSelectedTab?: (value: string) => void,
}

const defaultProps: Partial<Props> = {
    items: [],
    editing: false,
    className: '',
    counter: undefined,
    setSelectedTab: noop,
}

const checkIsActive = (pathname: string, tabUrl: string): boolean => {
    const splittedPathname = pathname.split('/')

    if (splittedPathname.includes('open')) {
        return tabUrl === splittedPathname[splittedPathname.indexOf('open') - 1]
    }

    return tabUrl === splittedPathname[splittedPathname.length - 1]
}

const Tabs = ({
    items,
    editing,
    className,
    counter,
    setSelectedTab,
}: Props) => {
    const {
        pathname,
    } = useLocation()

    const {
        classes,
    } = useStyles()
    const tabs = useMemo(() => {
        return editing ? items.slice(0, MAX_AM_TABS_EDIT_MODE) : items
    }, [
        items,
        editing,
    ])

    const isActive = useCallback((tab: SidebarTab) => {
        return tab.activeComparer
            ? tab.activeComparer(pathname, tab.url)
            : checkIsActive(pathname, tab.url)
    }, [pathname])

    return (
        <div
            className={clsx(classes.tabsWrapper, className)}
        >
            {
                tabs.map((tab, i) => {
                    const isActiveTab = tabs.length === 1 || isActive(tab)

                    return (
                        <Tab
                            key={`tab-${i}`} // eslint-disable-line react/no-array-index-key
                            setDefaultTab={setSelectedTab}
                            tab={tab}
                            counter={isActiveTab ? counter : undefined}
                            isActiveTab={isActiveTab}
                        />
                    )
                })
            }
        </div>
    )
}

Tabs.defaultProps = defaultProps

export default Tabs

import {
    RichTableColumns,
} from 'app/types/richtable.types'
import SkyNetLink, {
    TypeLink,
    SizeLink,
} from 'app/shared-components/SkyNetLink'
import {
    OpportunityPalletType,
    RegionFirstLevel,
} from 'app/types/enums'
import {
    OpportunityType,
    Packaging,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import {
    RequestedOpportunity,
} from '../../AccountQuotes.types'

const columns = ({
    l,
    opportunityStyle,
    getOpportunityLink,
}: {
    l: (l:string) => string,
    getAllowedValues: (e: Record<string, string>) => Record<string, string>
    opportunityStyle: string,
    getOpportunityLink: (id: string) => string
}): RichTableColumns<keyof (RequestedOpportunity & OpportunityType)>[] => {
    return [
        {
            id: 'opportunityNumber',
            headerProps: {
                children: l('Opportunity.opportunityNumber'),
            },
            mapCellProps: ({
                opportunityNumber,
                opportunity,
            }) => {
                return {
                    value: opportunityNumber,
                    link: getOpportunityLink(opportunity.id),
                    type: TypeLink.EXTERNAL,
                    className: opportunityStyle,
                    size: SizeLink.S,
                }
            },
            Cell: SkyNetLink,
        },
        {
            id: 'originRegion',
            headerProps: {
                children: 'Origin Region',
            },
            mapCellProps: 'originRegion',
            filterField: 'originRegion',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionFirstLevel,
        },
        {
            id: 'destinationRegion',
            headerProps: {
                children: 'Destination Region',
            },
            mapCellProps: 'destinationRegion',
            filterField: 'destinationRegion',
            componentName: 'EnumValue',
            allowedValuesEnum: RegionFirstLevel,
        },
        {
            id: 'temperatureRange',
            headerProps: {
                children: l('AccountQuotes.opportunityTempRange'),
            },
            mapCellProps: 'temperatureRange',
        },
        {
            id: 'competitorsPackaging',
            headerProps: {
                children: l('Opportunity.competitorsPackaging'),
            },
            mapCellProps: 'competitorsPackaging',
            componentName: 'EnumList',
            allowedValuesEnum: Packaging,
        },
        {
            id: 'currentPackagingSolution',
            headerProps: {
                children: l('Opportunity.currentPackagingSolution'),
            },
            mapCellProps: 'currentPackagingSolution',
            componentName: 'EnumValue',
            allowedValuesEnum: Packaging,
        },
        {
            id: 'businessStart',
            mapCellProps: 'businessStart',
            headerProps: {
                children: l('Opportunity.businessStart'),
            },
        },
        {
            id: 'palletType',
            headerProps: {
                children: l('Opportunity.palletType'),
            },
            mapCellProps: 'palletType',
            componentName: 'EnumValue',
            allowedValuesEnum: OpportunityPalletType,
        },
    ]
}

export default columns

import React, {
    useMemo,
} from 'react'
import {
    Navigate,
} from 'react-router-dom'

import {
    App, CommonRoutes,
    SkyNetRouteConfigObject,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'

import Dashboard from 'app/Apps/Dashboard'
import Finance from 'app/Apps/Finance'
import Pricing from 'app/Apps/Pricing'
import Loggers from 'app/Apps/Loggers'
import Deployments from 'app/Apps/Sensors/Deployments'
import TransportPlanner from 'app/Apps/TransportPlanner'
import Gateways from 'app/Apps/Sensors/Gateways'
import Masterdata from './Masterdata'
import ContactManagement from './ContactManagement'
import OrderManagement from './OrderManagement'
import AccountManagement from './AccountManagement'
import Forecast from './Forecast'
import DamageTracking from './DamageTracking'
import RelationshipsManagement from './RelationshipsManagement'
import ContainerManagement from './ContainerManagement'

export default function Apps() {
    const routesConfig: SkyNetRouteConfigObject<App> = useMemo(() => {
        return {
            [App.DASHBOARD]: <Dashboard />,
            [App.MASTERDATA]: <Masterdata />,
            [App.ORDER_MANAGEMENT]: <OrderManagement />,
            [App.CONTAINERS]: <ContainerManagement />,
            [App.FINANCE]: <Finance />,
            [App.GATEWAYS]: <Gateways />,
            [App.ACCOUNT_MANAGEMENT]: <AccountManagement />,
            [App.PRICING]: <Pricing />,
            [App.DEPLOYMENTS]: <Deployments />,
            [App.PRICING]: <Pricing />,
            [App.LOGGERS]: <Loggers />,
            [App.CONTACT_MANAGEMENT]: <ContactManagement />,
            [App.RELATIONSHIPS_MANAGEMENT]: <RelationshipsManagement />,
            [App.TRANSPORT_PLANNER]: <TransportPlanner />,
            [App.DAMAGE_TRACKING]: <DamageTracking />,
            [App.FORECAST]: <Forecast />,
            [CommonRoutes.SLASH]: <Navigate
                to={App.DASHBOARD}
                replace
            />,
        }
    }, [])

    return useSkyNetRoutes({
        routesConfig,
    })
}

import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        title: {
            '&&': {
                fontWeight: '400',
                fontSize: `${theme.typography.h3.fontSize}px !important`,
                letterSpacing: theme.typography.h3.letterSpacing,
                color: theme.palette.secondary.dark,
                display: 'flex',
            },
        },
        wrapperTiles: {
            display: 'flex',
            padding: theme.spacing(2, 0),
        },
    }
})

export default useStyles

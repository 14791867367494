import React, {
    useMemo,
} from 'react'

import SkyNetDomain from 'app/shared-components/SkyNetDomain'
import {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import requests from './PreBookings.requests'
import getTableConfig from './PreBookings.config'
import PreBookingCreate from './PreBookingCreate'
import PreBookingOverview from './PreBookingOverview'
import PreBookingUpdate from './PreBookingUpdate'
import PrebookingChangeHistory from './ChangeHistory'
import PreBookingBulkCreate from './PreBookingBulkCreate'
import PreBookingBulkEdit from './PreBookingBulkEdit'
import useAccountManagementRoutes from '../AccountManagement/hooks/useAccountManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.BulkCreate,
    TableControlTypes.BulkEdit,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const PreBookings = () => {
    const {
        PreBookings: PreBookingsRoutes,
    } = useAccountManagementRoutes()
    const canCreate = useHasPermission(['pre_booking_create'])
    const canUpdate = useHasPermission(['pre_booking_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: PreBookingsRoutes.Overview.route,
                Component: PreBookingOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: PreBookingsRoutes.Update.route,
                Component: PreBookingUpdate,
            },
            permission: canUpdate,
        },
        {
            tab: {
                label: 'Change History',
                url: PreBookingsRoutes.ChangeHistory.route,
                Component: PrebookingChangeHistory,
            },
            permission: canUpdate,
        },
    ])

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: canCreate ? PreBookingCreate : null,
                BulkCreate: canCreate ? PreBookingBulkCreate : null,
                BulkEdit: canUpdate ? PreBookingBulkEdit : null,
            }}
            name={tableConfig.name}
            getDomainObject={requests.get}
            domainRequestKey={RequestKeys.getPreBookings}
            sidebarTabs={tabs}
            defaultTab="overview"
        >
            <SkyNetTable
                moduleName={requests.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}

export default PreBookings

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export default (): SkyNetTableConfig => {
    return {
        tabs: [{
            name: 'Upcoming Container Selection',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'orderNumber',
                    filterField: 'orderNumber',
                    filterType: FilterType.Array,
                    width: '100px',
                },
                {
                    name: 'originAirportIataCode',
                    filterField: 'originAirportIataCode',
                    filterType: FilterType.Array,
                    width: '135px',
                },
                {
                    name: 'destinationAirportIataCode',
                    filterField: 'destinationAirportIataCode',
                    filterType: FilterType.Array,
                    width: '170px',
                },
                {
                    name: 'customerLocationName',
                    filterField: 'customerLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    labelKey: 'Prebooking.pricingAccount',
                    filterType: FilterType.Array,
                },
                {
                    name: 'originLocationLocationName',
                    filterField: 'originLocationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'handoverPointLocationName',
                    filterField: 'handoverPointLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'plannedPickup',
                    filterField: 'plannedPickup',
                    filterType: FilterType.DateRange,
                },
                {
                    name: 'requiredContainers',
                    componentName: 'List',
                },
                {
                    name: 'containerSelectionUntilUtc',
                    componentName: 'ContainerSelectionUntil',
                    headerProps: {
                        children: 'Remaining / Overdue',
                    },
                },
            ],
        }],
    }
}

import queryString from 'query-string'

import simpleApi from 'app/services/simpleApi'

const orderManagementRequest = {
    UpdateOrderContainerSelection: {
        domainName: 'OrderByNumberView',
        url: (orderNumber, apiPath) => {
            return `order/${orderNumber}/${apiPath}`
        },
    },
    UpdateStatusOrder: {
        request: (onSuccess) => {
            return {
                method: 'PATCH',
                domainName: 'Order',
                name: 'Order',
                api: simpleApi,
                onSuccess,
            }
        },
        url: (orderNumber) => {
            return `order/${orderNumber}/status`
        },
    },
    SaveOrderStep: {
        request: (onSuccess) => {
            return {
                name: 'OrderStep',
                api: simpleApi,
                method: 'PUT',
                domainName: 'OrderStep',
                onSuccess,
            }
        },
        url: (orderNumber, id) => {
            return `order/${orderNumber}/order-step/${id}`
        },
    },
    AddSelectedAssets: {
        request: (onSuccess) => {
            return {
                name: 'SelectedContainer',
                api: simpleApi,
                method: 'POST',
                onSuccess,
                domainName: 'SelectedContainer',
            }
        },
        url: (orderNumber) => {
            return `order/${orderNumber}/selected-containers`
        },
    },
    EditSelectedAssets: {
        request: {
            method: 'PATCH',
            domainName: 'SelectedContainer',
        },
        url: (orderNumber, id) => {
            return `order/${orderNumber}/selected-container/${id}`
        },
    },
    GetAvailableContainers: {
        request: {
            method: 'POST',
        },
        url: (orderNumber) => {
            return `order/${orderNumber}/available-containers`
        },
    },
    DailyOps: {
        name: 'DailyOpsView',
        uniqField: 'orderNumber',
        url: {
            toBeConfirmed: 'order/filter-request/daily-ops-toBeConfirmed',
            deliveryViaRoadInfo: 'order/filter-request/daily-ops-deliveryViaRoadInfo',
            deliveryViaRoadAddPickup: 'order/filter-request/daily-ops-deliveryViaRoadAddPickup',
            deliveryViaRoadConfirmation: 'order/filter-request/daily-ops-deliveryViaRoadConfirmation',
            pickupViaRoadConfirmation: 'order/filter-request/daily-ops-pickupViaRoadConfirmation',
            airTransportByCustomerInfo: 'order/filter-request/daily-ops-airTransportByCustomerInfo',
            confirmationOfDropOffInfo: 'order/filter-request/daily-ops-confirmationOfDropOffInfo',
            confirmationOfUnloadingInfo: 'order/filter-request/daily-ops-confirmationOfUnloadingInfo',
            missingInformation: 'order/filter-request/daily-ops-missingInformation',
            containerSelection: 'order/filter-request/daily-ops-containerSelection',
            pqOrders: 'order/filter-request/daily-ops-pq-orders',
        },
    },
    Pooling: {
        name: {
            poolingContainerSelection: 'OrderPoolingContainerSelectionView',
            poolingCustomerTransport: 'OrderPoolingCustomerTransportsView',
            poolingInternalTransports: 'OrderPoolingInternalTransportsView',
            PreBookingsTransports: 'OrderPoolingPreBookingsTransportsView',
        },
        url: {
            poolingContainerSelection: 'order/filter-request/pooling-container-selection',
            poolingCustomerTransport: 'order/filter-request/pooling-customer-transports',
            poolingInternalTransports: 'order/filter-request/pooling-internal-transports',
            poolingPickupAddInfo: 'order/filter-request/pooling-pickup-add-info',
            poolingPickupAddPickup: 'order/filter-request/pooling-pickup-add-pickup',
            pickupDeliveryConfirmation: 'order/filter-request/pooling-pickup-delivery-confirmation',
            transportDeparture: 'order/filter-request/pooling-transport-departure',
            transportArrival: 'order/filter-request/pooling-transport-arrival',
            deliveryAddInfo: 'order/filter-request/pooling-delivery-add-info',
            deliveryAddPickup: 'order/filter-request/pooling-delivery-add-pickup',
            deliveryConfirmation: 'order/filter-request/pooling-delivery-confirmation',
            preBookings: 'prebooking/filter-request/container-availability-assessment',
        },
    },
    Billing: {
        name: 'OrderBillingInformationView',
        request: (orderNumber) => {
            return {
                url: `order/${orderNumber}/billing/billing-information`,
                method: 'PATCH',
            }
        },
    },
    Position: {
        name: 'Position',
        UPDATE: {
            request: ({
                invoiceId,
                positionId,
            }) => {
                return {
                    url: `outgoinginvoice/${invoiceId}/position/${positionId}`,
                    method: 'PUT',
                }
            },
        },
        CREATE: {
            request: ({
                invoiceId,
            }) => {
                return {
                    url: `outgoinginvoice/${invoiceId}/position`,
                }
            },
        },
        DELETE: {
            mutationFn: ({
                token,
                invoiceId,
                positionId,
            }) => {
                return simpleApi({
                    url: `outgoinginvoice/${invoiceId}/position/${positionId}`,
                    method: 'DELETE',
                    token,
                })
            },
        },
    },
    AutoPopulatePosition: ({
        invoiceId,
        positionCode,
    }) => {
        return {
            url: queryString.stringifyUrl({
                url: `outgoinginvoice/${invoiceId}/position/pre-population`,
                query: {
                    positionCode,
                },
            }),
        }
    },
    Invoices: {
        name: 'OrderBillingInformationView',
        Update: {
            request: (selectedInvoiceId) => {
                return {
                    url: `outgoinginvoice/${selectedInvoiceId}/general-information`,
                    method: 'PATCH',
                }
            },
        },
        Create: {
            mutationFn: ({
                data,
                token,
                orderNumber,
            }) => {
                return simpleApi({
                    url: `order/${orderNumber}/billing/outgoinginvoice`,
                    data,
                    method: 'POST',
                    token,
                })
            },
        },
        SendToAbacus: {
            mutationFn: ({
                selectedInvoiceId,
                token,
            }) => {
                return simpleApi({
                    url: `outgoinginvoice/${selectedInvoiceId}/invoice-status`,
                    data: {
                        invoiceStatus: 'SENTTOABACUS',
                    },
                    method: 'PATCH',
                    token,
                })
            },
            request: (selectedInvoiceId) => {
                return {
                    data: {
                        invoiceStatus: 'SENTTOABACUS',
                    },
                    url: `outgoinginvoice/${selectedInvoiceId}/invoice-status`,
                }
            },
        },
    },
    LocationChangeSummary: {
        name: 'Modification',
        request: (locationNumber) => {
            return {
                customUrl: `location/${locationNumber}/modifications`,
                method: 'GET',
                name: 'Location',
            }
        },
    },
    Notifications: {
        ContainerSelection: {
            url: (orderNumber) => {
                return `order/${orderNumber}/notification/filter-request/containerselection`
            },
        },
        OrderSteps: {
            url: (orderNumber, orderStepId) => {
                return `order/${orderNumber}/orderstep/${orderStepId}/notification/filter-request/all`
            },
        },
        Overview: {
            url: (orderNumber) => {
                return `order/${orderNumber}/notification/filter-request/all`
            },
        },
    },
    OrderChangeSummary: {
        name: 'Modification',
        request: (orderNumber) => {
            return {
                customUrl: `order/${orderNumber}/modifications`,
                method: 'GET',
                name: 'Order',
            }
        },
    },
    OrderPostponements: {
        name: 'PostponementView',
        request: (orderNumber) => {
            return {
                customUrl: `order/${orderNumber}/postponement/all`,
                method: 'GET',
                name: 'PostponementView',
            }
        },
    },
    RequiredContainersChanges: {
        name: 'RequiredContainersChanges',
        request: (orderNumber) => {
            return {
                customUrl: `order/${orderNumber}/requiredcontainerschanges/all`,
                method: 'GET',
                name: 'RequiredContainersChanges',
            }
        },
    },
    PreviousOrders: {
        name: 'Order',
        url: (locationId) => { return `order/filter-request/previous-orders/${locationId}` },
    },
    PreviousOrdersByOrder: {
        name: 'Order',
        url: (orderNumber) => {
            return `order/${orderNumber}/previous-orders`
        },
        requestFn: ({
            orderNumber,
            params,
        }) => {
            return {
                url: `order/${orderNumber}/previous-orders`,
                method: 'POST',
                ...params,
            }
        },
    },
    Lane: {
        domainName: 'LaneOpsInformationDto',
        Get: ({
            id,
            token,
        }) => {
            return {
                url: `lane/${id}/ops-information`,
                method: 'GET',
                domainName: 'LaneOpsInformationDto',
                token,
            }
        },
        Update: ({
            id,
        }) => {
            return {
                method: 'PATCH',
                url: `lane/${id}/lane-logistics`,
            }
        },
        mutationFn: ({
            id,
            data,
            token,
        }) => {
            return simpleApi({
                url: `lane/${id}/implementation-details`,
                data,
                method: 'PATCH',
                token,
            })
        },
    },
    OrderNotification: {
        domainName: 'NotificationTableView',
        Update: ({
            id,
        }) => {
            return {
                url: `notification/${id}/general-data`,
                method: 'PATCH',
            }
        },
    },
}

export default orderManagementRequest

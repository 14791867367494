import {
    useMemo,
} from 'react'

import {
    App, CommonRoutes, createRoutesConfig, useSkyNetNavigate,
} from 'app/SkyNetRoutes'

import useCreateSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

export default function useGatewaysRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useCreateSkyNetDomainRoutes()()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [],
            Gateways: {
                route: App.GATEWAYS,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    LeaseType,
} from 'app/types/enums'
import {
    CollServiceType,
    DeliveryServiceType,
    Lane,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export default (): SkyNetTableConfig => {
    return {
        name: 'Pricing.Lanes.AirTransport',
        tabs: [{
            name: 'All',
            url: CommonRoutes.ALL,
            fields: [
                {
                    name: 'laneNumber',
                },
                {
                    name: 'laneGroupNumber',
                    labelKey: 'Lane.laneGroup',
                },
                {
                    name: 'customerQuotationLocationName',
                    filterField: 'customerQuotationLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'customerExecutionLocationName',
                    filterField: 'customerExecutionLocationName',
                    filterType: FilterType.Array,
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    labelKey: 'Lane.pricingAccount',
                    filterType: FilterType.Array,
                    mapCellProps: ({
                        hasNDA,
                        pricingAccountCompanyName,
                    }) => {
                        return {
                            children: `${hasNDA ? 'NDA' : pricingAccountCompanyName}`,
                        }
                    },
                },
                {
                    id: 'leaseType',
                    headerProps: {
                        children: 'Lease Type',
                    },
                    mapCellProps: 'leaseType',
                    componentName: 'EnumValue',
                    filterField: 'leaseType',
                    filterType: FilterType.Array,
                    allowedValuesEnum: LeaseType,
                },
                {
                    name: 'consigneeLocationName',
                    mapCellProps: ({
                        consigneeLocationName,
                        consigneeProvisional,
                    }: Lane) => {
                        return {
                            children: consigneeLocationName || consigneeProvisional,
                        }
                    },
                },
                {
                    name: 'deliveryServiceType',
                    filterField: 'deliveryServiceType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: DeliveryServiceType,
                },
                {
                    name: 'collectionServiceType',
                    filterField: 'collectionServiceType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: CollServiceType,
                },
                {
                    name: 'originAirportIataCode',
                    filterField: 'originAirportIataCode',
                    filterType: FilterType.Array,
                },
                {
                    name: 'destAirportIataCode',
                    filterField: 'destAirportIataCode',
                    filterType: FilterType.Array,
                },
                {
                    name: 'tempRange',
                    filterField: 'tempRange',
                    filterType: FilterType.Array,
                },
                {
                    name: 'laneStatus',
                    filterType: FilterType.Array,
                    filterField: 'laneStatus',
                    componentName: 'LaneStatus',
                },
            ],
        }],
    }
}

import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import AlLeasePricingForm from '../AlLeasePricingForm'
import AlLeasePricingRequests from '../AlLeasePricing.requests'

const AlLeasePricingCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <AlLeasePricingForm
            onSuccess={onSuccess}
            requestParam={AlLeasePricingRequests.create()}
        />
    )
}

export default AlLeasePricingCreate

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    OrderStatuses,
} from 'app/types/enums'
import {
    CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import ReverseLogisticBtn from '../ReverseLogisticBtn'
import {
    PoolingFields,
} from '../pooling.types'

export default (openCreateForm: ({
    collectionDropoffPointId,
    selectedPreviousOrderId,
}: {
    collectionDropoffPointId: number,
    selectedPreviousOrderId: number,
}) => void): SkyNetTableConfig<PoolingFields> => {
    return {
        name: 'OrderManagement.Pooling.AirlineLease',
        tabs: [{
            name: 'Airline Lease',
            url: CommonRoutes.ALL,
            includeFilters: {
                orderSubType: ['AIRLINE_LEASE'],
            },
            fields: [
                {
                    name: 'orderNumber',
                    width: '100px',
                },
                {
                    name: 'from',
                    filterField: 'from',
                    filterType: FilterType.Array,
                    width: '80px',
                },
                {
                    name: 'to',
                    filterField: 'to',
                    filterType: FilterType.Array,
                    width: '80px',
                },
                {
                    name: 'customerLocationName',
                    filterField: 'customerLocationName',
                    filterType: FilterType.Array,
                    width: '370px',
                },
                {
                    name: 'pricingAccountCompanyName',
                    filterField: 'pricingAccountCompanyName',
                    labelKey: 'Prebooking.pricingAccount',
                    filterType: FilterType.Array,
                    width: '220px',
                },
                {
                    name: 'baseLeaseUntilTimestamp',
                    filterField: 'baseLeaseUntilTimestamp',
                    filterType: FilterType.DateRange,
                    width: '200px',
                },
                {
                    name: 'orderStatus',
                    filterField: 'orderStatus',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: OrderStatuses,
                    width: '130px',
                },
                {
                    name: 'collectionServiceType',
                    filterField: 'collectionServiceType',
                    filterType: FilterType.Array,
                    componentName: 'EnumValue',
                    allowedValuesEnum: CollServiceType,
                    width: '240px',
                },
                {
                    name: 'collectionDropoffPointLocationName',
                    filterField: 'collectionDropoffPointLocationName',
                    filterType: FilterType.Array,
                    headerProps: {
                        children: 'Collection / Drop-off Point’',
                    },
                    width: '290px',
                },
                {
                    name: 'containersForPooling',
                    componentName: 'ContainerForPooling',
                    headerProps: {
                        children: 'Containers In Collection/Drop-off Point',
                    },
                    width: '280px',
                    sorting: false,
                },
                {
                    id: 'id',
                    headerProps: {
                        children: '',
                    },
                    width: '100px',
                    mapCellProps: ({
                        forwarderExportId,
                        collectionDropoffPointId,
                        orderStatus,
                        id,
                    }) => {
                        return {
                            collectionDropoffPointId: forwarderExportId || collectionDropoffPointId,
                            openCreateForm,
                            orderStatus,
                            id,
                        }
                    },
                    Cell: ReverseLogisticBtn,
                },
            ],
        }],
    }
}

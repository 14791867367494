import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'
import groupBy from 'lodash/groupBy'
import Typography from '@mui/material/Typography'

import TimelineChart from 'app/shared-components/TimelineChart'
import FullScreen from 'app/shared-components/FullScreen'

import options from './optionsChart'
import useStyles from './GatewayTimelineChart.styles'

const columns = [
    {
        type: 'string',
        id: 'Gateway',
    },
    {
        type: 'date',
        id: 'Start',
    },
    {
        type: 'date',
        id: 'End',
    },
]

const defaultProps = {
    setTemperatureChartFullscreen: noop,
    temperatureChartFullscreen: false,
}

const GatewayTimelineChart = ({
    sensorData,
    sensorLabels,
    temperatureChartFullscreen,
    setTemperatureChartFullscreen,
}: {
    sensorData: {
        t: string,
        d: (number|string)[],
    }[],
    sensorLabels: string[],
    temperatureChartFullscreen?: boolean,
    setTemperatureChartFullscreen?: (value: boolean) => void,
}) => {
    const {
        classes,
    } = useStyles()

    const dataChart: (string | number | Date)[][][] = useMemo(() => {
        return sensorData?.reduce((acc, item) => {
            item.d.forEach((logger, loggerIndex) => { // group data by logger
                if (logger) {
                    acc[loggerIndex].push({
                        ...item,
                        d: item.d[loggerIndex],
                    })
                }
            })

            return acc
        }, Array.from({
            length: sensorLabels.length,
        }, () => { return ([]) }))
            .map((dataLogger) => {
                return dataLogger.map(({
                    d, t,
                }, index) => {
                    const itemChart = [
                        d,
                        new Date(t),
                    ]

                    if (index < dataLogger.length - 1 && dataLogger[index + 1].d === d) {
                        return [
                            ...itemChart,
                            new Date(dataLogger[index + 1].t),
                        ]
                    }

                    return [
                        ...itemChart,
                        new Date(t),
                    ]
                })
            })
    }, [
        sensorData,
        sensorLabels,
    ])

    if (dataChart.length === 1) {
        return (
            <FullScreen
                setTemperatureChartFullscreen={setTemperatureChartFullscreen}
            >
                <TimelineChart
                    value={dataChart[0]}
                    options={{
                        ...options,
                        height: `${dataChart[0].length * 25}`,
                    }}
                    columns={columns}
                    className={temperatureChartFullscreen
                        ? classes.fullscreen : classes.chart}
                />
            </FullScreen>
        )
    }

    if (dataChart.length > 1) {
        return (
            <FullScreen
                setTemperatureChartFullscreen={setTemperatureChartFullscreen}
            >
                <div>
                    {sensorLabels.map((label, i) => {
                        const numberOfRows = Object.keys(groupBy( // to set up height for chart
                            dataChart[i], (item) => { return item[0] },
                        ))?.length

                        return (
                            <div
                                key={label}
                            >
                                <Typography
                                    variant="h3"
                                    className={classes.title}
                                >
                                    {label}
                                </Typography>
                                <TimelineChart
                                    value={dataChart[i]}
                                    options={{
                                        ...options,
                                        height: numberOfRows * 55,
                                    }}
                                    columns={columns}
                                    className={temperatureChartFullscreen
                                        ? classes.fullscreen : classes.chart}
                                />
                            </div>
                        )
                    })}
                </div>
            </FullScreen>
        )
    }

    return (<div data-testid="empty-state-msg">There is no Gateway data for the selected period.</div>)
}

GatewayTimelineChart.defaultProps = defaultProps

export default GatewayTimelineChart

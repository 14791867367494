import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import getTableConfig from './longTermLease.config'
import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const LongTermLease = () => {
    const {
        LongTermLease: LongTermLeaseRoutes,
    } = useOrderManagementRoutes()

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            name="LongTermLease"
            sidebarTabs={[]}
            defaultTab={LongTermLeaseRoutes.Overview.route}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>
    )
}

export default LongTermLease

import React from 'react'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import GatewayRequests from '../Gateways.request'
import GatewayForm from '../GatewayForm'

const GatewayCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    return (
        <GatewayForm
            onSuccess={onSuccess}
            requestParam={GatewayRequests.create.request()}
        />
    )
}

export default GatewayCreate

import React, {
    useCallback,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    App,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

import useOrderManagementRoutes from 'app/Apps/OrderManagement/hooks/useOrderManagementRoutes'
import useAccountManagementRoutes from 'app/Apps/AccountManagement/hooks/useAccountManagementRoutes'
import useGetPreBooking from '../components/hooks/useGetPreBooking'
import PreBookingUpdate from './PreBookingUpdate'

const PreBookingUpdateContainer = ({
    id,
}: Readonly<{id: number}>) => {
    const {
        PreBookings: OrderPreBookingRoutes,
    } = useOrderManagementRoutes()
    const {
        PreBookings: AccountsPreBookingRoutes,
    } = useAccountManagementRoutes()
    const {
        data: preBookingData,
        isDataReady,
        isError,
        error,
        isFetching,
    } = useGetPreBooking(id)

    const {
        pathname,
    } = useLocation()

    const onSuccess = useCallback(() => {
        let Module = AccountsPreBookingRoutes

        if (pathname.includes(App.ORDER_MANAGEMENT)) {
            Module = OrderPreBookingRoutes
        }

        Module.Overview.navigate({
            params: {
                key: id,
            },
        })
    }, [
        AccountsPreBookingRoutes,
        OrderPreBookingRoutes,
        id,
        pathname,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <PreBookingUpdate
                data={preBookingData}
                onSuccess={onSuccess}
            />
        </StatusHandler>
    )
}

export default PreBookingUpdateContainer

import React, {
    useCallback,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    useNavigate,
    NavLink,
} from 'react-router-dom'

import {
    Typography,
} from '@mui/material'

import useStyles from './Tab.style'

const propTypes = {
    tab: PropTypes.shape({
        url: PropTypes.string,
        tabUrl: PropTypes.string,
        defaultUrl: PropTypes.string,
        label: PropTypes.string,
    }).isRequired,
    setDefaultTab: PropTypes.func.isRequired,
    counter: PropTypes.number,
    isActiveTab: PropTypes.bool,
}

const defaultProps = {
    counter: undefined,
    isActiveTab: undefined,
}

const Tab = ({
    tab,
    setDefaultTab,
    counter,
    isActiveTab,
}) => {
    const {
        classes,
    } = useStyles()
    const navigate = useNavigate()

    const onChangeTab = useCallback((e) => {
        e.preventDefault()
        if (!isActiveTab) {
            setDefaultTab(tab.url)
            navigate(`../${tab.url}`)
        }
    }, [
        isActiveTab,
        tab.url,
        setDefaultTab,
        navigate,
    ])

    return (
        <NavLink
            data-testid={`tab-link-${tab.url}`}
            to={tab.url}
            className={clsx(classes.item, isActiveTab && classes.active)}
            onClick={onChangeTab}
        >
            <Typography
                variant="h5"
            >
                {tab.label}
            </Typography>
            {counter !== undefined && <div className={classes.counter}>{counter}</div>}
        </NavLink>
    )
}

Tab.propTypes = propTypes
Tab.defaultProps = defaultProps

export default Tab

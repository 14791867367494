import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton, useArchive, ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import OpportunitiesForm from '../OpportunitiesForm'
import useGetOpportunityById from '../hooks/useGetOpportunityById'
import request from '../opportunities.request'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const DataUpdate = ({
    id,
}: {id: number}) => {
    const {
        Opportunities: OpportunitiesRoutes,
    } = useAccountManagementRoutes()
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetOpportunityById(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'OpportunityUpdateDto',
        title: 'Opportunity',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: OpportunitiesRoutes.path,
        }),
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        archive,
        data?.archived,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <OpportunitiesForm
                    onSuccess={invalidate}
                    data={data}
                    exists
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Opportunity"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default DataUpdate

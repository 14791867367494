import {
    useMemo,
} from 'react'
import {
    useSkyNetNavigate, SkyNetRoutesConfig, App, CommonRoutes, createRoutesConfig,
} from 'app/SkyNetRoutes'
import useCreateSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useCreateSkyNetDomainRoutes'
import {
    MasterdataDomains,
} from '../Masterdata.types'

export default function useMasterdataRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useCreateSkyNetDomainRoutes()()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.MASTERDATA],
            Countries: {
                route: MasterdataDomains.COUNTRIES,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}

import {
    injectFieldsToTabs,
    SkyNetTableConfig, SkyNetTableField,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    AttitudeToSkyCell, EngagementTableTabs, EngagementTableType,
} from './engagement.types'

export default (): SkyNetTableConfig<keyof EngagementTableType> => {
    const fields: SkyNetTableField<keyof EngagementTableType>[] = [
        {
            name: 'contactName',
            reduced: true,
            labelKey: 'Engagement.contactName',
        },
        {
            name: 'contactCompanyName',
            filterField: 'contactCompanyName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'Engagement.contactCompanyName',
        },
        {
            name: 'contactCountryName',
            filterField: 'contactCountryName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'Engagement.contactCountryName',
        },
        {
            name: 'attitudeToSkyCell',
            filterField: 'attitudeToSkyCell',
            filterType: FilterType.Array,
            componentName: 'AttitudeToSkyCellTable',
            allowedValuesEnum: AttitudeToSkyCell,
            labelKey: 'Engagement.attitudeToSkyCell',
        },
    ]

    return {
        name: 'EngagementTable',
        tabs: injectFieldsToTabs<keyof EngagementTableType>([
            {
                name: 'Prospect Contacts',
                url: CommonRoutes.ALL,
                requestUrl: 'prospect',
            },
            {
                name: 'Contacts',
                url: EngagementTableTabs.Contacts,
                requestUrl: 'all',
                includeFilters: {
                    contactIsProspect: [false],
                },
            },
        ], fields),
    }
}

import React, {
    useMemo,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'
import {
    ProductType,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'

type Props = {
    value?: ProductType[],
    title?: string,
}

const defaultProps = {
    value: [],
    title: 'Allowed Container Types',
}

const AllowedContainerTypes = (props: Props) => {
    const {
        value,
        title,
    } = props
    const getEnumValues = useEnumValues()
    const allowedValues: Record<string, any> = useMemo(() => {
        return getEnumValues(ProductType)
    }, [getEnumValues])

    const allowedContainerTypes: {label: ProductType}[] = useMemo(() => {
        return value.map((item) => {
            return {
                label: allowedValues[item],
            }
        })
    }, [
        allowedValues,
        value,
    ])

    return (
        <ChipSelect
            value={allowedContainerTypes}
            title={title}
            disabled
        />
    )
}

AllowedContainerTypes.defaultProps = defaultProps

export default AllowedContainerTypes

import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    Navigate, RouteObject,
    useNavigate,
} from 'react-router-dom'
import useSkyNetRoutes from 'app/SkyNetRoutes/hooks/useSkyNetRoutes'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import {
    SidebarTab,
    ActionComponentsType,
} from './SkyNetDomain.types'
import useCreateSkyNetDomainRoutes from './useCreateSkyNetDomainRoutes'
import useCreateSkyNetDomainContext from './useCreateSkyNetDomainContext'
import SkyNetDomainContext from './SkyNetDomainContext'
import SkyNetDomainForm from './SkyNetDomainForm'
import SkyNetDomainTabs from './SkyNetDomainTabs'
import {
    SubDomainUrlsConfig,
} from './SkyNetDomain.utils'

const defaultProps = {
    editable: true,
    uniqField: 'id',
    subdomainUrls: {
        urls: [CommonRoutes.ALL],
        default: CommonRoutes.ALL,
    },
    actionComponents: undefined,
    defaultTab: undefined,
    name: undefined,
    domainRequestKey: undefined,
    sidebarTabs: [],
    getDomainObject: undefined,
    customRoutes: [],
}

export default function SkyNetDomain({
    children,
    actionComponents: ActionComponents,
    defaultTab,
    name,
    domainRequestKey,
    sidebarTabs,
    getDomainObject,
    editable,
    uniqField,
    subdomainUrls,
    customRoutes = [],
}: Readonly<{
    children: JSX.Element,
    actionComponents?: ActionComponentsType,
    defaultTab?: string,
    name?: string,
    domainRequestKey?: RequestKeys,
    uniqField?: string,
    sidebarTabs?: SidebarTab[],
    getDomainObject?: (params: Record<string, any>) => RequestConfig,
    editable?: boolean,
    subdomainUrls?: SubDomainUrlsConfig,
    customRoutes?: RouteObject[]
}>) {
    const {
        All: Routes,
        BulkCreate,
        BulkEdit,
    } = useCreateSkyNetDomainRoutes()()
    const navigate = useNavigate()

    const goBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const creatable = Boolean(ActionComponents?.Create)
    const printable = Boolean(ActionComponents?.Print)
    const bulkCreatable = Boolean(ActionComponents?.BulkCreate)
    const bulkEditable = Boolean(ActionComponents?.BulkEdit)

    const skyNetDomainContext = useCreateSkyNetDomainContext({
        defaultTab,
        name,
        domainRequestKey,
        creatable,
        printable,
        editable,
        uniqField,
    })

    const onSuccess = useCallback((obj: Partial<{id: number}>) => {
        if (obj?.[uniqField]) {
            navigate(`./${generateSkyNetLink({
                domainPath: Routes.Edit.path,
                absolute: false,
                params: {
                    key: obj[uniqField],
                },
            })}`, {
                relative: 'path',
            })
        } else {
            navigate('.', {
                relative: 'path',
            })
        }
        if (obj) skyNetDomainContext.addTableElement(obj)
    }, [
        uniqField,
        Routes.Edit,
        navigate,
        skyNetDomainContext,
    ])

    const childForms: RouteObject[] = useMemo(() => {
        const forms = []

        if (creatable) {
            forms.push({
                path: Routes.Create.route,
                element: (
                    <SkyNetDomainForm onClose={goBack}>
                        <ActionComponents.Create onSuccess={onSuccess} />
                    </SkyNetDomainForm>
                ),
            })
        }

        if (printable) {
            forms.push({
                path: Routes.Print.route,
                element: (
                    <SkyNetDomainForm onClose={goBack}>
                        <ActionComponents.Print onClose={onSuccess} />
                    </SkyNetDomainForm>
                ),
            })
        }

        if (editable) {
            const {
                Edit,
            } = Routes

            forms.push({
                path: `${Edit.route}/${Edit.stringParams}/${CommonRoutes.ASTERISK}`,
                element: (
                    <SkyNetDomainTabs
                        tabs={sidebarTabs}
                        getDomainObject={getDomainObject}
                    />
                ),
            })
        }

        return forms
    }, [
        ActionComponents,
        creatable,
        editable,
        getDomainObject,
        goBack,
        printable,
        sidebarTabs,
        Routes,
        onSuccess,
    ])

    const bulkRoutes: RouteObject[] = useMemo(() => {
        const forms = []
        const backToTable = () => {
            navigate('.')
        }

        if (bulkCreatable) {
            forms.push({
                path: BulkCreate.route,
                element: <ActionComponents.BulkCreate onClose={backToTable} />,
            })
        }

        if (bulkEditable) {
            forms.push({
                path: BulkEdit.route,
                element: <ActionComponents.BulkEdit onClose={backToTable} />,
            })
        }

        return forms
    }, [
        ActionComponents,
        bulkCreatable,
        bulkEditable,
        navigate,
        BulkCreate,
        BulkEdit,
    ])

    const DomainCoreElement = useMemo(() => {
        return (
            <SkyNetDomainContext.Provider value={skyNetDomainContext}>
                {children}
            </SkyNetDomainContext.Provider>
        )
    }, [
        children,
        skyNetDomainContext,
    ])

    return useSkyNetRoutes({
        routes: [
            ...subdomainUrls.urls.map((url) => {
                return {
                    path: url,
                    element: DomainCoreElement,
                    children: childForms,
                }
            }),
            ...bulkRoutes,
            ...customRoutes,
            {
                path: CommonRoutes.SLASH,
                element: <Navigate
                    to={
                        subdomainUrls.defaultSubDomainUrl || Routes.route
                    }
                    replace
                />,
            },
        ],
    })
}

SkyNetDomain.defaultProps = defaultProps

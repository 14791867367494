import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import request from '../orderConfirmation.request'
import {
    PositioningSetupType,
} from '../orderConfirmation.types'

const useGetPositioningSetup = (orderNumber: string) => {
    const queryClient = useQueryClient()

    const invalidate = () => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getPositioningSetup],
        })
    }

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<PositioningSetupType>({
        key: RequestKeys.getPositioningSetup,
        params: {
            orderNumber,
        },
        requestFunc: RequestFn.getEntity(request.Get.requestFn),
        enabled: Boolean(orderNumber),
        keepPreviousData: true,
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    }
}

export default useGetPositioningSetup

import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

const NDA_LABEL = 'NDA'

export default (): SkyNetTableConfig => {
    return {
        labelEntityName: 'ContractBasisUpdateDto', // if no labelEntityName than will take table.name
        tabs: [{
            name: 'Contract Basis',
            url: 'all',
            fields: [
                {
                    name: 'contractBasisNumber',
                    labelKey: 'ContractBasis.contractBasisId',
                    reduced: true,
                },
                {
                    name: 'customerQuotationLocationName',
                    filterField: 'customerQuotationLocationName',
                    filterType: FilterType.Array,
                    reduced: true,
                    labelKey: 'ContractBasisUpdateDto.customerQuotation',
                },
                {
                    name: 'accountCompanyCompanyName',
                    filterField: 'accountCompanyCompanyName',
                    filterType: FilterType.Array,
                    mapCellProps: ({
                        hasNDA,
                        accountCompanyCompanyName,
                    }) => {
                        return {
                            children: `${hasNDA ? NDA_LABEL : accountCompanyCompanyName}`,
                        }
                    },
                    reduced: true,
                    labelKey: 'Prebooking.pricingAccount', // if labelKey don't have . - than will take labelEntityName.labelKey
                    customFilterItemLabelConverter: (value) => {
                        return value || NDA_LABEL
                    },
                },
                {
                    name: 'currency',
                    filterField: 'currency',
                    filterType: FilterType.Array,
                    labelKey: 'LaneOpsInformationDto.currency',
                },
                {
                    name: 'keyAccountManager',
                    filterField: 'keyAccountManager',
                    filterType: FilterType.Array,
                // if no labelKey than will take {labelEntityName.name}
                },
                {
                    name: 'businessDevelopers',
                    filterField: 'businessDevelopers',
                    filterType: FilterType.Array,
                    componentName: 'List',
                },
            ],
        }],
    }
}

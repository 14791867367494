import React, {
    useCallback, useMemo,
} from 'react'
import useScrollToForm from 'app/hooks/useScrollToForm'
import HeaderWarning from 'app/shared-components/HeaderWarning'
import StatusHandler from 'app/shared-components/StatusHandler'
import {
    useCollapsibleTableContext,
} from 'app/shared-components/CollapsibleTable'
import omit from 'lodash/omit'
import {
    AdditionalFeesStatus,
} from 'app/types/enums'
import useContractBasisContext from 'app/Apps/Pricing/ContractBasis/hooks/useContractBasisContext'
import AdditionalFeesRequests from '../AdditionalFees.requests'
import AdditionalFeesForm from '../AdditionalFeesForm'
import useGetAdditionalFeesById from '../hooks/useGetAdditionalFeesById'

type Props = {
    id: number,
    refetch: (...args: any[]) => void
}

export default function AdditionalFeesCopy({
    id,
    refetch,
}: Readonly<Props>) {
    const scrollToForm = useScrollToForm(`scroll-to-${id}`)

    const {
        updatePathWithParams,
    } = useCollapsibleTableContext()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAdditionalFeesById(id)

    const onSuccess = useCallback(({
        id: newId,
    }) => {
        updatePathWithParams({
            id: newId,
        })
        refetch()
    }, [
        updatePathWithParams,
        refetch,
    ])

    const onCancel = useCallback(() => {
        updatePathWithParams({
            id: data?.id,
        })
        invalidate()
    }, [
        invalidate,
        data?.id,
        updatePathWithParams,
    ])

    const requestParams = useMemo(() => {
        return AdditionalFeesRequests.duplicate({
            id: data?.id,
        })
    }, [data?.id])

    const {
        contractId,
        currency,
    } = useContractBasisContext()

    const valueCleaned = useMemo(() => {
        return {
            ...omit(data, [
                'id',
                'createdOn',
                'createdBy',
                'changedBy',
                'changedOn',
                'additionalFeesNumber',
                'contractWithCurrencyId',
                'supportingDocuments',
                'fees',
            ]),
            contractId,
            currency,
            additionalFeesStatus: AdditionalFeesStatus.IN_PROGRESS,
            additionalFeesId: data?.id,
        }
    }, [
        data,
        contractId,
        currency,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <div
                    ref={scrollToForm}
                >
                    <HeaderWarning>You are deep copying a additional fee.</HeaderWarning>
                    <AdditionalFeesForm
                        value={valueCleaned}
                        onSuccess={onSuccess}
                        onCancel={onCancel}
                        requestParams={requestParams}
                    />
                </div>
            </>
        </StatusHandler>
    )
}

import {
    useMemo,
} from 'react'

import {
    ALLOWED_ASK_FOR_POOLING,
} from 'app/Apps/OrderManagement/Orders/utils/orderStatus'
import {
    OrderStatuses,
} from 'app/types/enums'

import useUpdateStatusOrder from './useUpdateStatusOrder'

type Args = {
    orderNumber: string,
    orderStatus: OrderStatuses,
    onSuccess: () => void,
}

type DataButtonType = {
    name: string,
    label: string,
    dataToSend: OrderStatuses,
    secondary?: boolean,
}

type ConditionShowType = {
    askForPrecon: boolean,
    askForPooling: boolean,
    reject: boolean,
    discussionRequired: boolean,
}

const dataButtons: DataButtonType[] = [
    {
        name: 'askForPrecon',
        label: 'ask for precon',
        secondary: true,
        dataToSend: OrderStatuses.CONFIRMING_PRECON_FEASIBILITY,
    },
    {
        name: 'askForPooling',
        label: 'ask for pooling',
        secondary: true,
        dataToSend: OrderStatuses.WAITING_FOR_POOLING,
    },
    {
        name: 'reject',
        label: 'reject',
        secondary: true,
        dataToSend: OrderStatuses.REJECTED,
    },
    {
        name: 'discussionRequired',
        label: 'discussion required',
        secondary: true,
        dataToSend: OrderStatuses.IN_DISCUSSION,
    },
]

const useGetStatusOrderBtns = ({
    orderNumber,
    orderStatus,
    onSuccess,
}: Args) => {
    const {
        mutate,
    } = useUpdateStatusOrder(onSuccess, orderNumber)

    const conditionShowMap: ConditionShowType = useMemo(() => {
        return {
            askForPrecon: orderStatus === OrderStatuses.NOT_STARTED,
            askForPooling: ALLOWED_ASK_FOR_POOLING.includes(orderStatus),
            reject: orderStatus === OrderStatuses.IN_DISCUSSION,
            discussionRequired: orderStatus === OrderStatuses.WAITING_FOR_POOLING,
        }
    }, [orderStatus])

    const allowedButtons: DataButtonType[] = useMemo(() => {
        return dataButtons.filter(({
            name,
        }) => {
            return conditionShowMap[name]
        })
    }, [conditionShowMap])

    return {
        allowedButtons,
        sendStatusOrder: mutate,
    }
}

export default useGetStatusOrderBtns

import {
    injectFieldsToTabs,
    SkyNetTableConfig, SkyNetTableField,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    LoggerType,
} from '../../Loggers'

import {
    Containers,
} from './containers.types'

export enum ContainersTablesTab {
    Operational = 'operational'
}

export default (): SkyNetTableConfig<keyof Containers> => {
    const fields: SkyNetTableField<keyof Containers>[] = [
        {
            name: 'serialNumber',
            reduced: true,
            width: '130px',
        },
        {
            name: 'containerTypeContainerModel',
            filterType: FilterType.Array,
            filterField: 'containerTypeContainerModel',
            width: '170px',
        },
        {
            name: 'tempRange',
            filterType: FilterType.Array,
            filterField: 'tempRange',
            componentName: 'ContainerTempRange',
            width: '150px',
        },
        {
            name: 'containerStatusEnriched',
            filterType: FilterType.Array,
            reduced: true,
            filterField: 'containerStatusEnriched',
            componentName: 'ContainerStatusEnriched',
            minWidth: '200px',
        },
        {
            name: 'containerStatusEnrichedChangedOnUtc',
            componentName: 'StatusAge',
            width: '160px',
        },
        {
            name: 'currentOrderOrderNumber',
            filterField: 'currentOrderOrderNumber',
            filterType: FilterType.Array,
            width: '120px',
        },
        {
            name: 'locationLocationName',
            filterField: 'locationLocationName',
            filterType: FilterType.Array,
            reduced: true,
            minWidth: '200px',
        },
        {
            name: 'defaultInternalLoggerType',
            filterField: 'defaultInternalLoggerType',
            minWidth: '200px',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: LoggerType,
            labelKey: 'Container.defaultInternalLoggerType',
        },
        {
            name: 'batteryLevelInternal',
            labelKey: 'Container.batteryLevel',
            minWidth: '115px',
            mapCellProps: ({
                batteryLevelInternal,
            }) => {
                return {
                    children: batteryLevelInternal && `${Math.round(batteryLevelInternal * 100)}%`,
                }
            },
        },
        {
            name: 'lastMeasuredTempInternal',
            componentName: 'ContainerTemp',
            propsMapper: {
                lowerTempBoundary: 'containerTypeIntTempRangeStart',
                higherTempBoundary: 'containerTypeIntTempRangeEnd',
            },
            width: '100px',
            sorting: false,
        },
        {
            name: 'lastMeasuredOnUtcInternal',
            componentName: 'TemperatureAge',
            width: '120px',
            sorting: false,
        },
        {
            name: 'loggerExchangedOnUtcInternal',
            componentName: 'LoggerAge',
            width: '150px',
        },
        {
            name: 'defaultAmbientLoggerType',
            filterField: 'defaultAmbientLoggerType',
            minWidth: '200px',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: LoggerType,
            labelKey: 'Container.defaultAmbientLoggerType',
        },
        {
            name: 'batteryLevelAmbient',
            labelKey: 'Container.batteryLevel',
            minWidth: '115px',
            mapCellProps: ({
                batteryLevelAmbient,
            }) => {
                return {
                    children: batteryLevelAmbient && `${Math.round(batteryLevelAmbient * 100)}%`,
                }
            },
        },
        {
            name: 'lastMeasuredTempAmbient',
            componentName: 'ContainerTemp',
            width: '100px',
            sorting: false,
        },
        {
            name: 'lastMeasuredOnUtcAmbient',
            componentName: 'TemperatureAge',
            width: '120px',
            sorting: false,
        },
        {
            name: 'loggerExchangedOnUtcAmbient',
            componentName: 'LoggerAge',
            width: '120px',
        },
    ]

    return {
        name: 'ContainersTable',
        tabs: injectFieldsToTabs([
            {
                name: 'Operational Containers',
                url: ContainersTablesTab.Operational,
                defaultTab: true,
            },
            {
                name: 'All Containers',
                url: CommonRoutes.ALL,
            },
            {
                name: 'Excluded',
                url: CommonRoutes.EXCLUDED,
            },
        ], fields),
    }
}

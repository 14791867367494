import React, {
    useCallback,
} from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import requests from '../Users.request'
import getUserCreateFields from './UserCreate.fields'
import useStyles from './UserCreate.styles'

const UserCreate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        classes,
    } = useStyles()

    const modifyDataBeforeSend = useCallback((update) => {
        return {
            ...update,
            hint: undefined,
        }
    }, [])

    return (
        <FormWithControls
            onSuccess={onSuccess}
            modifyDataBeforeSend={modifyDataBeforeSend}
            name="Users"
            fields={getUserCreateFields()}
            requestParams={requests.create()}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
        />
    )
}

export default UserCreate

// eslint-disable-next-line import/prefer-default-export
export enum CustomerTransportTabs {
    Notifications = 'notifications',
    OrderChanges = 'order-changes',
    ContainerSelection = 'container-selection',
    OrderSteps = 'order-steps',
    BillingInformation = 'billing-information',
    Invoices = 'invoices',
    SharedPositioning = 'shared-positioning',
    OrderConfirmation = 'order-confirmation',
}

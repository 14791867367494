import React, {
    useMemo,
} from 'react'

import {
    Navigate,
} from 'react-router-dom'
import {
    CommonRoutes, SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import ActivityRecords from './ActivityRecords'
import Engagement from './Engagement'
import useRelationshipsManagementRoutes from './hooks/useRelationshipsManagementRoutes'
import {
    RelationshipsManagementDomains,
} from './RelationshipsManagement.types'

const RelationshipsManagement = () => {
    const Routes = useRelationshipsManagementRoutes()
    const routesConfig: SkyNetRouteConfigObject<RelationshipsManagementDomains> = useMemo(() => {
        return {
            [Routes.ActivityRecords.route]: <ActivityRecords />,
            [Routes.Engagements.route]: <Engagement />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.ActivityRecords.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}

export default RelationshipsManagement

import React from 'react'

import FormWithControls from 'app/shared-components/FormWithControls'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useEnumValues from 'app/hooks/useEnumValues'

import {
    ActionComponentCreateProps,
} from 'app/shared-components/SkyNetDomain/SkyNetDomain.types'
import FxRatesRequests from '../FxRates.request'
import components from '../FxRate.fields'
import useStyles from '../FxRates.styles'

const CreateFxRate = ({
    onSuccess,
}: ActionComponentCreateProps) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const fields = useFieldsWithClassName(components(getAllowedValues), classes)

    return (
        <FormWithControls
            name="FxRate"
            fields={fields}
            classNames={{
                gridWrapper: classes.gridWrapper,
            }}
            onSuccess={onSuccess}
            requestParams={FxRatesRequests.create.request()}
        />
    )
}

export default CreateFxRate

import React, {
    useMemo,
    useCallback,
} from 'react'
import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import {
    ArchiveButton, useArchive, ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'
import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import useAddCopiedItemWorkaround from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'
import {
    useGetLocation,
} from 'app/Apps/ContactManagement/Locations/hooks'

import useRequestWithConfirmation from 'app/hooks/useRequestWithConfirmation'
import {
    useSkyNetDomainContext,
} from 'app/shared-components/SkyNetDomain'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import AccountLaneForm from '../AccountLaneForm/AccountLaneForm'
import request from '../AccountLanes.requests'
import useGetAccountLane from '../hooks/useGetAccountLane'

import {
    AccountLanesTable,
    LaneStatus,
} from '../AccountLanes.types'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const defaultProps = {
    disabled: false,
}

const AccountLaneUpdate = ({
    id,
    disabled,
}: {id: number, disabled?: boolean}) => {
    const {
        setSelectedTab,
    } = useSkyNetDomainContext()
    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccountLane(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'LaneUpdateDto',
        title: 'Lane',
        data,
        redirectionAfterSuccess: generateSkyNetLink({
            domainPath: LanesRoutes.path,
        }),
    })

    const onCancelSuccess = useCallback(() => {
        invalidate()
        LanesRoutes.Update.navigate({
            params: {
                key: id,
            },
        })
    }, [
        invalidate,
        LanesRoutes.Update,
        id,
    ])

    const cancelLane = useRequestWithConfirmation({
        requestParamFn: request.cancellation,
        notificationId: 'LaneUpdateDto',
        title: 'Lane',
        data,
        actionDone: 'Cancelled',
        onSuccess: onCancelSuccess,
    })

    const {
        data: destinationAirportData,
    } = useGetLocation(data?.destinationAirport?.id)
    const {
        data: originAirportData,
    } = useGetLocation(data?.originAirport?.id)

    useAddCopiedItemWorkaround({
        data: {
            ...data,
            originAirport: originAirportData?.iataCode,
            destinationAirport: destinationAirportData?.iataCode,
            opportunity: '',
        } as AccountLanesTable,
    })
    const customButtons = useMemo(() => {
        return [
            <Button
                label="Copy"
                key="copy"
                name="copy"
                onClick={() => {
                    setSelectedTab(null)
                    LanesRoutes.Overview.navigate({
                        params: {
                            key: id,
                            copy: 'copy',
                        },
                    })
                }}
            />,
            [
                LaneStatus.TO_BE_QUOTED,
                LaneStatus.UNDER_REVIEW_PRICING,
            ].includes(data?.laneStatus)
                && (
                    <Button
                        key="cancelLane"
                        label="Cancel Lane"
                        onClick={cancelLane.askConfirmation}
                    />
                ),
            data?.laneStatus === LaneStatus.CANCELLED && (
                <ArchiveButton
                    onClick={archive.handleArchiveRestore}
                    archived={data?.archived}
                />
            ),
        ].filter(Boolean)
    }, [
        data?.laneStatus,
        data?.archived,
        cancelLane.askConfirmation,
        archive.handleArchiveRestore,
        setSelectedTab,
        LanesRoutes.Overview,
        id,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <AccountLaneForm
                    data={data}
                    requestParam={request.update({
                        id,
                    })}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    disabled={disabled}
                    customButtons={customButtons}
                    exists
                />
                <ArchiveConfirmationDialog
                    title="Lane"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
                <ConfirmationDialog
                    open={cancelLane.openConfirmDialog}
                    handleClose={cancelLane.doAction}
                    dialogTitle="CANCEL LANE CONFIRMATION"
                    dialogContent="Do you want to cancel this lane?"
                />
            </>
        </StatusHandler>
    )
}

AccountLaneUpdate.defaultProps = defaultProps

export default AccountLaneUpdate

import React, {
    useMemo,
} from 'react'
import AccountQuotes from 'app/Apps/AccountManagement/AccountQuotes/AccountQuotes'
import {
    Navigate,
} from 'react-router-dom'
import {
    CommonRoutes, SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import ContractBasis from './ContractBasis'
import Lanes from './Lanes'
import LaneGroups from './LaneGroups'
import AlLogisticPricing from './AlLogisticPricing'
import AlLeasePricing from './AlLeasePricing'
import Dashboard from './Dashboard'
import usePricingRoutes from './hooks/usePricingRoutes'
import {
    PricingDomains,
} from './Pricing.types'

export default function Pricing() {
    const Routes = usePricingRoutes()
    const routesConfig: SkyNetRouteConfigObject<PricingDomains> = useMemo(() => {
        return {
            [Routes.PricingDashboard.route]: <Dashboard />,
            [Routes.ContractBasis.route]: <ContractBasis />,
            [Routes.LaneGroups.route]: <LaneGroups />,
            [Routes.Lanes.route]: <Lanes />,
            [Routes.AlLogisticPricing.route]: <AlLogisticPricing />,
            [Routes.AlLeasePricing.route]: <AlLeasePricing />,
            [Routes.Quotes.route]: <AccountQuotes isCreateFormEnabled={false} />,
            [CommonRoutes.SLASH]: <Navigate
                to={Routes.ContractBasis.route}
                replace
            />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}

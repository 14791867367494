import React, {
    useMemo,
    useCallback,
} from 'react'
import {
    Link,
} from 'react-router-dom'
import Typography from '@mui/material/Typography'

import AddIcon from '@mui/icons-material/Add'
import Card from 'app/shared-components/Card'
import SkyNetTable from 'app/shared-components/SkyNetTable'

import {
    LocationType,
} from 'app/types/enums'
import useContactManagementRoutes from 'app/Apps/ContactManagement/hooks/useContactManagementRoutes'
import {
    generateSkyNetLink,
} from 'app/SkyNetRoutes'
import {
    RoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import useStyles from '../CompanyOverview.styles'
import getTableConfig from './Addresses.config'

const Addresses = ({
    data,
}: {
    data: { id: number }
}) => {
    const {
        Locations: LocationsRoutes,
    } = useContactManagementRoutes()
    const {
        classes,
    } = useStyles()

    const filter = useMemo(() => {
        return {
            locationType: [LocationType.ADDRESS],
            companyId: [data?.id],
            isProspect: [
                true,
                false,
            ],
        }
    }, [data?.id])

    const onRowClick = useCallback(({
        id: locationId,
    }) => {
        (LocationsRoutes.Address as Record<string, RoutesConfig>).Edit.Update.navigate({
            params: {
                key: locationId,
            },
        })
    }, [LocationsRoutes])

    const createLocationLink = generateSkyNetLink({
        domainPath: LocationsRoutes.Create.path,
        searchParams: data?.id ? {
            companyId: String(data?.id),
            locationType: LocationType.ADDRESS,
        } : undefined,
    })

    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <div
            className={classes.tableContainer}
        >
            <Card
                fullHeight
                className={classes.cardRoot}
                contentClass={classes.cardContent}
            >
                <div className={classes.header}>
                    <Typography
                        variant="h3"
                    >
                        Addresses
                    </Typography>
                    <div className={classes.icon}>
                        <Link
                            className={classes.link}
                            to={createLocationLink}
                        >
                            <AddIcon
                                data-testid="open-create-locationType-ADDRESS"
                                className={classes.icon}
                            />
                        </Link>
                    </div>
                </div>
                <SkyNetTable
                    name="Location"
                    simple
                    filter={filter}
                    tableConfig={tableConfig}
                    onRowClick={onRowClick}
                />
            </Card>
        </div>
    )
}

export default Addresses

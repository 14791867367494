import React from 'react'
import Card from 'app/shared-components/Card'
import useStyles from './InternalOrdersChangeHistory.styles'
import OrderChangeSummary from '../../Components/OrderChangeSummary'

export default function InternalOrdersChangeHistory({
    id: orderNumber,
}: {id: string}) {
    const {
        classes,
    } = useStyles()

    return (
        <Card
            className={classes.root}
            title="Change History"
            titleClass={classes.title}
        >
            <OrderChangeSummary orderNumber={orderNumber} />
        </Card>
    )
}
